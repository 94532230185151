.complain-content {
  width: 500px;
  margin: 30px;
  background: #fff;
  .complain-main {
    overflow: hidden;
    .complain-main-title {
      margin-top: 0;
      font-size: 22px;
      padding-top: 15px;
      padding-left: 10px;
      font-family: ProximaNova, serif;
      font-weight: bold;
    }
    .complain-main-content-complain {
      padding-left: 10px;
      padding-right: 10px;
      p {
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-size: 16px;
      }
      .comment {
        margin-bottom: 10px;
      }
      textarea {
        height: 80px;
        max-width: 480px;
        min-width: 480px;
        border: 2px solid #4a4a4a;
      }
    }
    .send-wrapper {
      float: right;
      position: relative;
      width: auto;
      padding: 5px 10px 5px;
      .send-button {
        position: relative;
        float: left;
        cursor: pointer;
        font-size: 18px;
        color: #4a4a4a;
        left: -18px;
        height: 22px;
        &:hover {
          text-decoration: underline
        }
      }
      .close-popup {
        position: relative;
        float: left;
        height: 22px;
        color: #4a4a4a;
        font-size: 18px;
        cursor: pointer;
        &:hover {
          text-decoration: underline
        }
      }
    }
  }
}

.comments {
  //max-width: 630px;
  margin: 20px 0 0 0;
  .c-title {
    color: #4a4a4a;
    font-size: 18pt;
    margin: 0 0 20px 0;
    font-family: ProximaNova, serif;
    font-weight: bold;
    img {
      margin: 0 3px 0 10px;
    }
  }

  .c-title-form {
    margin: 20px 0 30px;
    font-size: 18px;
    line-height: 1.2;
    color: #191919;
  }

  .c-form {
    textarea {
      //max-width: 620px;
      //min-width: 620px;
      padding: 14px;
      font-size: 14px;
      line-height: 1.2;
      resize: none;
      height: 120px;
      border: 1px solid #E1E1E1;
      border-radius: 3px;
      width: 100%;
    }
    button {
      display: block;
      cursor: pointer;
      margin: 10px 0 15px 0;
      font-size: 18px;
      line-height: 25px;
      padding: 20px 0;
      background: #FFCA00;
      color: #FFF;
      font-family: ProximaNova, serif;
      border: none;
      max-width: 250px;
      width: 100%;
    }

    .c-focus-only {
      display: none;
    }

    &.c-focused {
      .c-focus-only {
        display: block;
      }
    }

  }

  textarea#editField {
    min-width: 536px;
    max-width: 620px;
  }

  .c-comment {
    font-family: ProximaNova, serif;
    font-weight: normal;
    font-size: 14px;
    padding: 25px 30px;
    background: #f5faef;
    margin-top: 30px;
    .c-text {
      line-height: 14pt;
		@media all and (max-width: 767px) {
			margin-left: -100px;
		}
    }

    .c-reply {
      color: #e91010;
      cursor: pointer;
      text-decoration: underline;
      margin-left: 20px;
      &:hover {
        opacity: 0.9;
      }
		@media all and (max-width: 767px) {
			margin-left: 0px;
    		display: block;
		}
    }

    padding-left: 130px;
    .c-user {
      margin-bottom: 14px;
      position: relative;
      .c-username {
        img {
          background: #F7811D;
          border-radius: 3px;
          position: absolute;
          left: -100px;
          top: 0;
          width:66px;
          height:66px;
        }
        color: #191919;
        font-size: 18px;
        line-height: 1;
      }
      .c-timestamp {
        padding-left: 20px;
		@media all and (max-width: 767px) {
			padding-left: 0px;
			display: block;
		  }
        a {
          color: #9d9d9d;
        }
      }
      .c-reply-to {
        background: url("../reply-to.png") no-repeat 0 2px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        padding-left: 20px;
        margin-left: 13px;
        img {
          width: 16px;
          height: 16px;
          margin-top: -2px;
          border-radius: 2px;
        }
        a {
          color: #8c8c8c;
        }
      }

    }
    .c-footer {
      overflow: hidden;
      min-height: 25px;
      margin-top: 5px;
    }

    .c-comment-hide {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }

    .c-like-users {
      font-size: 0.9em;
      padding-top: 5px;
      position: relative;
      width: 530px;
      word-wrap: normal;

      .more-like-users {
        cursor: pointer;
      }
    }
    .c-edit
    {
      cursor: pointer;
      margin-left: 20px;
      color: #5287c1;
      text-decoration: underline;
    }
    &:hover .c-edit
    {
      opacity: 0.9;
    }

    .c-complain
    {
      display: block;
      height: 45px;
      margin: -16px 0 0 10px;
    }
    &:hover .c-complain
    {
      display: block;
      background: url("../images/megaphone.png") no-repeat 0;
      height: 45px;
      margin: -16px 0 0 10px;
      width: 45px;
    }
  }

  .c-comment-list .c-comment-list {
    margin-left: 96px;
    .c-comment {
      background: transparent;
      padding: 25px 0;
      padding-left: 55px;
      margin-top: 0;
      .c-user {
        .c-username {
          img {
            border-radius: 2px;
            width: 40px;
            height: 40px;
            left: -55px;
          }
        }
      }
    }
    .c-comment-list {
      margin-left: 0;
    }
  }

  .c-like {
    bottom: 9px;
    float: right;
    position: relative;
    width: auto;
	  @media all and (max-width: 767px) {
		overflow: hidden;
		margin: 10px 0px;
		float: none;
		  bottom: 0;
	  }

    .c-like-select {
      float: left;
    }

    .c-like-select-type {
      display: none;
      position: relative;
      top: -3px;
    }

    .c-like-message {
      display: none;
    }

    #c-like-image {
      float: left;
      left: 0;
      position: relative;
      top: -2px;
      font-size: 23px;
    }

    .like-block, .c-like-count {
      float: left;
      display: block;
      font-size: 18px;
      line-height: 1;
      text-align: center;
      padding: 1px;
    }

    .like-block {
      line-height: 20px;
      color: #FFF;
      border-radius: 3px;
      cursor: pointer;
      width: 22px;
      height: 22px;
      &[data-id="like"] {
        background: #E0ECC8;
        &:hover {
          background: #c6d2ab;
        }
      }
      &[data-id="dislike"] {
        background: #F3C7C7;
        &:hover {
          background: #d7a9a9;

        }
      }
    }

    .c-like-count {
      padding: 2px 8px;
      min-width: 34px;
      color: #bfbfbf;
      &.success {
        color: #aabb89;
      }
      &.error {
        color: #c24949;
      }
    }


  }

  .c-edit-buttons span {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #4a4a4a;
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0 15px;
    padding: 3px 15px;
    text-transform: uppercase;
    cursor: pointer;
  }


}