/*------ RPopup ------*/
.RPopup-loader {
  z-index: 3000;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-image: url('/images/popup-loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;

  div:first-child {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    div:first-child {
      width: 50px;
      height: 20px;
      position: relative;
      top: 50px;
      left: -25px;
      padding: 0;
      font-size: 10px;
      line-height: 20px;
    }
  }
}
.RPopup {
  width: 100%;
  height: 101%;
  position: fixed;
  z-index: 1000;
  margin: 0;
  .screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0;
    z-index: 1001;
    top:0px;
    left: 0;
  }
  .rounded-popup(930px, 400px)
}

.rounded-popup(@width, @height) {
  .body {
    width: @width;
    z-index: 1002;
    height: @height;
    position: fixed;
    margin: -@height/2 0 0 -@width/2;
    top: 50%;
    left: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      background: url('/images/shop/close-popup.png') no-repeat center, #e5e5e5;
      opacity: 1;
      filter: grayscale(1);
      background-size: 12px auto;
    }
  }
}

.popup-login {
  .screen {
    background: rgba(40, 40, 40, 0.8);
  }
  .body {
    max-width: 600px;
    z-index: 1002;
    height: 400px;
    margin: -200px 0 0 -300px;
    width: 100%;
    padding: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
    &:before {
      content: 'Вход на сайт';
      color: #282828;
      font-size: 24px;
    }
    .body-border {
      margin: 20px 0 0;
      width: auto;
      height: auto;
      .body-title-login {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-bottom: 20px;
        .body-title {
		background: #FFCA00;
		height: auto;
		width: 50%;
		padding: 16px 10px;
		float: left;
		text-align: center;
          &.selected {
            background: rgba(243, 244, 244, 0.4);
          }
          a {
            font-family: inherit;
            color: #282828;
            font-style: normal;
            font-size: 18px;
            text-transform: none;
            line-height: 38px;
          }
          &.selected {
            a {
              color: #282828;
            }
          }
        }
      }
      .body-content {
        height: auto;
        .body-another {
          display: none;
        }
      }
      .body-social-content {
        .eauth {
          .eauth-list {
            width: auto;
            margin: 0 -15px;
          }
          .eauth-service {
            width: 50%;
            margin: 0;
            padding: 10px 15px;
          }
          .eauth-service-link {
            padding: 0;
            width: 100%;
            text-align: left;
            background: no-repeat 13px;
            color: #fafafa;
            line-height: 50px;
            padding-left: 50px;
            &:before, &:after {
              content: none;
            }
          }
          .eauth-service-id-twitter {
            .eauth-service-link {
              background-color: #63CDF1;
              background-image: url('/images/agronomu/social/twitter-in.png');
            }
          }
          .eauth-service-id-google_oauth {
            .eauth-service-link {
              background-color: #4D4F54;
              background-image: url('/images/agronomu/social/google-in.png');
            }
          }
          .eauth-service-id-yandex_oauth {
            .eauth-service-link {
              background-color: #EC3626;
              background-image: url('/images/agronomu/social/yandex-in.png');
            }
          }
          .eauth-service-id-facebook {
            .eauth-service-link {
              background-color: #507CBE;
              background-image: url('/images/agronomu/social/facebook-in.png');
            }
          }
          .eauth-service-id-vkontakte {
            .eauth-service-link {
              background-color: #5082B9;
              background-image: url('/images/agronomu/social/vk-in.png');
            }
          }
          .eauth-service-id-mailru {
            .eauth-service-link {
              background-color: #004684;
              background-image: url('/images/agronomu/social/mailru-in.png');
            }
          }
        }
      }
      .body-content-login {
        .warn {
          font-size: 18px;
          line-height: 1.1;
          color: #282828;
          max-width: 480px;
          margin: 30px auto 0;
        }
        .body-content-form {
          overflow: hidden;
          width: 100%;
          padding: 25px 0 0;
          .popup-login-wrap {
            float: left;
            width: 100%;
          }
          form {
            margin: 0 -15px;
            .form-group {
              padding: 0 15px;
              width: 50%;
              float: left;
              input {
                width: 100%;
                border: 1px solid #E1E1E1;
                border-radius: 3px;
                color: #808080;
                font-size: 14px;
                height: 41px;
              }
            }
          }
          div .popup-login-button {
            background: #FFCA00;
            color: #FFFFFF;
            min-width: 250px;
            border-radius: 2px;
            border: none;
            font-size: 14px;
            font-family: inherit;
            line-height: 43px;
            padding: 0 10px;
            cursor: pointer;
            text-transform: none;
          }
        }
      }
    }

    .close {
      top: 20px;
      right: 25px;
      font-size: 24px;
      padding: 5px;
      line-height: 1;
      background: transparent;
      width: auto;
      height: auto;
      &:before {
        content: 'x';
      }
    }
  }
}

.popup-add-mail
{
  .body {
    width: 660px;
    z-index: 1004;
    height: 350px;
    border: 25px solid #308fed;
    padding:30px;
    margin: -175px 0 0 -349px;
  }
}
@media (max-width: 600px) {
  .popup-login .body {
    margin-left: -50%;
    padding: 20px 30px;
    .body-border {
      width: 100%;
      .body-title-login {
        width: auto;
        margin: 0 -30px 20px;
        .body-title {
          a {
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
      .body-content {
        .body-social-content {
          .eauth {
            .eauth-service-link {
              font-size: 14px;
              font-weight: 600;
              padding-left: 37px;
              background-position: 6px;
            }
          }
        }
      }
      .body-content-login {
        .warn {
          font-size: 14px;
          margin-top: 20px;
        }
        .body-content-form {
          padding-top: 10px;
          form {
            .form-group {
              width: 100%;
              margin-bottom: 15px;
            }
          }
          .popup-login-wrap {
            margin: 0;
          }
          div .popup-login-button {
            margin-top: 0;
          }
        }
      }
    }
    .close {
      top: 10px;
    }
  }
}

@media (max-width: 319px) {
  .popup-login .body {
    .body-border {
      .body-content {
        .body-social-content {
          .eauth {
            .eauth-service-link {
              padding-left: 29px;
              background-position: 3px;
            }
          }
        }
      }
    }
  }
}

.bucket-popup {
  .rounded-popup(96vw, 80vh);
  .screen {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .body {
    max-height: 80vh;
    height: auto !important;
  }

  @media (min-width: 993px)
  {
    .rounded-popup(980px, 80vh);
  }
  @media (min-width: 1441px)
  {
    .rounded-popup(1323px, 80vh);
  }
}