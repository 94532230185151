html,
body {
  height: 100%;
  margin: 0;
  font: 12px/18px ProximaNova, sans-serif;
}
html {
  overflow-x: hidden;
  background: #FFF;
}
@font-face {
  font-family: ProximaNova;
  src: url(/font/ProximaNova-Regular.ttf);
}
.wrapper-index {
  font: 12px/18px ProximaNova, sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.wrapper-index::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/images/agronomu/background.jpg') no-repeat center bottom;
  background-size: cover;
  opacity: 0.5;
}
.loader-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) url('/images/load.gif') no-repeat center;
}
.content-main-index {
  height: auto;
  max-width: 1340px;
  width: 1323px;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(145, 166, 186, 0.3);
  padding-bottom: 222px;
  background: #FFF;
}
img {
  vertical-align: middle;
}
* {
  outline: none;
  box-sizing: border-box;
}
.hidden {
  display: none !important;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row:before,
.block-separator:before,
.row:after,
.block-separator:after {
  content: "";
  display: table;
}
.row:after,
.block-separator:after {
  clear: both;
}
.right {
  float: right;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-fx-1,
.col-fx-2,
.col-fx-3,
.col-fx-4,
.col-fx-5,
.col-fx-6,
.col-fx-7,
.col-fx-8,
.col-fx-9,
.col-fx-10,
.col-fx-11,
.col-fx-12,
.col-sx-1,
.col-sx-2,
.col-sx-3,
.col-sx-4,
.col-sx-5,
.col-sx-6,
.col-sx-7,
.col-sx-8,
.col-sx-9,
.col-sx-10,
.col-sx-11,
.col-sx-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-dl-1,
.col-dl-2,
.col-dl-3,
.col-dl-4,
.col-dl-5,
.col-dl-6,
.col-dl-7,
.col-dl-8,
.col-dl-9,
.col-dl-10,
.col-dl-11,
.col-dl-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-1-5 {
  margin-left: 12.5%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-4-5 {
  margin-left: 37.5%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
@media (min-width: 501px) {
  .col-fx-1,
  .col-fx-2,
  .col-fx-3,
  .col-fx-4,
  .col-fx-5,
  .col-fx-6,
  .col-fx-7,
  .col-fx-8,
  .col-fx-9,
  .col-fx-10,
  .col-fx-11,
  .col-fx-12 {
    float: left;
  }
  .col-fx-1 {
    width: 8.33333333%;
  }
  .col-fx-2 {
    width: 16.66666667%;
  }
  .col-fx-3 {
    width: 25%;
  }
  .col-fx-4 {
    width: 33.33333333%;
  }
  .col-fx-5 {
    width: 41.66666667%;
  }
  .col-fx-6 {
    width: 50%;
  }
  .col-fx-7 {
    width: 58.33333333%;
  }
  .col-fx-8 {
    width: 66.66666667%;
  }
  .col-fx-9 {
    width: 75%;
  }
  .col-fx-10 {
    width: 83.33333333%;
  }
  .col-fx-11 {
    width: 91.66666667%;
  }
  .col-fx-12 {
    width: 100%;
  }
  .col-fx-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-fx-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-fx-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-fx-offset-3 {
    margin-left: 25%;
  }
  .col-fx-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-fx-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-fx-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-fx-offset-6 {
    margin-left: 50%;
  }
  .col-fx-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-fx-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-fx-offset-9 {
    margin-left: 75%;
  }
  .col-fx-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-fx-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-fx-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 641px) {
  .col-sx-1,
  .col-sx-2,
  .col-sx-3,
  .col-sx-4,
  .col-sx-5,
  .col-sx-6,
  .col-sx-7,
  .col-sx-8,
  .col-sx-9,
  .col-sx-10,
  .col-sx-11,
  .col-sx-12 {
    float: left;
  }
  .col-sx-1 {
    width: 8.33333333%;
  }
  .col-sx-2 {
    width: 16.66666667%;
  }
  .col-sx-3 {
    width: 25%;
  }
  .col-sx-4 {
    width: 33.33333333%;
  }
  .col-sx-5 {
    width: 41.66666667%;
  }
  .col-sx-6 {
    width: 50%;
  }
  .col-sx-7 {
    width: 58.33333333%;
  }
  .col-sx-8 {
    width: 66.66666667%;
  }
  .col-sx-9 {
    width: 75%;
  }
  .col-sx-10 {
    width: 83.33333333%;
  }
  .col-sx-11 {
    width: 91.66666667%;
  }
  .col-sx-12 {
    width: 100%;
  }
  .col-sx-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sx-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-sx-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sx-offset-3 {
    margin-left: 25%;
  }
  .col-sx-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sx-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-sx-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sx-offset-6 {
    margin-left: 50%;
  }
  .col-sx-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sx-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sx-offset-9 {
    margin-left: 75%;
  }
  .col-sx-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sx-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sx-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 993px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-dl-1,
  .col-dl-2,
  .col-dl-3,
  .col-dl-4,
  .col-dl-5,
  .col-dl-6,
  .col-dl-7,
  .col-dl-8,
  .col-dl-9,
  .col-dl-10,
  .col-dl-11,
  .col-dl-12 {
    float: left;
  }
  .col-dl-1 {
    width: 8.33333333%;
  }
  .col-dl-2 {
    width: 16.66666667%;
  }
  .col-dl-3 {
    width: 25%;
  }
  .col-dl-4 {
    width: 33.33333333%;
  }
  .col-dl-5 {
    width: 41.66666667%;
  }
  .col-dl-6 {
    width: 50%;
  }
  .col-dl-7 {
    width: 58.33333333%;
  }
  .col-dl-8 {
    width: 66.66666667%;
  }
  .col-dl-9 {
    width: 75%;
  }
  .col-dl-10 {
    width: 83.33333333%;
  }
  .col-dl-11 {
    width: 91.66666667%;
  }
  .col-dl-12 {
    width: 100%;
  }
  .col-dl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-dl-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-dl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-dl-offset-3 {
    margin-left: 25%;
  }
  .col-dl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-dl-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-dl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-dl-offset-6 {
    margin-left: 50%;
  }
  .col-dl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-dl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-dl-offset-9 {
    margin-left: 75%;
  }
  .col-dl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-dl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-dl-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-1-5 {
    margin-left: 12.5%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-4-5 {
    margin-left: 37.5%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
.table-row {
  margin: 15px -15px;
}
.table-row .col-xs-1,
.table-row .col-xs-2,
.table-row .col-xs-3,
.table-row .col-xs-4,
.table-row .col-xs-5,
.table-row .col-xs-6,
.table-row .col-xs-7,
.table-row .col-xs-8,
.table-row .col-xs-9,
.table-row .col-xs-10,
.table-row .col-xs-11,
.table-row .col-xs-12,
.table-row .col-sx-1,
.table-row .col-sx-2,
.table-row .col-sx-3,
.table-row .col-sx-4,
.table-row .col-sx-5,
.table-row .col-sx-6,
.table-row .col-sx-7,
.table-row .col-sx-8,
.table-row .col-sx-9,
.table-row .col-sx-10,
.table-row .col-sx-11,
.table-row .col-sx-12,
.table-row .col-sm-1,
.table-row .col-sm-2,
.table-row .col-sm-3,
.table-row .col-sm-4,
.table-row .col-sm-5,
.table-row .col-sm-6,
.table-row .col-sm-7,
.table-row .col-sm-8,
.table-row .col-sm-9,
.table-row .col-sm-10,
.table-row .col-sm-11,
.table-row .col-sm-12,
.table-row .col-md-1,
.table-row .col-md-2,
.table-row .col-md-3,
.table-row .col-md-4,
.table-row .col-md-5,
.table-row .col-md-6,
.table-row .col-md-7,
.table-row .col-md-8,
.table-row .col-md-9,
.table-row .col-md-10,
.table-row .col-md-11,
.table-row .col-md-12,
.table-row .col-dl-1,
.table-row .col-dl-2,
.table-row .col-dl-3,
.table-row .col-dl-4,
.table-row .col-dl-5,
.table-row .col-dl-6,
.table-row .col-dl-7,
.table-row .col-dl-8,
.table-row .col-dl-9,
.table-row .col-dl-10,
.table-row .col-dl-11,
.table-row .col-dl-12,
.table-row .col-lg-1,
.table-row .col-lg-2,
.table-row .col-lg-3,
.table-row .col-lg-4,
.table-row .col-lg-5,
.table-row .col-lg-6,
.table-row .col-lg-7,
.table-row .col-lg-8,
.table-row .col-lg-9,
.table-row .col-lg-10,
.table-row .col-lg-11,
.table-row .col-lg-12 {
  float: none;
  padding: 15px;
}
.main-tags-block {
  list-style: none;
  margin: 0;
  background-color: #F0F5F5;
  border-radius: 3px;
  overflow: hidden;
  padding: 18px 25px;
}
.main-tags-block li {
  padding: 17px 5px;
  float: left;
  width: 25%;
  text-align: center;
}
.main-tags-block li a {
  font-size: 14px;
  font-weight: bold;
  color: #282828;
}
.main-tags-block li a:hover,
.main-tags-block li a:focus {
  color: #282828;
  text-decoration: underline;
}
.main-tags-block .image-block {
  height: 55px;
  margin-bottom: 15px;
}
.main-tags-block .image-block .table-cell {
  vertical-align: bottom;
}
.other-table {
  height: 50%;
  display: table;
  width: 100%;
}
.other-table.padding-bottom {
  padding-bottom: 15px;
}
.other-table.padding-top {
  padding-top: 15px;
}
.other-block {
  background: #FFFAE5;
  border-radius: 2px;
  text-align: center;
  padding: 15px 5px;
}
.other-block a {
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  color: #282828;
}
.other-block a:hover,
.other-block a:focus {
  color: #282828;
  text-decoration: underline;
}
.other-block a .image-block {
  margin-bottom: 25px;
}
.other-block a .text-count-post {
  font-weight: normal;
  color: #868686;
  min-height: 23.4px;
  margin-top: 7px;
}
a {
  text-decoration: none;
  color: #5a82d1;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #e91010;
}
a:hover .text-post,
a:focus .text-post {
  color: #e91010;
}
a:hover .text-post > div,
a:focus .text-post > div,
a:hover .text-post .text,
a:focus .text-post .text,
a:hover .text-post .category,
a:focus .text-post .category {
  color: #e91010;
}
.tablet-block,
.mobile-block,
.little-tablet {
  display: none;
}
.content-index .content-index-left {
  top: 0;
  width: 933px;
  position: relative;
  float: left;
}
.content-index .content-index-right {
  width: 300px;
  float: right;
  position: relative;
  top: 0;
}
.content-index .content-index-right ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.content-index > .content-main-post {
  margin: -30px -30px 0;
  padding: 30px 30px 0;
}
/* User index
-------------------------------------------------------------------------------------------------------------------*/
.user-index {
  height: auto;
}
.user-index .top-user-index {
  overflow: hidden;
  margin: 8px 0 15px 0;
  position: relative;
}
.user-index .top-user-index .top-title {
  float: left;
}
.user-index .top-user-index .mini-user-menu {
  float: right;
  height: 58px;
  width: 345px;
  background: #000000;
}
.user-index .top-user-index .mini-user-menu p {
  padding: 20px;
  color: #ffffff;
  font-size: 21px;
}
.user-index .main-user-index {
  overflow: hidden;
  position: relative;
}
.user-index .main-user-index .main-user-left {
  float: left;
  width: 250px;
  height: auto;
  margin: 0 20px 0 0;
}
.user-index .main-user-index .main-user-left ul {
  width: 222px;
  padding: 0;
  list-style-type: none;
}
.user-index .main-user-index .main-user-left ul li:last-child {
  border-bottom: 4px solid #ddddde;
}
.user-index .main-user-index .main-user-left ul li:first-child {
  cursor: none;
  background: #2a2a2a;
  padding: 9px 0 6px 20px;
}
.user-index .main-user-index .main-user-left ul li:first-child h1 {
  margin: 0;
  color: #f9cd1b;
  font-size: 12px;
  display: inline;
  padding: 0 0 0 10px;
}
.user-index .main-user-index .main-user-left ul .visit {
  border-left: 3px solid #f9cd1b;
  padding: 8px 0 8px 10px;
}
.user-index .main-user-index .main-user-left ul .ul-title {
  cursor: none;
  background: #d4d4d4;
  padding: 9px 0 6px 20px;
}
.user-index .main-user-index .main-user-left ul .ul-title h1 {
  margin: 0;
  color: #676767;
  font-size: 12px;
  display: inline;
  padding: 0 0 0 10px;
}
.user-index .main-user-index .main-user-left ul li {
  cursor: pointer;
  background: #f6f6f6;
  padding: 8px 0 8px 10px;
  border-bottom: 1px solid #ddddde;
}
.user-index .main-user-index .main-user-left ul li a {
  color: #2a2a2a;
  font-size: 13px;
  text-decoration: none;
}
.user-index .main-user-index .main-user-right {
  width: auto;
  height: auto;
  padding: 0 0 0 270px;
}
.user-index .main-user-index .main-user-right .main-user-right-title {
  height: 22px;
  width: 100%;
  padding: 0 0 0 45px;
}
.user-index .main-user-index .main-user-right .main-user-right-title span {
  color: #2a2a2a;
  font-size: 12px;
}
.user-index .main-user-index .main-user-right .main-user-right-main {
  height: auto;
  overflow: hidden;
  position: relative;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-foto {
  float: left;
  width: 150px;
  min-height: 200px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-foto a {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  background: #2a2a2a;
  text-decoration: none;
  margin: 17px 28px 0 28px;
  padding: 8px 10px 8px 10px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-foto .foto {
  width: 130px;
  height: 130px;
  border-radius: 7px;
  background: #f6f6f6;
  border: 3px solid #d9d9d9;
  background-image: url("/images/forum/userlogo.png");
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info {
  float: left;
  height: auto;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status {
  height: auto;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status h2 {
  margin: 0;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: Bold;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status h2 span {
  color: #2a2a2a;
  font-size: 20px;
  font-family: AllegroScript, serif;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .newstatus {
  padding: 0 0 15px 0;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .newstatus input {
  height: 30px;
  width: 500px;
  border-radius: 2px;
  margin: 10px 0 0 0;
  background: #f6f6f6;
  padding: 0 0 0 12px;
  border: 1px solid #e8e8e8;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .newstatus a {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  margin: 0 0 0 5px;
  background: #2a2a2a;
  text-decoration: none;
  padding: 8px 10px 8px 10px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .gender {
  font-size: 14px;
  color: #2a2a2a;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #dededf;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .gender h2 {
  margin: 0;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: Bold;
  display: inline;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .gender .radio-button {
  display: inline;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .gender .radio-button input {
  width: 20px;
  height: 20px;
  margin: 15px 5px 0 20px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box {
  padding: 20px 0 0 0;
  border-bottom: 1px solid #dededf;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box h2 {
  margin: 0;
  color: #2a2a2a;
  font-size: 16px;
  font-weight: Bold;
  padding: 0 0 10px 0;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box h2 span {
  color: #2a2a2a;
  font-size: 14px;
  font-weight: 100;
  font-family: Arial;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box span {
  color: #999999;
  font-size: 11px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .checbox-info input {
  width: 19px;
  height: 19px;
  display: inline;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .checbox-info span {
  color: #2a2a2a;
  font-size: 12px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .show-its-age {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  margin: 10px 0 8px 0;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .show-its-age .show-its-age-info {
  float: left;
  width: 170px;
  height: auto;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .show-its-age .show-its-age-setting {
  float: right;
  width: 150px;
  height: auto;
  text-align: right;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .personal-info-box .show-its-age .show-its-age-setting a {
  color: #2a2a2a;
  font-size: 11px;
  text-decoration: underline;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .persotal-info h2 {
  margin: 20px 0 0 0;
  color: #676767;
  font-size: 14px;
  font-weight: Bold;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .persotal-info input {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  margin: 10px 0 0 0;
  background: #f6f6f6;
  padding: 0 0 0 12px;
  border: 1px solid #e8e8e8;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .persotal-info textarea {
  width: 100%;
  resize: none;
  height: 100px;
  border-radius: 2px;
  margin: 10px 0 0 0;
  background: #f6f6f6;
  padding: 7px 0 0 12px;
  border: 1px solid #e8e8e8;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .much-button {
  width: 220px;
  height: 50px;
  margin: 30px auto;
  overflow: hidden;
  position: relative;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .much-button .cencel {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  position: absolute;
  background: #cdcdcd;
  text-decoration: none;
  padding: 8px 20px 8px 20px;
}
.user-index .main-user-index .main-user-right .main-user-right-main .main-user-right-info .status .much-button .save {
  right: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  margin: 0 0 0 5px;
  position: absolute;
  background: #2a2a2a;
  text-decoration: none;
  padding: 8px 20px 8px 20px;
}
.user-index .main-user-index .main-user-right .main-title-days-last {
  height: 25px;
  margin: 5px 0 0 0;
  background: #d4d4d4;
}
.user-index .main-user-index .main-user-right .main-title-days-last h2 {
  color: #2e2e2e;
  font-size: 12px;
  padding: 6px 0 0 15px;
}
.user-index .main-user-index .main-user-right .main-title-days {
  height: 25px;
  background: #f9cd1b;
}
.user-index .main-user-index .main-user-right .main-title-days h2 {
  color: #2e2e2e;
  font-size: 12px;
  padding: 6px 0 0 15px;
}
.user-index .main-user-index .main-user-right table {
  width: 100%;
}
.user-index .main-user-index .main-user-right table tbody tr:last-child {
  border-bottom: 3px solid #e0e0e1;
}
.user-index .main-user-index .main-user-right table tbody tr td {
  border-bottom: 1px solid #e0e0e1;
}
.user-index .main-user-index .main-user-right table tbody tr td .info-foto img {
  vertical-align: middle;
  display: block;
  border-radius: 4px;
  float: left;
  border: 2px solid #d3d3d3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin: 25px 9px;
}
.user-index .main-user-index .main-user-right table tbody tr td .message {
  margin: 31px 0 0 0;
}
.user-index .main-user-index .main-user-right table tbody tr td .message p {
  color: #494949;
  font-size: 12px;
}
.user-index .main-user-index .main-user-right table tbody tr td .message p a {
  color: #2585ce;
  font-size: 12px;
}
.user-index .main-user-index .main-user-right table tbody tr td .message p .name {
  padding: 5px 0 5px 15px;
  background-image: url("/images/forum/user.png");
  color: #2a2a2a;
  background-repeat: no-repeat;
  background-position: 0 center;
  margin: 0 5px 0 0;
  font-size: 11px;
  font-family: Arial;
  font-weight: bold;
}
.user-index .main-user-index .main-user-right table tbody tr td .message p .time {
  padding: 5px 0 5px 15px;
  background-image: url("/images/forum/time.png");
  color: #2a2a2a;
  background-repeat: no-repeat;
  background-position: 0 center;
  font-size: 11px;
  font-family: Arial;
}
.user-index .main-user-index .main-user-right .bottom-menu-info {
  width: 100%;
  height: 100px;
  margin: 35px 0 0 0;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .yet {
  width: 600px;
  float: left;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .yet span {
  color: #828282;
  font-size: 12px;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav {
  width: 400px;
  float: right;
  position: relative;
  overflow: hidden;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav .summary {
  display: inline;
  font-size: 12px;
  color: #494949;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav ul {
  padding: 0;
  margin: 0;
  float: right;
  display: inline-flex;
  list-style-type: none;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav ul .active {
  color: #000000;
  background: #f9cd1b;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav ul .next {
  color: #eec41c;
  background: #2a2a2a;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav ul .next a {
  color: #eec41c;
}
.user-index .main-user-index .main-user-right .bottom-menu-info .forum-nav ul li {
  color: #2a2a2a;
  margin: 0 8px 0 0;
  background: #cdcdcd;
  padding: 1px 6px 2px 6px;
}
/* Content-index
-------------------------------------------------------------------------------------------------------------------*/
.site-login {
  width: 800px;
  margin: 0 auto;
}
.site-login .form-horizontal .form-group:last-child {
  top: -25px;
  display: block;
  position: relative;
}
.site-login .form-horizontal .form-group:last-child .col-lg-11 {
  display: inline-block;
  width: auto !important;
  margin: 0;
}
.site-login .form-horizontal .form-group .ubi-register {
  display: inline-block;
  line-height: 47px;
  float: right;
  margin: 0 20px 0 0;
}
.site-login h1 {
  text-align: center;
  margin: 40px 0;
}
.site-login .col-lg-3 {
  width: 100%;
}
.site-login .form-horizontal {
  width: 300px;
  margin: 20px auto;
}
.site-login .eauth-service-id-yahoo {
  display: none;
}
.site-login .eauth-service-id-github {
  display: none;
}
.site-login .eauth-service-id-live {
  display: none;
}
.site-login .eauth-service-id-steam {
  display: none;
}
.site-login .col-lg-offset-1 {
  display: none;
}
.site-login .col-lg-offset-1.col-lg-11 {
  display: block;
}
/* header
-------------------------------------------------------------------------------------------------------------------*/
.menu-index-logo {
  float: left;
  width: 225px;
  padding: 10px 0;
}
.menu-index-logo a {
  display: inline-block;
  color: #282828;
  text-decoration: none;
  margin: 0 0 0 30px;
}
.menu-index-logo a .footer-logo {
  filter: grayscale(1);
}
.menu-index-logo img {
  float: left;
}
.menu-index-logo span {
  display: block;
  font-size: 11px;
  padding: 7px 0 7px 63px;
  line-height: 13px;
}
.menu-index-logo b {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 21px;
}
.menu-enter {
  width: 100%;
  position: relative;
  z-index: 5;
  border-bottom: 1px solid #F5F5F5;
}
.menu-enter .progress-bar {
  display: none;
  height: 4px;
  float: none;
  width: 100%;
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  box-shadow: none;
}
.menu-enter .progress-bar #progress {
  height: 100%;
  padding-right: 100%;
}
.menu-enter .progress-bar #progress div {
  background: #39b54a;
  height: 100%;
}
.menu-enter .new-enter {
  color: #fff;
  float: right;
  font-size: 18px;
  width: 247px !important;
  margin: -15px 25px 0 0;
}
.menu-enter .new-enter .avatarr {
  margin: -4px 0 0 20px;
  border-radius: 25px;
}
.menu-enter .new-enter a {
  text-transform: lowercase;
  float: right;
  color: #fff;
  display: inline-block;
  width: 70px;
  height: 30px;
  font-size: 12px;
  cursor: pointer;
  padding: 3px 10px;
  margin: 0 0 0 15px;
  text-decoration: none;
  font-family: ProximaNova, serif;
  border: 3px solid #FFDE13;
  line-height: 16px;
  text-align: center;
  border-radius: 15px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}
.menu-enter .new-enter a:hover,
.menu-enter .new-enter a:active {
  background: #FFDE13;
  color: #666;
  border: none;
  line-height: 22px;
}
.menu-enter .new-enter .enter {
  margin: 10px 10px 0 0;
}
.menu-block {
  padding: 0;
  display: block;
  margin: 0;
  position: relative;
  list-style-type: none;
  max-height: 68px;
}
.menu-block.tablet {
  display: none;
}
.menu-block ul {
  display: inline-block;
  padding: 0;
}
.menu-block li,
.menu-block > div {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  line-height: 68px;
  float: left;
}
.menu-block li.right,
.menu-block > div.right {
  float: right;
}
.menu-block li.no-float,
.menu-block > div.no-float {
  float: none;
}
.menu-block li.menu-mobile,
.menu-block > div.menu-mobile {
  display: none;
  border: medium none;
  margin: 0;
  padding: 25px;
  background: #FAFCFC;
  float: left;
  cursor: pointer;
}
.menu-block li.menu-mobile .icon-bar,
.menu-block > div.menu-mobile .icon-bar {
  display: block;
  background-color: #FFCA00;
  width: 14px;
  height: 4px;
  margin-bottom: 2px;
}
.menu-block li.tablet,
.menu-block > div.tablet,
.menu-block li.little-tablet,
.menu-block > div.little-tablet {
  display: none;
}
.menu-block li.dacha,
.menu-block > div.dacha {
  display: block;
  background: #FFCA00 url('/images/agronomu/dacha-video.png') no-repeat 25px center;
}
.menu-block li.dacha a,
.menu-block > div.dacha a {
  line-height: 16px;
  padding: 18px 30px 18px 75px;
  color: #fff5d0;
  background: transparent;
}
.menu-block li.dacha a .text,
.menu-block > div.dacha a .text {
  display: block;
  font-size: 12px;
  text-transform: none;
}
.menu-block li.dacha a:hover,
.menu-block > div.dacha a:hover,
.menu-block li.dacha a:focus,
.menu-block > div.dacha a:focus {
  background: transparent;
}
.menu-block li a,
.menu-block > div a {
  color: #282828;
  display: block;
  cursor: pointer;
  padding: 0 20px;
  text-decoration: none;
  font-family: ProximaNova, serif;
  font-weight: bold;
  text-transform: uppercase;
}
.menu-block li a.admin-block,
.menu-block > div a.admin-block {
  padding: 0;
}
.menu-block li a:not(.social):not(.admin-block):hover,
.menu-block > div a:not(.social):not(.admin-block):hover,
.menu-block li a:not(.social):not(.admin-block):focus,
.menu-block > div a:not(.social):not(.admin-block):focus {
  background: #fafcfc;
}
.menu-block li a .last,
.menu-block > div a .last {
  margin: 0 -5px 0 15px;
}
.menu-block li.auth,
.menu-block > div.auth {
  width: 225px;
  line-height: 1.1;
}
.menu-block li.auth .avatar-link,
.menu-block > div.auth .avatar-link {
  padding: 15px 20px;
  height: 68px;
}
.menu-block li.auth .avatar-image,
.menu-block > div.auth .avatar-image {
  float: right;
}
.menu-block li.auth .avatar-block,
.menu-block > div.auth .avatar-block {
  display: block;
  padding: 4px 52px 4px 0;
  text-align: right;
}
.menu-block li.auth .avatar-block > div,
.menu-block > div.auth .avatar-block > div {
  display: inline-block;
  text-align: left;
}
.menu-block li.auth .avatar-block span,
.menu-block > div.auth .avatar-block span {
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  height: 16px;
  overflow: hidden;
}
.menu-block li.auth .avatar-block a,
.menu-block > div.auth .avatar-block a {
  padding: 0;
  text-transform: none;
  font-weight: normal;
  font-size: 12px;
}
.menu-block li.auth .avatar-block a:hover,
.menu-block > div.auth .avatar-block a:hover,
.menu-block li.auth .avatar-block a:focus,
.menu-block > div.auth .avatar-block a:focus {
  background: transparent;
}
.menu-block li.active:not(.dacha) a,
.menu-block > div.active:not(.dacha) a {
  background: #fafcfc;
}
.menu-block li.active:not(.dacha) a:hover,
.menu-block > div.active:not(.dacha) a:hover,
.menu-block li.active:not(.dacha) a:focus,
.menu-block > div.active:not(.dacha) a:focus {
  background: #fafcfc;
}
.menu-block li.search,
.menu-block > div.search {
  padding: 0 20px;
  border-left: 1px solid #F5F5F5;
  border-right: 1px solid #F5F5F5;
  cursor: pointer;
}
.menu-block li.search input,
.menu-block > div.search input {
  display: none;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #f5f5f5;
  padding: 10px 50px 10px 30px;
  background: #fafcfc url('/images/agronomu/search.png') no-repeat right 25px top 26.5px;
}
.menu-block li.search input:hover,
.menu-block > div.search input:hover,
.menu-block li.search input:focus,
.menu-block > div.search input:focus {
  display: block;
}
.menu-block li.search .search-window,
.menu-block > div.search .search-window {
  width: 25px;
  height: 68px;
  background: url('/images/agronomu/search.png') no-repeat center;
}
.menu-block li.search:hover input,
.menu-block > div.search:hover input,
.menu-block li.search.active input,
.menu-block > div.search.active input {
  display: block;
}
.menu {
  display: flex;
  position: relative;
}
.menu .menu-block {
  width: 100%;
}
/* footer
-------------------------------------------------------------------------------------------------------------------*/
.footer-block {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.footer-index {
  padding: 25px 30px 20px;
  line-height: 30px;
  font-size: 14px;
}
.footer-index .text {
  font-size: 12px;
  line-height: 18px;
}
.footer-index .link-block {
  float: right;
}
.footer-index .socials {
  text-align: center;
  padding-top: 15px;
  line-height: 1;
}
.footer-index .socials a {
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
}
/* index- post
-------------------------------------------------------------------------------------------------------------------*/
.index-post {
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #4c4c4c;
}
.index-post table {
  margin-bottom: 30px;
}
.index-post .answer-table-block {
  border: 1px solid #F0F7F7;
  border-collapse: collapse;
}
.index-post .answer-table-block tr:nth-child(odd) {
  background: rgba(240, 247, 247, 0.5);
}
.index-post .answer-table-block tr td {
  padding: 4px 15px;
  border: 1px solid #F0F7F7;
  width: 65%;
}
.index-post .answer-table-block tr td:first-child {
  width: 35%;
}
.index-post .table-bordered {
  border: 1px solid #C4C4C4;
  border-collapse: collapse;
}
.index-post .table-bordered td {
  border: 1px dashed #C4C4C4;
  font-size: 18px;
  line-height: 1;
  padding: 20px 15px;
  width: 1%;
}
.index-post .table-bordered td p {
  margin: 0;
}
.index-post .table-bordered:not(.no-header) tr:first-child td {
  border-bottom-style: solid;
  background: #FFFAE5;
  font-weight: bold;
}
.index-post a {
  color: #2c74bb;
  text-decoration: underline rgba(119, 149, 213, 0.2);
}
.index-post ul {
  padding-left: 40px;
}
.index-post .review-block {
  border-radius: 2px;
  background: #ebf2e2;
  margin: 30px 0;
  overflow: hidden;
}
.index-post .review-block .text {
  padding: 25px 30px 60px;
}
.index-post .review-block .author-block {
  margin: 0px;
  padding: 35px 30px 35px 275px;
  background: #d4dfc5;
  font-size: 24px;
  line-height: 24px;
  position: relative;
}
.index-post .review-block .author-block img {
  position: absolute;
  top: -30px;
  left: 0;
  margin: 0;
}
.index-post .review-block .author {
  color: #282828;
  font-weight: 600;
  margin-bottom: 7px;
}
.index-post .review-block .source {
  line-height: 18px;
  color: #4c4c4c;
  font-size: 14px;
}
.index-post .index-post-slaider {
  margin: 60px 0 30px 0;
}
.index-post h2,
.index-post h3,
.index-post h4,
.index-post h5,
.index-post h6 {
  clear: both;
  line-height: 30px;
  font-weight: 600;
}
.index-post h2 {
  font-size: 36px;
}
.index-post h3 {
  font-size: 30px;
}
.index-post h4 {
  font-size: 24px;
}
.index-post h5 {
  font-size: 24px;
  font-weight: 400;
}
.index-post h6 {
  font-size: 18px;
}
.index-post img {
  margin: 10px auto;
  display: block;
}
.index-post p {
  color: #4c4c4c;
  text-align: left;
}
.index-post > p,
.index-post > div {
  margin: 30px 0;
}
.index-post iframe {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
@media (max-width: 640px) {
  .index-post iframe {
    height: calc(100vw * 406 / 620);
  }
}
.index-post p:not([style="margin: 0px;"]) + ol {
  margin-top: -20px;
}
.index-post .bb-image,
.index-post .bb-video {
  margin: 30px 0;
  display: block;
}
.index-post .bb-image + .bb-image,
.index-post .bb-video + .bb-image {
  margin: 30px 0 0 0;
}
.index-post .bb-image img,
.index-post .bb-video img {
  cursor: pointer;
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
}
.index-post .bb-image.bb-image-align-left,
.index-post .bb-video.bb-image-align-left,
.index-post .bb-image.bb-image-align-right,
.index-post .bb-video.bb-image-align-right {
  margin: 0;
  width: 330px;
}
.index-post .bb-image.bb-image-align-left img,
.index-post .bb-video.bb-image-align-left img,
.index-post .bb-image.bb-image-align-right img,
.index-post .bb-video.bb-image-align-right img {
  width: 100%;
}
.index-post .bb-image.bb-image-align-left,
.index-post .bb-video.bb-image-align-left {
  float: left;
  padding: 5px 30px 30px 0;
}
.index-post .bb-image.bb-image-align-right,
.index-post .bb-video.bb-image-align-right {
  float: right;
  padding: 5px 0 30px 30px;
}
.index-post .bb-image .bb-image-desc,
.index-post .bb-video .bb-image-desc {
  padding: 25px 20px;
  border-radius: 2px;
  background: #f0f7f7;
  font-size: 14pt;
  line-height: 20pt;
  color: #718585;
  display: block;
  max-width: 740px;
  margin: 0 auto;
  width: 90%;
  width: calc(100% - 25px);
  text-align: center;
}
.index-post ol .bb-image-align-left {
  margin: 5px 10px 5px -40px;
}
.index-post ul .bb-image-align-left {
  margin: 5px 10px 5px 0;
}
.index-post .br {
  display: block;
  clear: both;
}
.index-post .contents {
  padding: 20px 30px 20px 10px;
  background-color: #f0f7f7;
  clear: both;
  margin-top: 30px;
}
.index-post .contents .title-block.comments {
  margin: 8px 20px 15px;
  float: left;
}
.index-post .contents .title-block.comments > div {
  display: inline;
}
.index-post .contents #hide-contents {
  float: right;
  margin: 4px;
}
.index-post .contents #hide-contents .hide {
  display: none;
}
.index-post .contents ul {
  margin: 0;
  list-style-type: none;
  padding: 0;
  margin-left: 20px;
}
.index-post .contents li {
  margin: 0;
  padding: 5px 0;
}
.index-post .contents li a {
  padding-left: 20px;
  display: inline-block;
  color: #474747;
  text-decoration-color: #d8e5f1;
  transition: all 0.3s ease;
  position: relative;
}
.index-post .contents li a::before,
.index-post .contents li a::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 6px;
  top: 10px;
  left: 0;
  transition: all 0.3s ease;
}
.index-post .contents li a::after {
  background: url('/images/agronomu/post2.png') no-repeat;
  opacity: 0;
}
.index-post .contents li a::before {
  background: url('/images/agronomu/post1.png') no-repeat;
  opacity: 1;
}
.index-post .contents li a:hover::before {
  opacity: 0;
}
.index-post .contents li a:hover::after {
  opacity: 1;
}
.index-post .contents.hide-text .title-block.comments {
  margin-bottom: 8px;
}
.index-post .contents.hide-text ul {
  display: none;
}
.index-post .contents.hide-text #hide-contents span {
  display: none;
}
.index-post .contents.hide-text #hide-contents span.hide {
  display: inline;
}
.index-post .ab {
  clear: both;
  display: inline-block;
}
.index-post .ab p {
  margin: 0;
}
.index-post .ab .bb-image,
.index-post .ab .bb-video {
  width: 480px;
}
.index-post .ab .bb-image img,
.index-post .ab .bb-video img {
  width: 480px;
}
.index-post .ab .bb-image.bb-image-align-left,
.index-post .ab .bb-video.bb-image-align-left,
.index-post .ab .bb-image.bb-image-align-right,
.index-post .ab .bb-video.bb-image-align-right {
  width: 150px;
}
.index-post .ab .bb-image.bb-image-align-left img,
.index-post .ab .bb-video.bb-image-align-left img,
.index-post .ab .bb-image.bb-image-align-right img,
.index-post .ab .bb-video.bb-image-align-right img {
  width: 150px;
  height: 100px;
}
.index-post .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background: #4a4a4a;
}
.index-post .flex-control-paging li .flex-active {
  background: #308fed;
}
.index-post .flex-direction-nav a {
  opacity: 1;
}
.index-post .ab {
  margin: 27px 0;
  padding: 20px 25px;
  float: none;
  display: block;
  overflow: hidden;
  border-radius: 2px;
}
.index-post .ab-warning,
.index-post .ab-danger,
.index-post .ab-tip,
.index-post .ab-info,
.index-post .ab-definition {
  padding-left: 120px;
  position: relative;
}
.index-post .ab-warning > div,
.index-post .ab-danger > div,
.index-post .ab-tip > div,
.index-post .ab-info > div,
.index-post .ab-definition > div {
  width: 100%;
  display: table;
  height: 78px;
}
.index-post .ab-warning > div > div,
.index-post .ab-danger > div > div,
.index-post .ab-tip > div > div,
.index-post .ab-info > div > div,
.index-post .ab-definition > div > div {
  display: table-cell;
  vertical-align: middle;
}
.index-post .ab-definition {
  background-color: rgba(196, 224, 32, 0.1);
}
.index-post .ab-definition::before,
.index-post .ab-definition::after {
  position: absolute;
  content: "";
  background: url('/images/agronomu/icons/ab-definition.png');
  top: 0;
  bottom: 0;
  margin: auto;
  left: 25px;
  width: 70px;
  height: 70px;
  opacity: 1;
  transition: all 0.7s ease;
}
@media all and (max-width: 767px) {
  .index-post .ab-definition::before,
  .index-post .ab-definition::after {
    bottom: initial;
    right: 0;
    left: 0;
    top: 25px;
  }
}
.index-post .ab-definition::after {
  background: url('/images/agronomu/icons/ab-definition2.png');
  opacity: 0;
}
.index-post .ab-definition:hover {
  transition: all 0.7s ease;
}
.index-post .ab-definition:hover::before {
  opacity: 0;
}
.index-post .ab-definition:hover::after {
  opacity: 1;
}
.index-post .ab-warning,
.index-post .ab-danger {
  background-color: #fff4f4;
}
.index-post .ab-warning::before,
.index-post .ab-danger::before {
  position: absolute;
  content: "";
  background: url('/images/agronomu/icons/ab-warning.png');
  top: 0;
  bottom: 0;
  margin: auto;
  left: 25px;
  width: 70px;
  height: 70px;
}
@media all and (max-width: 767px) {
  .index-post .ab-warning::before,
  .index-post .ab-danger::before {
    bottom: initial;
    right: 0;
    left: 0;
    top: 25px;
  }
}
.index-post .ab-warning::after,
.index-post .ab-danger::after {
  position: absolute;
  content: "";
  background: url('/images/agronomu/icons/ab-warning2.png');
  top: -12px;
  bottom: 0;
  margin: auto;
  left: 28px;
  width: 63px;
  height: 63px;
  animation: rotate-m 8s infinite linear;
}
@media all and (max-width: 767px) {
  .index-post .ab-warning::after,
  .index-post .ab-danger::after {
    bottom: initial;
    right: 0;
    left: 0;
    top: 25px;
  }
}
@keyframes rotate-m {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.index-post .ab-luck {
  border: solid #50ffdc;
}
.index-post .ab-tip,
.index-post .ab-info {
  background-color: #f0f7f7;
}
.index-post .ab-tip::before,
.index-post .ab-info::before,
.index-post .ab-tip::after,
.index-post .ab-info::after {
  position: absolute;
  content: "";
  background: url('/images/agronomu/icons/ab-info.png');
  top: 0;
  bottom: 0;
  margin: auto;
  left: 25px;
  width: 70px;
  height: 70px;
  opacity: 1;
  transition: all 0.7s ease;
}
@media all and (max-width: 767px) {
  .index-post .ab-tip::before,
  .index-post .ab-info::before,
  .index-post .ab-tip::after,
  .index-post .ab-info::after {
    bottom: initial;
    right: 0;
    left: 0;
    top: 25px;
  }
}
.index-post .ab-tip::after,
.index-post .ab-info::after {
  background: url('/images/agronomu/icons/ab-info2.png');
  opacity: 0;
}
.index-post .ab-tip:hover,
.index-post .ab-info:hover {
  transition: all 0.7s ease;
}
.index-post .ab-tip:hover::before,
.index-post .ab-info:hover::before {
  opacity: 0;
}
.index-post .ab-tip:hover::after,
.index-post .ab-info:hover::after {
  opacity: 1;
}
.index-post * a {
  transition: all 0.3s ease;
}
.index-post * a:hover {
  transition: all 0.3s ease;
  color: #e91010;
  text-decoration-color: #e91010;
}
/* index-post-comments
-------------------------------------------------------------------------------------------------------------------*/
.index-post-comments {
  float: left;
  overflow: hidden;
}
.index-post-comments span {
  color: #333333;
  font-size: 13px;
  padding: 1px 0 0 25px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("/images/forum/zakladka.png");
}
.index-post-comments .index-post-event-tag-news .tag-news {
  margin: 10px 0;
  line-height: 1;
  overflow: hidden;
}
.index-post-comments .index-post-event-tag-news .tag-news .tag-news-left {
  width: 64px;
  float: left;
}
.index-post-comments .index-post-event-tag-news .tag-news .tag-news-left img {
  margin: 0;
}
.index-post-comments .index-post-event-tag-news .tag-news .tag-news-right {
  width: 85%;
  float: left;
}
.index-post-comments .index-post-event-tag-news .tag-news .tag-news-right p {
  margin: 0;
  font-size: 15px;
}
.index-post-comments .index-post-event-tag-news .tag-news .tag-news-right .tag-news-right-data-public {
  font-size: 13px;
  font-weight: bold;
  padding: 10px 0 0 0;
}
.index-post-comments .tag-list a {
  color: #282828;
  background: rgba(255, 202, 0, 0.1);
  text-decoration: none;
  margin: 1px 1px 1px 3px;
  font-size: 11pt;
  padding: 1px 6px;
  border-radius: 3px;
  display: inline-block;
}
.index-post-comments .tag-list a:hover {
  background: #f5f5f5;
}
.index-post-comments .comment-index-button {
  width: 100%;
  height: 70px;
}
.index-post-comments .comment-index-button h1 {
  float: left;
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 900;
}
.index-post-comments .comment-index-button a {
  float: right;
  color: #333333;
  cursor: pointer;
  font-size: 16px;
  padding: 3px 15px;
  margin: 22px 0 0 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  border: 2px solid #5c5c5c;
}
.index-post-comments .index-comment {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top: 1px solid #c2c2c2;
}
.index-post-comments .index-comment a {
  float: left;
}
.index-post-comments .index-comment a img {
  margin: 20px 0 0 0;
  border-radius: 35px;
}
.index-post-comments .index-comment .text {
  float: left;
  width: 480px;
  margin: 0 0 0 40px;
}
.index-post-comments .index-comment .text h1 {
  color: #222222;
  font-size: 15px;
  font-weight: bold;
}
.index-post-comments .index-comment .text h1 span {
  color: #8c8c8c;
  font-size: 15px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: italic;
  background-image: none;
}
.index-post-comments .index-comment .text span {
  padding: 0;
  color: #222222;
  font-size: 16px;
  background-image: none;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.index-post-comments .index-comment .text a {
  color: #8c8c8c;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0 20px 0;
}
.index-post-comments .index-comment-otvet {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top: 1px solid #c2c2c2;
}
.index-post-comments .index-comment-otvet a {
  float: left;
  margin: 0 0 0 108px;
}
.index-post-comments .index-comment-otvet a img {
  margin: 20px 0 0 0;
  border-radius: 35px;
}
.index-post-comments .index-comment-otvet .text {
  float: left;
  width: 400px;
  margin: 0 0 0 20px;
}
.index-post-comments .index-comment-otvet .text h1 {
  color: #222222;
  font-size: 15px;
  font-weight: bold;
}
.index-post-comments .index-comment-otvet .text h1 span {
  color: #8c8c8c;
  font-size: 15px;
  padding: 0 10px 0 5px;
  background-image: none;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: italic;
}
.index-post-comments .index-comment-otvet .text span {
  padding: 0;
  color: #222222;
  font-size: 16px;
  background-image: none;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.index-post-comments .index-comment-otvet .text a {
  margin: 0;
  color: #8c8c8c;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0 20px 0;
}
/* index-post-title
-------------------------------------------------------------------------------------------------------------------*/
.index-post-title {
  width: 100%;
  overflow: hidden;
}
.index-post-title .title-center {
  width: 1323px;
  min-height: 343px;
  margin: 0 auto;
  position: relative;
  /*-webkit-box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);
    box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);*/
}
.index-post-title .title-center:before {
  content: '';
  position: absolute;
  width: 1323px;
  height: 200px;
  left: 0;
  bottom: 0;
  margin: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(78%, rgba(0, 0, 0, 0.8)), color-stop(99%, rgba(0, 0, 0, 0.8)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
}
.index-post-title .title-center .index-post-into-title {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.index-post-title .title-center .index-post-into-title h2,
.index-post-title .title-center .index-post-into-title h1 {
  color: #FFFFFF;
  font-size: 32px;
  padding-left: 45px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.index-post-title .title-center .index-post-into-title .block-banner {
  margin: 10px 0 20px 30px;
}
.index-post-title .title-center .index-post-into-title .block-banner a {
  color: #cccaca;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}
.index-post-title .title-center .index-post-into-title .block-banner a img {
  margin: -2px 10px 0 15px;
}
.index-post-title .title-center .index-post-into-title .block-banner a p {
  color: #000;
  font-size: 14px;
  display: inline;
  font-weight: bold;
  border-radius: 5px;
  background: #ffdf3f;
  padding: 0px 6px 0 6px;
}
/* content-main-post
-------------------------------------------------------------------------------------------------------------------*/
.content-main-post {
  overflow: hidden;
  position: relative;
  background: #FFFFFF;
  /*.content-main-post-form {
    float: left;
    width: 890px;
    position: relative;
    .content-main-post-text {
      float: left;
      width: 620px;
      height: auto;
      h1 {
        margin: 0 0 25px 0;
        font-weight: bold;
        font-size: 30px;
        color: #3c2313;
      }
      .show-more {
        width: 939px;
        height: 50px;
        overflow: hidden;
        text-align: center;
        margin: 20px 0 0 0;
        background: #308fed;
        padding: 15px 0 15px 0;
        a {
          color: #333333;
          cursor: pointer;
          font-size: 16px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
        img {
          margin: -4px 0 0 0;
        }
      }
    }
    .social-block-on-980 {
      display: none;
    }
    .content-main-post-widgets {
      float: left;
      width: 220px;
      margin: 0 25px;
      min-height: 200px;
    }
    .index-post {
      width: 620px;
      float: left;
      //padding-right: 20px;
    }
  }
  .content-main-post-banner {
    float: left;
    width: 300px;
    height: auto;
    ul {
      list-style-type: none;
      li:last-child {
        margin: 0;
      }
      li {
        margin: 0 0 30px 0;
      }
    }
    .widget-sinoptik {
      //height: 143px;
      border-radius: 5px;
    }
    .widget-changegid {
      height: 367px;
      margin: 0 0 20px 0;
      background: #f5f4f0;
    }
    .widget-rozsilka {
      width: 100%;
      height: auto;
      .widget-rozsilka-title {
        height: 50px;
        background: #4a4a4a;
        h1 {
          color: #ffffff;
          font-size: 16px;
          padding: 16px 0 0 20px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .widget-rozsilka-index {
        background: #f5f4f0;
        input {
          border: none;
          height: 30px;
          width: 280px;
          color: #808080;
          font-size: 16px;
          padding: 0 0 0 15px;
          margin: 15px 0 20px 10px;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: italic;
        }
        a {
          color: #333333;
          font-size: 16px;
          margin: 0 0 0 10px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
          border: 2px solid #5c5c5c;
          padding: 3px 10px 3px 10px;
        }
        .text-help {
          padding: 20px 10px 20px 10px;
          span {
            color: #808080;
            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
      }
    }
    .widget-link-on-forum {
      width: 100%;
      height: auto;
      .link-on-forum-title {
        height: 50px;
        background: #4a4a4a;
        h1 {
          color: #ffffff;
          font-size: 16px;
          padding: 16px 0 0 20px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .link-on-forum-main {
        width: 100%;
        height: auto;
        overflow: hidden;
        background: #f5f4f0;
        .link-on-forum-text {
          height: auto;
          width: 270px;
          padding: 10px;
          margin: 10px auto;
          background: #fafaf8;
          h1 {
            color: #4a4a4a;
            font-size: 16px;
            margin: 0 0 10px 0;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
          }
          h2 {
            color: #808080;
            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
          span {

            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
      }
    }
  }*/
}
.related-news-blocks {
  padding-top: 45px;
}
.related-news {
  margin: -15px;
}
.related-new {
  padding: 15px;
}
/* Слайдер
-------------------------------------------------------------------------------------------------------------------*/
.slider {
  z-index: 9;
  width: 620px;
  height: 520px;
  overflow: hidden;
  margin: 0 0 7px;
  position: relative;
}
.slider ul,
.slider li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.slider ul {
  width: 9999px;
}
.slider ul li {
  list-style-type: none;
  float: left;
  width: 700px;
  height: 290px;
}
.slider .nav {
  position: absolute;
  left: 260px;
  bottom: 12px;
}
.slider .nav span {
  opacity: 0.9;
  background: #4a4a4a;
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  display: block;
  float: left;
}
.slider .nav span.on {
  background: #308fed;
}
/* Слайдер
-------------------------------------------------------------------------------------------------------------------*/
.breadcrumb {
  background-color: transparent;
}
.about-title-transition {
  top: 0;
  margin: 0;
  width: 100%;
  min-height: 32px;
  opacity: 0.6;
  border-radius: 0;
  background: #000000;
  padding: 5px 0 0 0;
  position: absolute;
}
.about-title-transition ul.breadcrumb {
  margin: 0 0 0 135px;
  background-color: transparent;
}
.about-title-transition ul.breadcrumb li {
  margin: 0;
  padding: 0;
}
.about-title-transition ul.breadcrumb li:hover {
  background-color: transparent;
}
.about-title-transition ul.breadcrumb li:before {
  background: none;
}
.about-title-transition ul.breadcrumb a {
  color: #FFFFFF;
  cursor: pointer;
  font-size: 13px;
  margin: 0 20px 0 0;
  position: relative;
  text-decoration: none;
  padding: 0 10px 5px 15px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
}
.about-title-transition ul.breadcrumb a:before {
  top: 3px;
  width: 0;
  height: 0;
  content: ' ';
  right: -15px;
  position: absolute;
  border-style: solid;
  border-width: 7px 0 6.5px 12px;
  border-color: transparent transparent transparent #308fed;
}
#__utl-buttons-1 .uptl_toolbar > ul > li {
  margin-bottom: 12px !important;
}
#multiupload .b-thumb__preview {
  width: 160px !important;
  height: 160px !important;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  -moz-transition: -moz-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
}
#multiupload .b-thumb__progress {
  top: 150px;
  left: 10px;
  right: 10px;
  position: absolute;
}
/* last post
-------------------------------------------------------------------------------------------------------------------*/
.last-post {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.last-post .last-post-title {
  width: 100%;
  height: 50px;
  background: #4a4a4a;
}
.last-post .last-post-title h1 {
  margin: 0;
  color: #308fed;
  font-size: 24px;
  padding: 12px 0 12px 20px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.last-post .last-post-foto {
  position: relative;
  margin: 35px 0 30px 0;
}
.last-post .last-post-foto .index-news-foto:last-child {
  margin: 0 0 19px 0;
}
.last-post .last-post-foto .index-news-foto {
  float: left;
  cursor: pointer;
  overflow: hidden;
  margin: 0 20px 19px 0;
}
.last-post .last-post-foto .index-news-foto:before {
  content: '';
  position: absolute;
  width: 300px;
  height: 183px;
  margin: 2px 0 0 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.59) 82%, rgba(0, 0, 0, 0.89) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0);
  /* IE6-9 */
}
.last-post .last-post-foto .index-news-foto .title-name {
  height: 30px;
  width: 98px;
  margin: -185px 0 0 0;
  background: #308fed;
  position: absolute;
}
.last-post .last-post-foto .index-news-foto .title-name:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: -30px;
  border-top: 30px solid #308fed;
  border-right: 30px solid transparent;
}
.last-post .last-post-foto .index-news-foto .title-name span {
  color: #333333;
  font-size: 13px;
  position: absolute;
  margin: 6px 0 6px 10px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.last-post .last-post-foto .index-news-foto .title-banner-two {
  width: 285px;
  position: absolute;
  margin: -59px 0 0 0;
}
.last-post .last-post-foto .index-news-foto .title-banner-two h2 {
  margin: 0;
  color: #fefefe;
  font-size: 16px;
  padding: 0 0 10px 15px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.last-post .last-post-foto .index-news-foto .title-banner-two .block-banner {
  margin: -8px 0 0 0;
}
.last-post .last-post-foto .index-news-foto .title-banner-two .block-banner a {
  color: #308fed;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.last-post .last-post-foto .index-news-foto .title-banner-two .block-banner a img {
  margin: -2px 10px 0 15px;
}
.last-post .last-post-foto .index-news-foto .title-banner-two .block-banner a p {
  color: #ffffff;
  font-size: 14px;
  display: inline;
  border-radius: 5px;
  background: #ff0000;
  padding: 0px 6px 0 6px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.last-post .botton-banner {
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 10px 0 0 0;
}
/* скрипт прокручования в верх*/
#Go_Top {
  right: 60px;
  width: 40px;
  height: 40px;
  bottom: 176px !important;
  cursor: pointer;
  position: absolute;
  z-index: 5000;
  background: #308fed;
}
#Go_Top img {
  margin: 10px 0 0 10px;
}
.main-foto {
  width: 300px;
  height: 185px;
}
/*-------- Error --------*/
.site-error {
  width: auto;
  height: auto;
}
.site-error .site-error-content {
  height: 500px;
  margin: 40px 0 0 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url("/images/site/Error-foto-agro.jpg");
  -webkit-background-size: cover;
  background-size: cover;
}
.site-error .site-error-content .error-text {
  width: 490px;
  line-height: 25px;
  padding-left: 30px;
}
.site-error .site-error-content .error-text h1 {
  color: #f8c81f;
  font-size: 48px;
  margin: 0 0 50px 0;
  padding: 30px 0 0 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.site-error .site-error-content .error-text p {
  color: #fff;
  font-size: 18px;
  margin: 0 0 30px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.site-error .site-error-content .error-text span {
  color: #fff;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.site-error .site-error-content .error-text span a {
  color: #f8c81f;
  font-size: 16px;
  text-decoration: underline;
}
.site-error .site-error-content .search-in-error {
  width: 460px;
  height: auto;
  padding: 20px 0 0 0;
}
.site-error .site-error-content .search-in-error input {
  width: 400px;
  height: 48px;
  font-size: 18px;
  padding: 0 0 0 10px;
}
.site-error .site-error-content .search-in-error a {
  margin: 0 0 0 -3px;
  background: #4a4a4a;
  text-decoration: none;
  padding: 18px 18px 16px 18px;
}
/*-------------------- Страница реклама на сайте (Реклама на сайте)----------------------*/
.content-re {
  width: 100%;
  height: auto;
  position: relative;
}
.content-re table {
  margin: 0 0 25px 0;
}
.content-re table .re-table-title {
  text-align: center;
  background: #308fed;
  border: 2px solid #4a4a4a;
}
.content-re table tr {
  text-align: center;
}
.content-re table tr .name-banner {
  text-align: left;
}
.content-re table tr td:last-child {
  width: 100px;
}
.content-re table tr th p {
  font-size: 18px;
  margin: 10px 0 10px 25px;
}
.content-re table tr td {
  border: 2px solid #4a4a4a;
}
.content-re table tr td p {
  padding: 10px;
  font-size: 18px;
}
.content-re table tr td span {
  font-size: 16px;
  padding: 0 10px 0 10px;
}
.content-re .contact-re {
  top: 0;
  right: 0;
  width: 400px;
  height: auto;
  position: absolute;
}
.content-re .contact-re span {
  font-size: 16px;
  line-height: 26px;
}
/*--------Авто точки ---------*/
.auto-point {
  width: 100%;
  height: auto;
  position: relative;
  padding: 0 0 1px 0;
  background: #4a4a4a;
}
.auto-point h2 {
  color: #fff;
  font-size: 32px;
  padding: 40px 0 0 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.auto-point h2 a {
  color: #308fed;
  cursor: pointer;
  font-size: 32px;
  text-decoration: none;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  border-bottom: 2px dashed #308fed;
}
.auto-point .content-point {
  overflow: hidden;
  margin: 45px auto;
  position: relative;
}
.auto-point .content-point .title-point {
  float: left;
  width: 300px;
  height: auto;
  margin: 0 23px 0 0;
}
.auto-point .content-point .title-point .point:hover {
  background: #308fed;
}
.auto-point .content-point .title-point .point:hover:before {
  width: 0;
  content: '';
  height: 17px;
  position: absolute;
  margin: 0 0 0 250px;
  border: 19px solid transparent;
  border-left: 12px solid #308fed;
}
.auto-point .content-point .title-point .point:hover a {
  color: #333333;
  text-decoration: none;
}
.auto-point .content-point .title-point .button-in-point {
  margin: 40px 0 0 0;
}
.auto-point .content-point .title-point .point {
  width: 250px;
  height: 37px;
  margin: 0 0 5px 0;
}
.auto-point .content-point .title-point .point .point-image {
  width: 37px;
  height: 37px;
  display: initial;
}
.auto-point .content-point .title-point .point a {
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  padding: 0 0 0 20px;
  display: inline-flex;
  text-decoration: none;
  font-family: ProximaNova;
}
.auto-point .content-point .title-point .point a sub {
  font-size: 11px;
  padding: 0 0 0 5px;
}
.auto-point .content-point .auto-map {
  float: left;
  width: 940px;
  height: 545px;
  border: 1px solid #ffffff;
}
/*----показать больше*----*/
.show-more-categori {
  width: 100%;
  height: 50px;
  cursor: pointer;
  text-align: center;
  margin: 0 0 20px 0;
  background: #308fed;
  padding: 16px 0 15px 0;
}
.show-more-categori a {
  color: #3d3d3d;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
}
.show-more-categori img {
  margin: -4px 0 0 0;
}
/*----------search----------*/
.search-content .search-content-title {
  width: 100%;
  height: auto;
  margin: 0 0 31px 0;
}
.search-content .search-content-title h1 {
  color: #4a4a4a;
  font-size: 24px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.search-content .search-content-title p {
  color: #808080;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.search-content .search-content-title p span {
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.search-content .search-content-main {
  width: 100%;
  height: auto;
  position: relative;
}
.search-content .search-content-main .search-content-main-search {
  float: left;
  height: auto;
  width: 300px;
  margin: 0 20px 0 0;
}
.search-content .search-content-main .search-content-main-search input {
  width: 195px;
  height: 28px;
  color: #222222;
  font-size: 16px;
  padding: 0 0 0 9px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.search-content .search-content-main .search-content-main-search a {
  color: #4a4a4a;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  margin: -1px 0 0 12px;
  border: 2px solid #4A4A4a;
  text-transform: uppercase;
  padding: 4px 10px 4px 10px;
}
.search-content .search-content-main .search-content-main-search .block-categori {
  width: 100%;
  height: auto;
  margin: 40px 0 0 0;
}
.search-content .search-content-main .search-content-main-search .block-categori p {
  color: #4a4a4a;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.search-content .search-content-main .search-content-main-search .block-categori p span {
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  padding: 0 0 0 15px;
}
.search-content .search-content-main .search-content-main-finish {
  float: left;
  height: auto;
  width: 600px;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 0 20px 0;
  position: relative;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box .search-content-image {
  float: left;
  width: 140px;
  margin: 0 20px 0 0;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box .search-content-text {
  float: left;
  width: 440px;
  padding: 0 20px 0 0;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box .search-content-text h1 {
  color: #222222;
  font-size: 18px;
  margin: 0 0 15px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box .search-content-text p {
  color: #222222;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.search-content .search-content-main .search-content-main-finish .search-content-main-search-box .search-content-text a {
  color: #ccad14;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.search-content .search-content-main .search-content-main-finish .search-jump {
  height: auto;
  width: 100%;
}
.search-content .search-content-main .search-content-main-finish .search-jump ul {
  text-align: center;
}
.search-content .search-content-main .search-content-main-finish .search-jump ul .previous a {
  padding: 13px;
  background: #308fed;
}
.search-content .search-content-main .search-content-main-finish .search-jump ul .next a {
  padding: 13px;
  background: #308fed;
}
.search-content .search-content-main .search-content-main-finish .search-jump ul li {
  cursor: pointer;
  display: inline;
}
.search-content .search-content-main .search-content-main-finish .search-jump ul li a {
  padding: 13px;
}
/*----------team----------*/
.team-content {
  width: 100%;
  height: auto;
  position: relative;
}
.team-content h1 {
  color: #4a4a4a;
  font-size: 24px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.team-content .team-content-people-box {
  width: 869px;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .team-content .team-content-people-box {
    width: 423px;
  }
}
@media (max-width: 992px) {
  .team-content .team-content-people-box {
    width: 869px;
  }
}
@media (max-width: 919px) {
  .team-content .team-content-people-box {
    width: 646px;
  }
}
@media (max-width: 696px) {
  .team-content .team-content-people-box {
    width: 423px;
  }
}
@media (max-width: 473px) {
  .team-content .team-content-people-box {
    width: 200px;
  }
}
.team-content .team-content-people {
  height: auto;
  position: relative;
  overflow: hidden;
  margin: -10px;
}
.team-content .team-content-people .team-people-box {
  text-align: center;
  height: auto;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 10px;
}
.team-content .team-content-people .team-people-box .team-people-box-foto {
  width: 200px;
  height: 200px;
}
.team-content .team-content-people .team-people-box .team-people-box-foto img {
  width: 100%;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: filter 0.2s, -webkit-filter 0.2s;
  -moz-transition: filter 0.2s, -webkit-filter 0.2s;
  -ms-transition: filter 0.2s, -webkit-filter 0.2s;
  -o-transition: filter 0.2s, -webkit-filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}
.team-content .team-content-people .team-people-box .team-people-box-position {
  padding: 10px 0;
  width: 200px;
  height: auto;
}
.team-content .team-content-people .team-people-box .team-people-box-position h1 {
  margin: 0;
  font-size: 16px;
}
.team-content .team-content-people .team-people-box .team-people-box-position p {
  font-size: 12px;
  margin: 7px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.team-content .team-content-people .team-people-box:hover .team-people-box-foto img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
/* game - угадай авто */
.static-text {
  font-size: 15px;
}
.static-text span {
  font-size: 16px;
  font-weight: bold;
}
.answer div {
  margin: 0 0 5px 0;
}
.answer div label {
  font-size: 20px;
  padding: 0 0 0 7px;
  font-weight: normal;
}
.next-question {
  background: #f9cd1b;
  border: 3px solid #5c5c5c;
  color: #333333;
  font-size: 16px;
  cursor: pointer;
  padding: 3px 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.next-question:hover {
  background: #fff;
}
.for-game {
  width: 940px;
  overflow: hidden;
  margin: 30px 0 0 0;
  position: relative;
  padding: 20px 0;
  border-top: 2px solid #b3b3b3;
}
.for-game:nth-child(4) {
  border: none;
}
.for-game .form-game {
  width: 300px;
  float: left;
  margin: 0 20px 0 0;
}
.for-game .form-game .watched-badge-game {
  background-color: #c6c6c6;
  top: 20px;
  color: #FFF;
  float: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3em;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  left: 220px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  z-index: 99;
}
.for-game .form-game .watched-badge-main-game {
  top: 0;
  width: 300px;
  height: 100%;
  position: absolute;
  opacity: 0.7;
  background: #989898;
}
.for-game .form-game a {
  cursor: pointer;
}
.for-game .form-game a img {
  width: 300px;
}
.for-game .form-game a .title {
  position: absolute;
  top: 150px;
  color: #308fed;
  padding: 0 0 0 10px;
}
.for-game .form-game a .title h1 {
  font-size: 27px;
}
.for-game .form-game-info .form-game-info-text {
  width: 100%;
  height: auto;
}
.for-game .form-game-info .form-game-info-text h1 {
  font-size: 23px;
  margin: 0px 0 10px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.for-game .form-game-info .form-game-info-text span {
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.for-game .form-game-info .form-game-info-text span p {
  color: #4a4a4a;
  font-size: 15px;
  margin: 8px 0 0 0;
}
.for-game .form-game-info .form-game-info-text .event-tag-news .tag-news {
  margin: 15px 0 0 0;
}
.for-game .form-game-info .form-game-info-text .event-tag-news .tag-news .tag-news-left {
  width: 50px;
  float: left;
  margin-right: 10px;
}
.for-game .form-game-info .form-game-info-text .event-tag-news .tag-news .tag-news-right {
  margin-left: 70px;
}
.for-game .form-game-info .form-game-info-text .event-tag-news .tag-news .tag-news-right .all-tag-news {
  float: right;
  cursor: pointer;
  font-size: 14px;
}
.for-game .form-game-info .button-game {
  right: 65px;
  float: right;
  margin: 15px 0 10px 0;
}
.for-game .form-game-info .button-game a:hover {
  cursor: pointer;
  background: #fff;
}
.for-game .form-game-info .button-game a {
  border: 2px solid #4a4a4a;
  font-size: 17px;
  padding: 3px 11px;
  color: #4a4a4a;
  background: #308fed;
  font-weight: bold;
}
.for-game .no-rights {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  background: #989898;
}
.images-auto img {
  width: 700px;
  margin: 0 0 20px 0;
}
.images-auto h1 {
  font-size: 23px;
  color: #4a4a4a;
}
.end-game {
  text-align: center;
}
.end-game h1 {
  color: #4a4a4a;
  font-size: 34px;
  font-weight: bold;
}
.end-game h2 {
  font-size: 22px;
}
.end-game span {
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
/* Новий дизайн категорий*/
.news-avto-block {
  float: left;
  width: 100%;
  padding: 30px;
  position: absolute;
  margin: 0;
  background: #FFF;
  box-shadow: 0 0 20px 0 rgba(202, 209, 217, 0.75);
  /*.enter {
    right: 0;
    bottom: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: absolute;
    background: #bcd6f8;
    img {
      position: absolute;
      right: 0;
      margin: 12px 0 0 12px;
    }
  }
  .enter-arrow {
    right: 11px;
    bottom: 12px;
    position: absolute;
  }*/
}
.news-avto-block .news-avto-block-img {
  float: left;
  margin: 0 30px 0 0;
  max-width: 230px;
}
.news-avto-block .news-avto-block-img img {
  max-width: 100%;
}
.news-avto-block .news-avto-block-title {
  width: 100%;
  margin: -4px 0 0;
  padding: 0 0 0 260px;
  line-height: 27px;
  font-size: 22px;
}
.news-avto-block .news-avto-block-title a {
  color: #222222;
  font-weight: bold;
}
.news-avto-block .news-avto-block-title .news-avto-block-title-text a {
  color: #222222;
  font-size: 18px;
  font-weight: bold;
}
.news-avto-block .news-avto-block-content {
  padding-left: 260px;
}
.news-avto-block .news-avto-block-content span {
  font-size: 18px;
  line-height: 27px;
}
.news-avto-block .news-avto-block-content span.posts-block {
  margin-top: 5px;
  display: inline-block;
  padding: 3px 16px;
  background: #FFFBEB;
  margin-left: 4px;
  border-radius: 2px;
}
.news-avto-block .news-avto-block-content span.posts-block:hover {
  background-color: rgba(255, 251, 235, 0.9);
}
.news-avto-block .news-avto-block-content span a {
  color: #5287c1;
  text-decoration: underline;
  transition: all 0.3s ease;
}
.news-avto-block .news-avto-block-content span a:hover {
  color: #e91010;
  text-decoration-color: #e91010;
}
.news-avto-block .news-avto-block-content span a.text {
  color: #333;
  text-decoration: none;
}
.news-avto-block .news-avto-block-content span a.posts {
  text-decoration: none;
}
.news-avto-block .news-avto-block-content span a.all-posts {
  text-decoration: none;
  color: #282828;
}
.news-avto-block .news-avto-block-content span ul {
  margin: 10px 0 0 20px;
  list-style: circle;
  color: rgba(40, 40, 40, 0.2);
}
.quest-button {
  right: 0;
  color: #4A4A4a;
  font-size: 17px;
  font-weight: bold;
  position: absolute;
  padding: 3px 11px;
  background: #308FED;
  margin: 30px 0 0 0;
  border: 2px solid #4a4a4a;
}
.pswp--open {
  display: block;
  z-index: 10000;
}
.fixed-hint {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  font-weight: normal !important;
}
.fixed-hint .loader-hint {
  width: 100%;
  height: 200px;
  position: absolute;
  background: url('/images/loading.gif') no-repeat center, #FFF;
}
.no-mobile {
  display: inline;
}
/*------------------------@Media - для головна --------------------------------*/
@media screen and (max-width: 1440px) {
  .content-main-index {
    width: 1170px;
    padding-bottom: 240px;
  }
  .content-index .content-index-left {
    width: 780px;
  }
  .category-items.absolute-type {
    width: 780px;
  }
}
/*------------------------@Media - для головна --------------------------------*/
@media screen and (max-width: 1199px) {
  /*content-main*/
  .desktop-block {
    display: none;
  }
  .tablet-block {
    display: block;
  }
  .popular-post-block {
    margin-bottom: 30px;
  }
  .popular-post-block .post:last-child {
    display: none;
  }
  .content-main-index {
    width: 980px;
    padding-bottom: 258px;
  }
  .content-index .content-index-left {
    width: 590px;
  }
  .category-items.absolute-type {
    width: 590px;
  }
  .menu-block.desktop,
  .menu-block li.desktop,
  .menu-block > div.desktop {
    display: none;
  }
  .menu-block.tablet,
  .menu-block li.tablet,
  .menu-block > div.tablet {
    display: block;
  }
  /* блок категория*/
  .content-index-left-text-new {
    overflow: hidden;
  }
  /*Заголовок статї*/
  .content-index .index-post-title .title-center {
    width: 980px;
    overflow: hidden;
  }
  .content-index .index-post-title .title-center:before {
    width: 980px;
  }
  /*подробно про категории*/
  .content-index-left .content-index-categori .content-index-left-foto-news {
    width: 300px;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li:nth-child(3) {
    display: none;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li:nth-child(4) {
    display: none;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li .post-avatar {
    margin: 0 0 20px 0;
  }
}
@media screen and (max-width: 1199px) and (min-width: 993px) {
  .news-avto-block .news-avto-block-content {
    padding: 0;
  }
  .table.main-tag-table {
    display: block;
  }
  .table.main-tag-table .table-cell {
    display: block;
  }
  .table.main-tag-table .table-cell.other-tag-table {
    display: table;
    width: 100%;
  }
  .other-table {
    width: 50%;
    display: table-cell;
  }
  .other-table.padding-bottom {
    padding-bottom: 0;
    padding-right: 15px;
  }
  .other-table.padding-top {
    padding-top: 0;
    padding-left: 15px;
  }
  .tag-children-list .types-list-blocks {
    position: relative;
    bottom: auto;
    overflow: hidden;
  }
  .tag-children-list .types-list-blocks li {
    float: none;
    width: auto;
    margin: 0 15px;
  }
  .tag-children-list .types-list-blocks li:not(:first-child) {
    margin-top: -4px;
  }
  .tag-children-list .types-list-blocks li:after {
    content: '';
  }
}
/*------------------------@Media - для головна --------------------------------*/
@media screen and (max-width: 992px) {
  .index-side-banner {
    height: 0;
    width: 0;
  }
  html {
    overflow: inherit;
  }
  /*меню */
  .no-little-tablet {
    display: none;
  }
  .little-tablet {
    display: block;
  }
  .menu-index-logo {
    width: 210px;
  }
  .menu-index-logo a {
    margin-left: 15px;
  }
  .content-index .content-index-left {
    width: 100%;
  }
  .content-index .content-index-right {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .category-items.absolute-type {
    width: 100%;
  }
  .menu-block .menu-block-hamburger {
    display: none;
    position: absolute;
    top: 100%;
    left: -210px;
    z-index: 1;
    margin-top: -3px;
    padding: 0;
    border-color: #FFCA00;
    border-style: solid;
    border-width: 3px 0 0;
    background: #FFF;
    width: 100vw;
  }
  .menu-block .menu-block-hamburger.footer-hamburger {
    top: auto;
    bottom: 100%;
    border-width: 0 0 3px;
  }
  .menu-block .menu-block-hamburger:hover,
  .menu-block .menu-block-hamburger:focus {
    display: block;
  }
  .menu-block .menu-block-hamburger li {
    line-height: 54px;
    display: block;
    border-bottom: 1px solid #F5F5F5;
    width: 100%;
  }
  .menu-block .menu-block-hamburger li a {
    padding: 0 35px;
  }
  .menu-block li.menu-mobile,
  .menu-block > div.menu-mobile {
    display: block;
  }
  .menu-block li.menu-mobile:hover + .menu-block-hamburger,
  .menu-block > div.menu-mobile:hover + .menu-block-hamburger,
  .menu-block li.menu-mobile:focus + .menu-block-hamburger,
  .menu-block > div.menu-mobile:focus + .menu-block-hamburger {
    display: block;
  }
  .menu-block li.no-little-tablet,
  .menu-block > div.no-little-tablet {
    display: none;
  }
  .menu-block li.little-tablet,
  .menu-block > div.little-tablet {
    display: block;
  }
  .title-block.block-right {
    padding-left: 0;
  }
  .title-block.block-right:before {
    left: -30px;
  }
  .commenting-block {
    border-color: #F7811D;
    margin-top: 20px;
  }
  /*content-main*/
  .content-main-index {
    width: 100%;
    padding-bottom: 263px;
  }
  .footer-index {
    padding-left: 15px;
    padding-right: 15px;
  }
  .footer-index .text {
    padding-left: 15px;
    height: auto;
  }
  .footer-index .link-block {
    z-index: 1;
    float: none;
    display: block;
  }
  .tag-children-list {
    margin: -15px -30px 0;
  }
  /*Заголовок статї*/
  .content-index .index-post-title {
    width: 100%;
    overflow: hidden;
  }
  .content-index .index-post-title .title-center {
    width: 100%;
  }
  .content-index .index-post-title .title-center:before {
    width: 100%;
  }
  .content-index .index-post-title .title-center img {
    margin: 0 -35%;
  }
  /*пост*/
  .content-main-post .content-main-post-form {
    width: 100%;
  }
  .content-main-post .content-main-post-form .content-main-post-text {
    float: none;
    width: 100%;
  }
  .content-main-post .content-main-post-form .content-main-post-text .index-post {
    float: none;
    width: 100%;
  }
  .content-main-post .content-main-post-form .content-main-post-text .index-post p {
    margin: 0 0 10px 0;
  }
  .content-main-post .content-main-post-form .content-main-post-text .index-post p:not([style="margin: 0px;"]) + ol {
    margin-top: 0;
  }
  .content-main-post .content-main-post-form .content-main-post-text .index-post span {
    display: none;
  }
  .content-main-post .content-main-post-form .content-main-post-text #div-gpt-ad-1466174275524-0,
  .content-main-post .content-main-post-form .content-main-post-text #div-gpt-ad-1466174275524-1 {
    display: none;
  }
  .content-main-post .content-main-post-form .social-block-on-980 {
    margin: 10px 0 0 0;
    display: block;
    height: 46px;
  }
  .content-main-post .content-main-post-form .social-block-on-980 > div {
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  .content-main-post .content-main-post-form .social-block-on-980 > div > div {
    max-width: 350px;
    margin: 0 auto;
  }
  .content-main-post .content-main-post-form .social-block-on-980 > div > div ul {
    width: 100%;
  }
  .content-main-post .content-main-post-form .social-block-on-980 > div > div ul li {
    width: 20%;
  }
  .content-main-post .content-main-post-form .social-block-on-980 > div > div ul li .sn-icon {
    width: 100% !important;
  }
  .content-main-post .content-main-post-form .comments {
    margin: 0;
    max-width: 100%;
  }
  .content-main-post .content-main-post-form .comments .c-title {
    font-size: 18px;
  }
  .content-main-post .content-main-post-form .comments .c-form textarea {
    max-width: 100%;
    min-width: 100%;
    resize: vertical;
    border: 1px solid;
  }
  .content-main-post .content-main-post-form .comments .c-form .c-focus-only {
    display: block;
    width: 100%;
    padding: 6px;
    margin: 0 auto;
    max-width: 450px;
  }
  .content-main-post .content-main-post-banner {
    display: none;
  }
  /*вивод игры*/
  .for-game {
    width: 630px;
  }
  .for-game .form-game {
    width: 200px;
  }
  .for-game .form-game a img {
    height: 123px;
    width: 200px;
  }
  .for-game .form-game .watched-badge-game {
    left: 140px !important;
  }
  .for-game .form-game .watched-badge-main-game {
    width: 200px;
    height: 123px;
  }
  /*показ фото играть*/
  .images-auto img {
    width: 630px;
  }
  /*подробно про категории*/
  .content-index-left .content-index-categori .content-index-left-foto-news {
    width: 300px;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li:nth-child(3) {
    display: none;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li:nth-child(4) {
    display: none;
  }
  .content-index-left .content-index-categori .content-index-left-foto-news ul li .post-avatar {
    margin: 0 0 20px 0;
  }
}
/*------------------------@Media - для головна --------------------------------*/
@media screen and (max-width: 767px) {
  /*content-main*/
  .no-mobile-block,
  .no-mobile {
    display: none;
  }
  .mobile-block {
    display: block;
  }
  .vertical-post.big-slider-post a .text-post {
    bottom: -5px;
  }
  .content-main-index {
    padding-bottom: 317px;
  }
  .footer-index .text {
    height: auto;
  }
  .content-index .index-post-title .title-center .index-post-into-title h1 {
    font-size: 28px;
  }
  .content-index .index-post-title .title-center .about-title-transition {
    display: none;
  }
  .index-post .bb-image:not(.bb-image-align-left):not(.bb-image-align-right),
  .index-post .bb-video,
  .index-post .contents {
    margin-left: -30px;
    margin-right: -30px;
  }
  .index-post .ab-warning,
  .index-post .ab-danger,
  .index-post .ab-tip,
  .index-post .ab-info,
  .index-post .ab-definition {
    padding: 90px 25px 20px;
    background-position: center 25px;
  }
  .index-post .contents {
    margin-left: -40px;
  }
  .index-post .contents ul {
    margin-left: 15px;
  }
  .index-post .contents ul li a {
    padding-left: 15px;
  }
  .index-post ul .bb-image:not(.bb-image-align-left):not(.bb-image-align-right),
  .index-post ol .bb-image:not(.bb-image-align-left):not(.bb-image-align-right),
  .index-post ul .bb-video,
  .index-post ol .bb-video {
    margin-left: -70px;
  }
  .index-post .review-block {
    margin: 30px -30px;
  }
  .index-post .review-block .author-block {
    padding: 30px;
  }
  .index-post .review-block .author-block img {
    display: none;
  }
  .index-post h1,
  .index-post h2,
  .index-post h3,
  .index-post h4,
  .index-post h5,
  .index-post h6 {
    word-break: break-word;
  }
  .fixed-hint {
    margin: 0 -30px;
    width: 100vw;
  }
  .block-poll-block {
    margin: 30px -30px !important;
  }
  .poll-text {
    display: block;
  }
  .poll-title {
    font-size: 14px;
    line-height: 13px;
    margin-bottom: 5px;
    padding: 0 20px;
  }
  .poll-title.no-title {
    font-weight: bold;
  }
  .poll-title.no-title div {
    margin-top: 6px;
  }
  .poll-button {
    display: block;
    float: left;
    width: auto;
    padding: 0 15px;
  }
  #poll-yes {
    margin-left: 5px;
  }
  .text-poll-block > div {
    height: auto;
  }
  .count-positive {
    padding: 0 20px 0 15px;
  }
  .count-positive.hidden-mobile {
    display: none;
  }
  .count-help-post {
    display: none;
  }
  .social-likes {
    padding-right: 0;
  }
  .social-likes__button {
    width: 24px;
    height: 24px;
  }
  .social-likes__counter {
    display: none;
  }
  .table.main-tag-table {
    display: block;
  }
  .table.main-tag-table .table-cell {
    display: block;
  }
  .table.main-tag-table .table-cell.other-tag-table {
    display: table;
    width: 100%;
  }
  .other-table {
    width: 50%;
    display: table-cell;
  }
  .other-table.padding-bottom {
    padding-bottom: 0;
    padding-right: 15px;
  }
  .other-table.padding-top {
    padding-top: 0;
    padding-left: 15px;
  }
  .tag-children-list .types-list-blocks {
    position: relative;
    bottom: auto;
    overflow: hidden;
  }
  .tag-children-list .types-list-blocks li {
    float: none;
    width: auto;
    margin: 0 15px;
  }
  .tag-children-list .types-list-blocks li:not(:first-child) {
    margin-top: -4px;
  }
  .tag-children-list .types-list-blocks li:after {
    content: '';
  }
  .table-block {
    overflow: auto;
  }
}
.position-content-main-post {
  z-index: 0;
}
/*--------------------Little Media----------------------------*/
@media screen and (max-width: 640px) {
  .news-avto-block .news-avto-block-img {
    float: none;
    margin: 0 0 20px;
    max-width: none;
    text-align: center;
  }
  .news-avto-block .news-avto-block-title {
    padding: 0;
  }
  .news-avto-block .news-avto-block-content {
    padding: 0;
  }
  .index-post .bb-image.bb-image-align-left,
  .index-post .bb-image.bb-image-align-right {
    float: none;
    text-align: center;
    width: 300px;
    padding: 0;
    margin: 30px auto;
  }
  .index-post .answer-table-block {
    display: block;
  }
  .index-post .answer-table-block tbody {
    display: block;
  }
  .index-post .answer-table-block tbody tr {
    display: block;
    padding: 4px 15px;
  }
  .index-post .answer-table-block tbody tr:not(:last-child) {
    border-bottom: 1px solid #F0F7F7;
  }
  .index-post .answer-table-block tbody tr td {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
  }
  .index-post .answer-table-block tbody tr td:first-child {
    width: 100%;
  }
  .category-items .category-item-block {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .no-little-mobile {
    display: none;
  }
  .little-mobile {
    display: block;
  }
  .menu-index-logo {
    width: 70px;
  }
  .menu-index-logo span {
    display: none;
  }
  .menu-block .menu-block-hamburger {
    left: -70px;
  }
  .menu-block li.auth,
  .menu-block > div.auth {
    width: 210px;
  }
  .vertical-post.slider-post,
  .vertical-post.big-slider-post {
    margin: 0 -30px 35px;
  }
  .horizontal-post.post-bok a img {
    width: 100%;
    height: auto;
  }
  .horizontal-post.post-bok a .text-post {
    margin-top: 20px;
    padding: 0;
    text-align: left;
  }
  .horizontal-post.post-bok a .text-post > div .text {
    padding-top: 10px;
  }
  .content-main-index {
    padding-bottom: 331px;
  }
  .footer-index .text {
    height: auto;
    font-size: 10px;
    color: #969696;
    line-height: 16px;
  }
  .index-post .bb-image.bb-image-align-left,
  .index-post .bb-image.bb-image-align-right {
    width: auto;
  }
  .main-tags-block li {
    width: 33.334%;
  }
  .table.main-tag-table .table-cell.other-tag-table {
    display: block;
  }
  .other-table {
    display: block;
    width: 100%;
  }
  .other-table.padding-bottom,
  .other-table.padding-top {
    padding-bottom: 15px;
    padding-right: 0;
  }
  .other-table.padding-top {
    padding-top: 15px;
    padding-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .main-tags-block li {
    width: 50%;
  }
  .category-items .category-item-block {
    width: 100%;
  }
}
@media screen and (max-width: 379px) {
  .content-main-index {
    padding-bottom: 411px;
  }
  .footer-index .text {
    height: auto;
  }
  .menu-block li.auth,
  .menu-block > div.auth {
    width: 185px;
  }
  .menu-block li.auth .avatar-link,
  .menu-block > div.auth .avatar-link {
    padding: 15px;
  }
  .menu-block li.auth .avatar-block,
  .menu-block > div.auth .avatar-block {
    padding-right: 50px;
  }
  .index-post .bb-image.bb-image-align-left,
  .index-post .bb-image.bb-image-align-right {
    margin: 30px -30px;
    padding: 0;
    max-width: none;
    float: none;
  }
  .index-post .contents .title-block.comments > div {
    display: none;
  }
}
@media screen and (max-width: 319px) {
  .menu-block li.search,
  .menu-block > div.search,
  .menu-block li a,
  .menu-block > div a {
    padding: 0 10px;
  }
  .menu-block li.search .last,
  .menu-block > div.search .last,
  .menu-block li a .last,
  .menu-block > div a .last {
    margin: 0 0 0 10px;
  }
  .menu-block li.auth,
  .menu-block > div.auth {
    width: 146px;
  }
  .menu-block li.auth .avatar-link,
  .menu-block > div.auth .avatar-link {
    padding-left: 10px;
  }
  .menu-block li.auth .avatar-block,
  .menu-block > div.auth .avatar-block {
    padding-right: 45px;
  }
  .content-main-index {
    padding-bottom: 344px;
  }
  .footer-index .link-block {
    float: none;
  }
  .footer-index .text {
    padding-left: 15px;
    padding-top: 15px;
    height: 159px;
  }
}
/*--------------------Event-update-slider----------------------------*/
.event-update UL.mytabs {
  position: relative;
  z-index: 2;
}
.event-update UL.mytabs,
.event-update UL.mytabs LI {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}
.event-update UL.mytabs LI {
  padding: 0 5px;
}
.event-update UL.mytabs LI A {
  float: left;
  padding: 7px;
  border: 1px solid #CCCCCC;
  border-bottom: 1px solid #E0E0E0;
  background: #F0F0F0;
  text-decoration: none;
  color: #333333;
}
.event-update UL.mytabs LI A:HOVER,
.event-update UL.mytabs LI.current A {
  background: #FFFFFF;
}
.event-update UL.mytabs LI.current A {
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid #FFFFFF;
}
.event-update .mytabs-container {
  position: relative;
  z-index: 1;
  clear: both;
  border: 1px solid #E0E0E0;
  padding: 10px;
  top: 0;
  background: #fff;
}
/*------------------------Banners----------------------*/
.index-image-banner {
  position: relative;
  z-index: 5;
}
.index-image-banner span {
  display: none;
}
.index-image-banner.mini {
  display: none;
}
@media (max-width: 1900px) {
  .index-image-banner {
    display: none;
  }
  .index-image-banner.mini {
    display: block;
  }
}
/*--------Admin-popup-created-event------*/
.event-form .one-text {
  padding: 25px 15px;
  margin-bottom: 20px;
  border: 2px solid maroon;
}
.event-form .one-text p {
  font-size: 27px;
  text-align: center;
  font-weight: normal;
  padding-bottom: 15px;
  border-bottom: 2px solid maroon;
}
.insert-social-image {
  width: 204px;
  min-height: 134px;
  border: 2px solid;
}
.insert-logo-image {
  width: 204px;
  min-height: 134px;
  border: 2px solid;
}
.insert-main-image {
  width: 204px;
  min-height: 134px;
  border: 2px solid;
}
/*------ RPopup ------*/
.RPopup-loader {
  z-index: 3000;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-image: url('/images/popup-loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
}
.RPopup-loader div:first-child {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.RPopup-loader div:first-child div:first-child {
  width: 50px;
  height: 20px;
  position: relative;
  top: 50px;
  left: -25px;
  padding: 0;
  font-size: 10px;
  line-height: 20px;
}
.RPopup {
  width: 100%;
  height: 101%;
  position: fixed;
  z-index: 1000;
  margin: 0;
}
.RPopup .screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
  z-index: 1001;
  top: 0px;
  left: 0;
}
.RPopup .body {
  width: 930px;
  z-index: 1002;
  height: 400px;
  position: fixed;
  margin: -200px 0 0 -465px;
  top: 50%;
  left: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
}
.RPopup .body .close {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background: url('/images/shop/close-popup.png') no-repeat center, #e5e5e5;
  opacity: 1;
  filter: grayscale(1);
  background-size: 12px auto;
}
.popup-login .screen {
  background: rgba(40, 40, 40, 0.8);
}
.popup-login .body {
  max-width: 600px;
  z-index: 1002;
  height: 400px;
  margin: -200px 0 0 -300px;
  width: 100%;
  padding: 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
}
.popup-login .body:before {
  content: 'Вход на сайт';
  color: #282828;
  font-size: 24px;
}
.popup-login .body .body-border {
  margin: 20px 0 0;
  width: auto;
  height: auto;
}
.popup-login .body .body-border .body-title-login {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px;
}
.popup-login .body .body-border .body-title-login .body-title {
  background: #FFCA00;
  height: auto;
  width: 50%;
  padding: 16px 10px;
  float: left;
  text-align: center;
}
.popup-login .body .body-border .body-title-login .body-title.selected {
  background: rgba(243, 244, 244, 0.4);
}
.popup-login .body .body-border .body-title-login .body-title a {
  font-family: inherit;
  color: #282828;
  font-style: normal;
  font-size: 18px;
  text-transform: none;
  line-height: 38px;
}
.popup-login .body .body-border .body-title-login .body-title.selected a {
  color: #282828;
}
.popup-login .body .body-border .body-content {
  height: auto;
}
.popup-login .body .body-border .body-content .body-another {
  display: none;
}
.popup-login .body .body-border .body-social-content .eauth .eauth-list {
  width: auto;
  margin: 0 -15px;
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service {
  width: 50%;
  margin: 0;
  padding: 10px 15px;
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-link {
  padding: 0;
  width: 100%;
  text-align: left;
  background: no-repeat 13px;
  color: #fafafa;
  line-height: 50px;
  padding-left: 50px;
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-link:before,
.popup-login .body .body-border .body-social-content .eauth .eauth-service-link:after {
  content: none;
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-twitter .eauth-service-link {
  background-color: #63CDF1;
  background-image: url('/images/agronomu/social/twitter-in.png');
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-google_oauth .eauth-service-link {
  background-color: #4D4F54;
  background-image: url('/images/agronomu/social/google-in.png');
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-yandex_oauth .eauth-service-link {
  background-color: #EC3626;
  background-image: url('/images/agronomu/social/yandex-in.png');
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-facebook .eauth-service-link {
  background-color: #507CBE;
  background-image: url('/images/agronomu/social/facebook-in.png');
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-vkontakte .eauth-service-link {
  background-color: #5082B9;
  background-image: url('/images/agronomu/social/vk-in.png');
}
.popup-login .body .body-border .body-social-content .eauth .eauth-service-id-mailru .eauth-service-link {
  background-color: #004684;
  background-image: url('/images/agronomu/social/mailru-in.png');
}
.popup-login .body .body-border .body-content-login .warn {
  font-size: 18px;
  line-height: 1.1;
  color: #282828;
  max-width: 480px;
  margin: 30px auto 0;
}
.popup-login .body .body-border .body-content-login .body-content-form {
  overflow: hidden;
  width: 100%;
  padding: 25px 0 0;
}
.popup-login .body .body-border .body-content-login .body-content-form .popup-login-wrap {
  float: left;
  width: 100%;
}
.popup-login .body .body-border .body-content-login .body-content-form form {
  margin: 0 -15px;
}
.popup-login .body .body-border .body-content-login .body-content-form form .form-group {
  padding: 0 15px;
  width: 50%;
  float: left;
}
.popup-login .body .body-border .body-content-login .body-content-form form .form-group input {
  width: 100%;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #808080;
  font-size: 14px;
  height: 41px;
}
.popup-login .body .body-border .body-content-login .body-content-form div .popup-login-button {
  background: #FFCA00;
  color: #FFFFFF;
  min-width: 250px;
  border-radius: 2px;
  border: none;
  font-size: 14px;
  font-family: inherit;
  line-height: 43px;
  padding: 0 10px;
  cursor: pointer;
  text-transform: none;
}
.popup-login .body .close {
  top: 20px;
  right: 25px;
  font-size: 24px;
  padding: 5px;
  line-height: 1;
  background: transparent;
  width: auto;
  height: auto;
}
.popup-login .body .close:before {
  content: 'x';
}
.popup-add-mail .body {
  width: 660px;
  z-index: 1004;
  height: 350px;
  border: 25px solid #308fed;
  padding: 30px;
  margin: -175px 0 0 -349px;
}
@media (max-width: 600px) {
  .popup-login .body {
    margin-left: -50%;
    padding: 20px 30px;
  }
  .popup-login .body .body-border {
    width: 100%;
  }
  .popup-login .body .body-border .body-title-login {
    width: auto;
    margin: 0 -30px 20px;
  }
  .popup-login .body .body-border .body-title-login .body-title a {
    font-weight: normal;
    font-size: 14px;
  }
  .popup-login .body .body-border .body-content .body-social-content .eauth .eauth-service-link {
    font-size: 14px;
    font-weight: 600;
    padding-left: 37px;
    background-position: 6px;
  }
  .popup-login .body .body-border .body-content-login .warn {
    font-size: 14px;
    margin-top: 20px;
  }
  .popup-login .body .body-border .body-content-login .body-content-form {
    padding-top: 10px;
  }
  .popup-login .body .body-border .body-content-login .body-content-form form .form-group {
    width: 100%;
    margin-bottom: 15px;
  }
  .popup-login .body .body-border .body-content-login .body-content-form .popup-login-wrap {
    margin: 0;
  }
  .popup-login .body .body-border .body-content-login .body-content-form div .popup-login-button {
    margin-top: 0;
  }
  .popup-login .body .close {
    top: 10px;
  }
}
@media (max-width: 319px) {
  .popup-login .body .body-border .body-content .body-social-content .eauth .eauth-service-link {
    padding-left: 29px;
    background-position: 3px;
  }
}
.bucket-popup .body {
  width: 96vw;
  z-index: 1002;
  height: 80vh;
  position: fixed;
  margin: -40vh 0 0 -48vw;
  top: 50%;
  left: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
}
.bucket-popup .body .close {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background: url('/images/shop/close-popup.png') no-repeat center, #e5e5e5;
  opacity: 1;
  filter: grayscale(1);
  background-size: 12px auto;
}
.bucket-popup .screen {
  background-color: rgba(0, 0, 0, 0.75);
}
.bucket-popup .body {
  max-height: 80vh;
  height: auto !important;
}
@media (min-width: 993px) {
  .bucket-popup .body {
    width: 980px;
    z-index: 1002;
    height: 80vh;
    position: fixed;
    margin: -40vh 0 0 -490px;
    top: 50%;
    left: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  }
  .bucket-popup .body .close {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background: url('/images/shop/close-popup.png') no-repeat center, #e5e5e5;
    opacity: 1;
    filter: grayscale(1);
    background-size: 12px auto;
  }
}
@media (min-width: 1441px) {
  .bucket-popup .body {
    width: 1323px;
    z-index: 1002;
    height: 80vh;
    position: fixed;
    margin: -40vh 0 0 -661.5px;
    top: 50%;
    left: 50%;
    background-color: #fff;
    -webkit-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.7);
  }
  .bucket-popup .body .close {
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background: url('/images/shop/close-popup.png') no-repeat center, #e5e5e5;
    opacity: 1;
    filter: grayscale(1);
    background-size: 12px auto;
  }
}
::selection {
  background: #c6c6c6;
  /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #c6c6c6;
  /* Gecko Browsers */
}
/*----------------------------Головна сторінка-----------------------------------*/
.content-index {
  padding: 30px 30px 0;
}
.popular-posts {
  margin: -30px -30px 0;
  padding: 25px 30px;
  background-color: rgba(255, 202, 0, 0.1);
}
.block-title-block {
  padding-bottom: 35px;
}
.block-title-block .title-block {
  margin: 0;
}
.block-title-block .block-after-title {
  margin-top: 20px;
}
.block-title-block .comment-block-title {
  float: right;
  padding-left: 23px;
  font-size: 14px;
  line-height: 22px;
  background: url('/images/agronomu/comment-dark.png') no-repeat 0 center;
}
.block-title-block .comment-block-title a {
  color: #282828;
}
.block-title-block .comment-block-title a:hover {
  color: #e91010;
}
.block-title-block .comment-block-title.no-background {
  background: none;
}
.block-title-block .comment-block-title:not(:last-child) {
  margin-left: 20px;
}
.title-block {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 24px;
  color: #282828;
  margin-top: 2px;
  margin-bottom: 30px;
  font-weight: 600;
}
.title-block:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: 2px;
  height: 20px;
  background: #E4512F;
}
.title-block.popular {
  font-size: 18px;
}
.title-block.popular:before {
  background: #FFCA00;
}
.title-block.comments {
  padding: 0;
  margin: 8px 0 30px;
}
.title-block.comments:before {
  content: none;
}
.title-block.block-right {
  padding-left: 15px;
}
.title-block.block-right:before {
  left: 0;
}
.title-block .count-posts {
  display: block;
  padding-top: 5px;
  font-size: 12px;
  color: #bec0c2;
}
h1 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 800;
}
h1.title-block {
  font-size: 36px;
  line-height: 1.2;
  height: auto;
}
h1.title-block:before {
  top: 11.6px;
}
.title-dacha {
  display: block;
  font-size: 24px;
  color: #282828;
  text-align: center;
  margin: 0 0 20px;
  font-weight: 600;
  line-height: 1.2;
}
.post:not(:last-child) {
  margin-bottom: 32px;
}
.horizontal-post:not(:last-child) {
  margin-bottom: 32px;
}
.horizontal-post a {
  position: relative;
  display: block;
}
.horizontal-post a img {
  max-width: 100%;
  border-radius: 2px;
  float: left;
}
.horizontal-post a .text-post {
  padding-left: 85px;
}
.horizontal-post a .text-post > div {
  display: table;
  width: 100%;
  height: 67px;
}
.horizontal-post a .text-post > div > span {
  display: table-cell;
  vertical-align: top;
  line-height: 20px;
  font-size: 16px;
  color: #282828;
}
.horizontal-post a .text-post > div > span.table-text {
  vertical-align: middle;
}
.horizontal-post a .text-post > div > span .category {
  font-size: 12px;
  color: #778ca3;
}
.horizontal-post a:hover .text-post > div > span,
.horizontal-post a:focus .text-post > div > span {
  color: #e91010;
}
.horizontal-post a:hover .text-post > div > span .category,
.horizontal-post a:focus .text-post > div > span .category {
  color: rgba(233, 16, 16, 0.6);
}
.horizontal-post.little-post:not(:last-child) {
  margin-bottom: 14px;
}
.horizontal-post.little-post a .text-post {
  padding-left: 65px;
}
.horizontal-post.little-post a .text-post > div {
  height: 50px;
}
.horizontal-post.little-post a .text-post > div > span {
  font-size: 12px;
  line-height: 1.2;
  color: #191919;
}
.horizontal-post.little-post a:hover .text-post > div > span,
.horizontal-post.little-post a:focus .text-post > div > span {
  color: #e91010;
}
.horizontal-post.post-bok {
  margin-bottom: 32px;
}
.horizontal-post.post-bok a img {
  width: 164px;
  height: 135px;
}
@media all and (max-width: 767px) {
  .horizontal-post.post-bok a img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
}
.horizontal-post.post-bok a .text-post {
  padding-left: 195px;
}
.horizontal-post.post-bok a .text-post > div {
  height: 135px;
}
@media all and (max-width: 767px) {
  .horizontal-post.post-bok a .text-post > div {
    height: auto;
  }
}
.horizontal-post.post-bok a .text-post > div > span {
  line-height: 1.2;
  max-width: 500px;
  margin: 0 auto 32px;
  font-size: 24px;
  font-weight: 600;
}
.horizontal-post.post-bok a .text-post > div > span .text {
  font-weight: 400;
  padding-top: 20px;
  line-height: 24px;
  font-size: 14px;
  color: #606060;
}
@media all and (max-width: 767px) {
  .horizontal-post.post-bok a .text-post > div > span .text {
    display: none;
  }
}
@media all and (max-width: 767px) {
  .horizontal-post.post-bok a .text-post {
    padding-left: 0px;
  }
}
.horizontal-post.post-bok a:hover .text-post > div,
.horizontal-post.post-bok a:focus .text-post > div {
  color: #e91010;
}
.vertical-post a {
  position: relative;
  display: block;
  color: #282828;
}
.vertical-post a .image-post {
  position: relative;
}
.vertical-post a .image-post > img {
  width: 100%;
  border-radius: 2px;
}
.vertical-post a .image-post .comment-count {
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 6px;
  background: #FFCA00;
  color: #FFF;
  border-radius: 2px;
}
.vertical-post a .image-post .comment-count img {
  margin-right: 6px;
}
.vertical-post a .text-post {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #282828;
}
.vertical-post a .text-post .category {
  font-size: 14px;
  font-weight: 400;
}
.vertical-post.popular {
  padding-bottom: 20px;
}
.vertical-post.popular a {
  background: #F7FAFA;
  border-radius: 2px;
  padding: 12px 12px 0;
}
.vertical-post.popular a .text-post {
  margin-top: 5px;
  line-height: 18px;
  font-weight: 400;
  display: table;
  height: 90px;
  width: 100%;
}
.vertical-post.popular a .text-post .text {
  display: table-cell;
  vertical-align: middle;
}
.vertical-post.dacha-post a .image-post {
  position: relative;
}
.vertical-post.dacha-post a .image-post .image-block {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5) url('/images/agronomu/dacha-post.png') no-repeat center;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.vertical-post.related-new-block a .image-post {
  position: relative;
}
.vertical-post.related-new-block a .image-post .image-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -ms-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}
.vertical-post.related-new-block a .text-post {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 0 5px;
  color: #FFF;
  line-height: 1.2;
  font-size: 18px;
  margin: 0;
  text-align: center;
}
.vertical-post.related-new-block a:hover .text-post > div,
.vertical-post.related-new-block a:focus .text-post > div {
  color: #f5f5f5;
}
.vertical-post.slider-post,
.vertical-post.big-slider-post {
  position: relative;
  margin-top: 45px;
}
.vertical-post.slider-post a .text-post,
.vertical-post.big-slider-post a .text-post {
  position: absolute;
  bottom: 30px;
  left: 7.5%;
  width: 85%;
  padding: 15px 20px 10px;
  color: #FFF;
  line-height: 28px;
  margin: 0;
  text-align: center;
}
.vertical-post.slider-post a .text-post .text,
.vertical-post.big-slider-post a .text-post .text {
  text-decoration: underline;
  font-size: 24px;
}
.vertical-post.slider-post a:hover .text-post > div,
.vertical-post.big-slider-post a:hover .text-post > div,
.vertical-post.slider-post a:focus .text-post > div,
.vertical-post.big-slider-post a:focus .text-post > div {
  color: #f5f5f5;
}
.vertical-post.slider-post.first a .text-post,
.vertical-post.big-slider-post.first a .text-post {
  background-color: rgba(255, 178, 148, 0.9);
}
.vertical-post.slider-post.second a .text-post,
.vertical-post.big-slider-post.second a .text-post {
  background-color: rgba(206, 222, 189, 0.9);
}
.vertical-post.slider-post.third a .text-post,
.vertical-post.big-slider-post.third a .text-post {
  background-color: rgba(189, 195, 222, 0.9);
}
.vertical-post.big-slider-post a .text-post {
  bottom: 30px;
  text-align: left;
  left: 3.75%;
  width: 92.5%;
}
.vertical-post.tag-post-breed a .text-post {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 25px;
}
.popular-bok {
  padding: 45px 0 5px;
}
.boks {
  padding-bottom: 3px;
  padding-top: 25px;
}
.dacha-block {
  margin: 0 -30px;
  padding: 20px 30px 0;
  background-color: rgba(208, 223, 191, 0.1);
}
.dacha-block .vertical-post {
  padding-bottom: 20px;
}
.commenting-block {
  padding: 20px 15px 6px;
  border: 2px solid #FADDD7;
  border-radius: 3px;
  margin: 0 auto 32px;
}
.commenting-block.normal {
  border-color: #F7811D;
}
.commenting-block .little-block-post {
  margin-bottom: 14px;
}
.news {
  margin: 0 -30px;
  padding: 5px 30px;
  border-top: 1px solid #F5F5F5;
  background-color: #FAFCFC;
}
.news .horizontal-post {
  padding: 25px 0;
}
.news .horizontal-post a .text-post {
  padding-left: 0px;
}
.news .horizontal-post a .text-post > div {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  position: relative;
  padding-bottom: 16px;
}
.news .horizontal-post a .text-post > div .time-post {
  color: #a4a9ae;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  margin-top: 5px;
  bottom: 0;
  left: 0;
}
.news .horizontal-post a .text-post > div .time-post:before {
  content: '•';
  margin-right: 15px;
}
.news .horizontal-post a img {
  width: 100%;
  float: none;
  margin-bottom: 15px;
}
/*-----------------------Сторінка новостей-------------------------*/
.count-themes {
  line-height: 20px;
  display: inline-block;
  margin-top: 2px;
  color: #bec0c2;
  font-weight: 600;
}
.category-items {
  padding: 15px 12px;
  background: #FCFBF8;
  border-radius: 2px;
  margin-bottom: 30px;
}
.category-items.alphabet-type {
  background: #FFFDF4;
  padding: 20px;
}
.category-items.chars-type {
  background: #FCF6DF;
  padding: 20px;
}
.category-items.absolute-type {
  position: absolute;
  bottom: 30px;
  z-index: -1;
  width: 933px;
}
.category-items.absolute-type.fixed {
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: auto;
}
.category-items .category-item-block {
  width: 33.334%;
  float: left;
}
.category-items .category-item-link {
  padding: 10px;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  color: #282828;
  border-radius: 2px;
}
.category-items .category-item-link.active,
.category-items .category-item-link:hover,
.category-items .category-item-link:focus {
  background: rgba(255, 202, 0, 0.1);
}
.category-items .title-type {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 25px;
  color: #282828;
}
.botton-banner {
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 10px 0 0 0;
}
.content-index-categori {
  margin: 0 0 20px 0;
}
.content-index-categori .content-index-left-text {
  float: left;
  width: 300px;
  min-height: 1px;
  padding: 0 15px  0 0;
}
.content-index-categori .content-index-left-text .simple-in-page-news {
  width: 300px;
  font-size: 16px;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 0 24px;
  display: inline-block;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.content-index-categori .content-index-left-text .simple-in-page-news .important {
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.content-index-categori .content-index-left-text .simple-in-page-news .time {
  color: #ccad14;
  font-size: 14px;
  padding: 0 10px 0 0;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.content-index-categori .content-index-left-text .simple-in-page-news div {
  width: 214px;
  display: inline-flex;
}
.content-index-categori .content-index-left-text .simple-in-page-news a {
  color: #222222;
  cursor: pointer;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.content-index-categori .content-index-left-text .simple-in-page-news a.test-red-title {
  color: #c6c6c6;
}
.content-index-categori .content-index-left-text .simple-in-page-news:before {
  content: " ";
  left: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  background: #edecec;
  border-radius: 10px;
}
.content-index-categori .content-index-left-text .show-more-categori {
  width: 100%;
  height: 50px;
  cursor: pointer;
  text-align: center;
  background: #308fed;
  margin: 0 0 20px 0;
  padding: 16px 0 15px 0;
}
.content-index-categori .content-index-left-text .show-more-categori a {
  color: #3d3d3d;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
}
.content-index-categori .content-index-left-text .show-more-categori img {
  margin: -4px 0 0 0;
}
.content-index-categori .content-index-left-foto-news {
  float: left;
  height: auto;
  width: 622px;
  margin: 10px 0 0 20px;
}
.content-index-categori .content-index-left-foto-news ul {
  list-style-type: none;
}
.content-index-categori .content-index-left-foto-news .categori-text {
  margin: 20px 0 20px 0;
}
.content-index-categori .content-index-left-foto-news .categori-text span {
  color: #222222;
  font-size: 18px;
  line-height: 24px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.content-index-categori .content-index-left-foto-news .categori-text .show-more-categori {
  width: 300px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  margin: 20px 0 0 0;
  background: #308fed;
  padding: 16px 0 15px 0;
}
.content-index-categori .content-index-left-foto-news .categori-text .show-more-categori a {
  color: #3d3d3d;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
}
.content-index-categori .content-index-left-foto-news .categori-text .show-more-categori img {
  margin: -4px 0 0 0;
}
/*---------------------  сторінка тегів  -------------------*/
.tag-children-list {
  position: relative;
  padding-bottom: 30px;
  margin-top: -15px;
}
.tag-children-list .category-items {
  margin: 0;
}
.tag-children-list .types-list-blocks {
  position: absolute;
  bottom: 100%;
  right: 0;
  list-style: none;
  margin: 0;
}
.tag-children-list .types-list-blocks li {
  float: left;
  display: block;
  width: 195px;
  height: 55px;
  position: relative;
}
.tag-children-list .types-list-blocks li:last-child {
  border-top-right-radius: 2px;
}
.tag-children-list .types-list-blocks li:before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  border-left: 15px solid transparent;
  border-bottom: 55px solid #000;
}
.tag-children-list .types-list-blocks li:after {
  position: absolute;
  top: 0;
  right: -15px;
  border-right: 15px solid transparent;
  border-bottom: 55px solid #000;
}
.tag-children-list .types-list-blocks li.category-block-list {
  background: #FCFBF8;
}
.tag-children-list .types-list-blocks li.category-block-list:before,
.tag-children-list .types-list-blocks li.category-block-list:after {
  border-bottom-color: #FCFBF8;
}
.tag-children-list .types-list-blocks li.alphabet-block-list {
  background: #FFFDF4;
}
.tag-children-list .types-list-blocks li.alphabet-block-list:before,
.tag-children-list .types-list-blocks li.alphabet-block-list:after {
  border-bottom-color: #FFFDF4;
}
.tag-children-list .types-list-blocks li.chars-block-list {
  background: #FCF6DF;
}
.tag-children-list .types-list-blocks li.chars-block-list:before,
.tag-children-list .types-list-blocks li.chars-block-list:after {
  border-bottom-color: #FCF6DF;
}
.tag-children-list .types-list-blocks li .list-block-type {
  display: table;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #282828;
  padding-right: 20px;
}
.tag-children-list .types-list-blocks li .list-block-type .table-cell {
  display: table-cell;
  vertical-align: middle;
}
.tag-children-list .types-list-blocks li .list-block-type .image {
  text-align: center;
  width: 70px;
  padding-right: 5px;
}
.char-title {
  padding: 30px 15px;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  color: #282828;
}
.alphabet-block-char {
  margin-bottom: 5px;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  background: #ffca00;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.alphabet-block-char:hover,
.alphabet-block-char:focus {
  color: #ffffff;
}
.alphabet-block-char.no-active {
  background: #A5A5A5;
  cursor: not-allowed;
}
.form-group {
  margin-bottom: 30px;
  position: relative;
  color: #646464;
}
.form-group input,
.form-group .text-input-block {
  line-height: 40px;
  border-radius: 2px;
  background: #FFF;
  border: 1px solid #ECE4C6;
  font-size: 14px;
  width: 100%;
  padding-left: 15px;
}
.form-group .text-input-block {
  cursor: pointer;
}
.form-group .text-input-block:before {
  content: '';
  position: absolute;
  top: 0;
  right: 20px;
  width: 10px;
  height: 100%;
  background: url('/images/agronomu/select-arrow.png') no-repeat center;
}
.form-group .select-block {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #FFF;
  border: 1px solid #ECE4C6;
  border-radius: 2px;
  margin-top: -1px;
}
.form-group .checkbox label {
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 18px;
  padding: 6px 15px 6px 40px;
  position: relative;
}
.form-group .checkbox label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  background: #FFF;
  border: 1px solid #E2E6ED;
  border-radius: 2px;
}
.form-group .checkbox:first-child label {
  padding-top: 15px;
}
.form-group .checkbox:last-child label {
  padding-bottom: 15px;
}
.form-group .checkbox input[type="checkbox"] {
  display: none;
}
.form-group .checkbox input[type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: url('/images/agronomu/active-checkbox.png');
}
.filters-block {
  margin-bottom: 20px;
  border: 1px solid #E2E6ED;
  border-radius: 2px;
  padding: 10px 190px 10px 10px;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  height: 50px;
}
.filters-block .remove-filter {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 170px;
  padding: 6px 10px;
  background: #FFCA00;
  border-radius: 2px;
  color: #FFF;
  cursor: pointer;
  text-align: center;
}
.filters-block .filter-block {
  padding: 6px 10px;
  float: left;
  background: #FCFBF8;
  border-radius: 2px;
  color: #646464;
}
.filters-block .filter-block:not(:last-child) {
  margin-right: 10px;
}
.filters-block .filter-block .close-filter {
  cursor: pointer;
  padding: 14px 10px;
  margin: -10px -10px -10px 0;
}
/*------   під адмінку  ------*/
ul.inline-list {
  display: inline;
}
ul.inline-list li {
  float: left;
  margin: 6px;
  display: inline;
  cursor: pointer;
}
/*------   сеню в категориях 4 иконки рразом  ------*/
ul {
  padding: 0;
}
ul.inline-list-news {
  display: inline;
}
ul.inline-list-news li {
  margin: 0;
  float: left;
  display: inline;
  cursor: pointer;
}
ul.inline-list-news li:before {
  margin: 79px 0 0 0;
  content: '';
  width: 300px;
  height: 106px;
  position: absolute;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0);
  /* IE6-9 */
}
ul.inline-list-news li .title-name {
  height: 30px;
  background: #308fed;
  position: absolute;
  margin: -185px 0 0 0;
  padding: 6px 0 0 0;
  text-transform: uppercase;
}
ul.inline-list-news li .title-name span a {
  color: #333333;
  font-size: 13px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  margin: 0 0 0 5px;
}
ul.inline-list-news li .title-name span a:before {
  content: '';
  top: 0;
  width: 0;
  height: 0;
  right: -30px;
  position: absolute;
  border-top: 30px solid #308fed;
  border-right: 30px solid transparent;
}
ul.inline-list-news li .title-banner {
  width: 300px;
  margin: -140px 0 0 0;
  position: absolute;
}
ul.inline-list-news li .title-banner h2 {
  padding: 0 0 0 15px;
  display: table-cell;
  vertical-align: bottom;
  height: 105px;
  text-shadow: none;
}
ul.inline-list-news li .title-banner h2 a {
  margin: 0;
  color: #fefefe;
  font-size: 16px;
  padding: 0 0 0 0;
  text-shadow: none;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
ul.inline-list-news li .title-banner .block-banner {
  margin: 10px 0 20px 0;
}
ul.inline-list-news li .title-banner .block-banner a {
  color: #cccaca;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
ul.inline-list-news li .title-banner .block-banner a img {
  margin: -2px 10px 0 15px;
}
ul.inline-list-news li .title-banner .block-banner a p {
  color: #000000;
  font-size: 14px;
  display: inline;
  border-radius: 5px;
  background: #ffdf3f;
  padding: 0px 6px 0 6px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
/*=-------меню категории------------*/
.memu-categori {
  width: 100%;
  height: 50px;
}
.memu-categori .menu-ca {
  height: 50px;
  width: 1323px;
  margin: 0 auto;
  overflow: hidden;
  background: #308fed;
  -webkit-box-shadow: 0px 3px 11px 0px rgba(50, 50, 49, 0.75);
  -moz-box-shadow: 0px 3px 11px 0px rgba(50, 50, 49, 0.75);
  box-shadow: 0px 3px 11px 0px rgba(50, 50, 49, 0.75);
}
.memu-categori .menu-ca ul {
  padding: 0;
  margin: 15px 0 0 0;
  list-style-type: none;
}
.memu-categori .menu-ca ul li:first-child:before {
  width: 0;
  content: '';
  height: 17px;
  position: absolute;
  background: #FFFFFF;
}
.memu-categori .menu-ca ul li:first-child {
  padding: 6px 15px 24px 0;
}
.memu-categori .menu-ca ul li {
  display: inline;
  text-decoration: none;
  padding: 6px 15px 24px 0;
}
.memu-categori .menu-ca ul li:before {
  width: 1px;
  content: '';
  height: 22px;
  position: absolute;
  background: #6c6c6c;
}
.memu-categori .menu-ca ul li a {
  color: #4a4a4a;
  font-size: 16px;
  cursor: pointer;
  padding: 0 0 0 15px;
  text-decoration: none;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.memu-categori .menu-ca ul li a:hover {
  color: #575757;
}
/*=------- Последние новости------------*/
.LatestArticles {
  float: left;
  width: 100%;
  height: auto;
}
.LatestArticles .LatestArticles-title {
  width: 100%;
  height: 50px;
  margin: 20px 0 0 0;
  background: #4a4a4a;
}
.LatestArticles .LatestArticles-title h2 {
  margin: 0;
  color: #308fed;
  font-size: 24px;
  text-shadow: none;
  padding: 10px 0 0 20px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.LatestArticles .LatestArticles-content {
  width: 100%;
  height: 260px;
}
.LatestArticles .LatestArticles-content ul {
  list-style-type: none;
}
.LatestArticles .LatestArticles-content ul li:last-child {
  margin: 0;
}
.LatestArticles .LatestArticles-content ul li {
  margin: 0 15px 0 0;
  float: left;
}
/*------------Cупер пупер--------------*/
.smol-foto-slider .post-avatar {
  position: relative;
}
.index-news .post-hover {
  display: none;
  position: relative;
}
.index-news .post {
  width: 300px;
}
.big-foto-slider .post-avatar {
  float: left;
  position: relative;
}
.index-news .post:hover .post-hover {
  display: block;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  width: 320px;
  height: 330px;
  z-index: 200;
  padding: 10px 10px 10px 10px;
  background-color: lightslategray;
}
.index-news .post:hover .post-avatar {
  z-index: 300;
}
.index-news .post:hover .post-avatar .title-avatar {
  z-index: 400;
}
.post-avatar {
  position: relative;
}
.post-avatar.post-avatar-300 {
  width: 300px;
  height: 185px;
}
.post-avatar.post-avatar-300:before {
  margin: 77.7px 0 0 0;
  width: 300px;
  height: 107.3px;
}
.post-avatar.post-avatar-300 .main-foto {
  width: 300px;
  height: 185px;
}
.post-avatar.post-avatar-300 .title-text h2 {
  height: 145px;
}
.post-avatar.post-avatar-300 .watched-badge-main {
  width: 300px;
  height: 185px;
}
.post-avatar.post-avatar-630 {
  width: 630px;
  height: 370px;
  margin: 0 0 15px;
}
.post-avatar.post-avatar-630:before {
  margin: 155.4px 0 0 0;
  width: 630px;
  height: 214.6px;
}
.post-avatar.post-avatar-630 .main-foto {
  width: 630px;
  height: 370px;
}
.post-avatar.post-avatar-630 .title-text h2 {
  height: 330px;
}
.post-avatar.post-avatar-630 .watched-badge-main {
  width: 630px;
  height: 370px;
}
.post-avatar .main-foto {
  border-image: none;
}
.post-avatar:before {
  content: '';
  position: absolute;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0);
  /* IE6-9 */
}
.post-avatar .title-avatar {
  height: 30px;
  background: #308fed;
  position: absolute;
  top: 0;
  padding: 6px 0 0 0;
  z-index: 100;
  text-transform: uppercase;
}
.post-avatar .title-avatar span a {
  color: #bcd6f8;
  cursor: pointer;
  font-size: 13px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  margin: 0 0 0 10px;
}
.post-avatar .title-avatar span a:before {
  content: '';
  top: 0;
  width: 0;
  height: 0;
  right: -30px;
  position: absolute;
  border-top: 30px solid #308fed;
  border-right: 30px solid transparent;
}
.post-avatar .title-text {
  width: 100%;
  position: absolute;
  top: 0;
}
.post-avatar .title-text h2 {
  padding: 0 15px 0 15px;
  display: table-cell;
  vertical-align: bottom;
  line-height: 11pt;
  text-shadow: none;
}
.post-avatar .title-text h2 a {
  margin: 0;
  color: #fefefe;
  font-size: 16px;
  padding: 0 0 0 0;
  text-shadow: none;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.post-avatar .title-text .block-banner {
  margin: 10px 0 20px 0;
}
.post-avatar .title-text .block-banner a {
  color: #cccaca;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.post-avatar .title-text .block-banner a img {
  margin: -2px 10px 0 15px;
}
.post-avatar .title-text .block-banner a p {
  color: #000000;
  font-size: 14px;
  display: inline;
  border-radius: 5px;
  background: #ffdf3f;
  padding: 0px 6px 0 6px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.post-avatar .pere {
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}
.pere:focus,
.pere:hover {
  text-decoration: none;
}
.post-hover .ddd {
  color: #ffffff;
  font-size: 14px;
  padding: 186px 0 0 0;
  text-shadow: none;
  line-height: 1.2;
  text-align: justify;
}
.post-hover .ddd:hover {
  text-decoration: none;
}
.post-hover .read-new {
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 40px;
  float: none;
  text-align: center;
  background-color: #308fed;
  right: 10px;
  padding-top: 10px;
}
.post-hover .read-new p {
  color: #333333;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
/*-------Социальна прокутка ------*/
aside {
  display: inline-block;
  width: 300px;
  height: 240px;
}
.prilip {
  position: fixed;
  z-index: 101;
}
.stop {
  position: relative;
}
/*-------Блок просмотрено */
.watched-badge-main {
  top: 0;
  width: 300px;
  height: 185px;
  position: absolute;
  opacity: 0.5;
  background: #989898;
}
.watched-badge {
  background-color: #c6c6c6;
  top: 20px;
  color: #FFF;
  float: right;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.3em;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  right: 15px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
/*----сторинка azs------*/
.azs {
  width: 100%;
  height: auto;
  position: relative;
}
.azs .min-fin {
  color: #222222;
  font-size: 14px;
  margin-top: 20px;
  text-align: right;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.azs .azs-title {
  width: 100%;
  clear: both;
  text-align: center;
  margin-bottom: 20px;
}
.azs .azs-title #ui-datepicker-div {
  background: #000;
}
.azs .azs-title input {
  width: 160px;
  border: none;
  height: 35px;
  outline: none;
  color: #4a4a4a;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
  background: #f9cd1b;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.azs .azs-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.azs .azs-content .azs-content-block-gas {
  float: left;
  width: 280px;
  height: 100%;
  margin: 0 40px 0 0;
}
.azs .azs-content .azs-content-block-gas .title-in-widget-news-related {
  height: 30px;
  color: #222222;
  font-size: 24px;
  line-height: 27px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  background: url("/images/site/related-background.png");
  background-repeat: no-repeat;
}
.azs .azs-content .azs-content-block-gas .azs-block-data {
  height: 40px;
  width: 280px;
  position: relative;
  margin: 0 0 10px 0;
  background: #f3f3f1;
  border: 3px solid #5c5c5c;
}
.azs .azs-content .azs-content-block-gas .azs-block-data .title-area {
  float: left;
  width: 73px;
  cursor: pointer;
  margin: 8px 0 0 0;
  padding: 0 0 0 10px;
  border-right: 2px solid #e4e4e2;
}
.azs .azs-content .azs-content-block-gas .azs-block-data .title-area span {
  color: #3333;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.azs .azs-content .azs-content-block-gas .azs-block-data input {
  border: none;
  background: none;
  width: 200px;
  height: 32px;
  outline: none;
  font-size: 17px;
  font-weight: bold;
  color: #4a4a4a;
  cursor: pointer;
  padding: 5px 0 0 15px;
  background: url("/images/site/calendar.png");
  background-position: 94% 50%;
  background-repeat: no-repeat;
}
.azs .azs-content .azs-content-block-gas .content-area {
  height: 40px;
  width: 280px;
  position: relative;
  background: #f3f3f1;
  border: 3px solid #5c5c5c;
}
.azs .azs-content .azs-content-block-gas .content-area .title-area {
  float: left;
  width: 73px;
  cursor: pointer;
  margin: 8px 0 0 0;
  padding: 0 0 0 10px;
  border-right: 2px solid #e4e4e2;
}
.azs .azs-content .azs-content-block-gas .content-area .title-area span {
  color: #333333;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.azs .azs-content .azs-content-block-gas .content-area .area {
  font-size: 20px;
  background: url("/images/point-in-area.png");
  background-position: 90% 50%;
  background-repeat: no-repeat;
}
.azs .azs-content .azs-content-block-gas .content-area .area-dropdown ul {
  top: 40px;
  right: -3px;
  z-index: 100;
  width: 280px;
  background: #ccc;
  text-align: center;
  position: absolute;
  list-style-type: none;
  border: 2px solid #4a4a4a;
}
.azs .azs-content .azs-content-block-gas .content-area .area-dropdown ul li {
  float: left;
  width: 300px;
  height: 35px;
  cursor: pointer;
  padding: 8px 0 8px 0;
}
.azs .azs-content .azs-content-block-gas .content-area .area-dropdown ul li a {
  color: #4a4a4a;
  font-size: 18px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.azs .azs-content .azs-content-block-gas .content-block-gas {
  width: 280px;
  height: auto;
  margin: 0 20px 0 0;
}
.azs .azs-content .azs-content-block-price {
  float: left;
  width: 500px;
  height: auto;
}
.azs .dropdown {
  float: left;
  width: 600px;
  position: relative;
}
.azs .dropdown .title-dropdown {
  height: 27px;
  background: #f3f3f1;
}
.azs .dropdown .title-dropdown span {
  color: #3e3c35;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.azs .dropdown .title-dropdown .title-right {
  height: 27px;
  float: right;
  padding: 4px 10px 0 0;
}
.azs .dropdown .title-dropdown .title-left {
  float: left;
  height: 27px;
  padding: 4px 0 0 10px;
}
.azs .dropdown .dropdown-refill:hover {
  cursor: default;
  background: #fefaea;
}
.azs .dropdown .dropdown-refill {
  height: auto;
  cursor: default;
  overflow: hidden;
  margin: 2px 0 0 0;
  position: relative;
  border-bottom: 2px solid #f3f3f1;
}
.azs .dropdown .dropdown-refill .name-gas {
  border: none;
  width: 350px;
  height: auto;
  float: left;
  font-size: 20px;
  margin: 8px 0 8px 20px;
}
.azs .dropdown .dropdown-refill .name-gas a {
  color: #e20000;
  font-size: 15px;
}
.azs .dropdown .dropdown-refill .prise {
  top: 30%;
  right: 0;
  text-align: center;
  position: absolute;
}
.azs .dropdown .dropdown-refill .prise .prise-gas {
  float: left;
  width: 100px;
  height: auto;
  border: none;
  font-size: 24px;
  position: relative;
  vertical-align: middle;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
/*------меню цен -------*/
ul#menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul#menu li {
  width: 280px;
  height: 45px;
  background: #f3f3f1;
}
ul#menu li a {
  border: none;
  height: 45px;
  color: #4a4a4a;
  display: block;
  text-decoration: none;
}
.vidactive {
  color: #fff !important;
  background: #308fed;
}
.gas:hover {
  color: #fff;
  background: #308fed;
}
.gas {
  height: 45px;
  width: 280px;
  cursor: pointer;
  margin: 5px 0 0 0;
  position: relative;
}
.gas .name {
  left: 0;
  width: 100px;
  font-size: 20px;
  position: absolute;
  margin: 14px 0 14px 20px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.gas .one-gas {
  right: 0;
  font-size: 20px;
  display: inline;
  position: absolute;
  margin: 14px 15px 14px 0;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.social-azs {
  width: 100px;
  margin: 30px 0 0 0;
  position: absolute;
}
/*------------Виджет бензина--------------*/
.widget-gas {
  width: 300px;
  height: auto;
  padding: 0 0 5px 0;
  background: #f5f4f0;
}
.widget-gas .head {
  margin: 0;
  color: #fff;
  height: 50px;
  font-size: 18px;
  background: #4a4a4a;
  padding: 17px 0 0 15px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.widget-gas .head span {
  font-size: 15px;
  color: #4a4a4a;
}
.widget-gas .widget-block-comment {
  margin: 10px;
  background: #fafaf8;
  padding: 10px 10px 5px 10px;
}
.widget-gas .widget-block-comment .widget-block-comment-title {
  padding: 0 0 1px 0;
}
.widget-gas .widget-block-comment .widget-block-comment-title a {
  color: #4a4a4a;
  font-size: 15px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.widget-gas .widget-block-comment .widget-block-comment-name {
  color: #808080;
  font-size: 14px;
  padding: 0 0 5px 0;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.widget-gas .widget-block-comment p {
  color: #808080;
  font-size: 14px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.widget-gas .widget-block-comment p a {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: bold;
}
.widget-gas .widget-block-comment p span {
  color: #222222;
  font-size: 14px;
}
.widget-gas select {
  margin: 10px 0 0 70px;
}
.widget-gas .redirect-azs {
  width: 300px;
  text-align: center;
}
.widget-gas .redirect-azs a {
  cursor: pointer;
}
.widget-gas .widget-price-gas {
  width: 300px;
  height: 90px;
  position: relative;
  padding: 10px 0 0 10px;
}
.widget-gas .widget-price-gas .block-gas {
  width: 140px;
  height: 25px;
  position: relative;
  display: inline-block;
}
.widget-gas .widget-price-gas .block-gas .block-gas-name {
  float: left;
  width: 70px;
}
.widget-gas .widget-price-gas .block-gas .block-gas-name p {
  color: #808080;
  font-size: 14px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.widget-gas .widget-price-gas .block-gas .block-gas-price {
  float: left;
  width: 50px;
}
.widget-gas .widget-price-gas .block-gas .block-gas-price p {
  color: #333333;
  font-size: 16px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
/*-------Политика Конфиденциальности Сonfidentiality */
.confidentiality-main .confidentiality-box h1 {
  text-align: center;
}
.confidentiality-main .confidentiality-box p {
  font-size: 17px;
  line-height: 24px;
}
/*--------- Tags ---------*/
.tags-index {
  height: auto;
  width: 630px;
  margin: 0 auto;
  max-width: 100%;
}
.tags-index h2 {
  color: #4a4a4a;
  font-size: 24px;
  text-align: center;
  margin: 10px 0 20px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}
.tags-index h2 span {
  color: #f9cd1b;
}
/*---------------------------*/
.index-post-related-news {
  width: 220px;
  height: auto;
}
.index-post-related-news .title-in-widget-news-related {
  height: 30px;
  color: #222222;
  font-size: 18px;
  line-height: 27px;
  padding-left: 5px;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
  background: url("/images/site/related-background.png");
  background-repeat: no-repeat;
}
.index-post-related-news ul {
  list-style-type: none;
}
.index-post-related-news ul li:last-child {
  border-bottom: none;
}
.index-post-related-news ul li {
  padding: 9px 0;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
}
.index-post-related-news ul li p {
  margin: 0;
  color: #969696;
}
.index-post-related-news ul li p .time-related {
  color: #969696;
}
.index-post-related-news ul li p a {
  color: #222222;
  font-size: 15px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.index-post-related-news ul li a {
  color: #222222;
  font-size: 14px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
  overflow: hidden;
  display: block;
  margin-bottom: -1px;
}
.index-post-related-news ul li a:hover > div,
.index-post-related-news ul li a:focus > div {
  text-decoration: underline;
}
.index-post-related-news ul li .related-image {
  float: left;
  width: 50px;
  height: 50px;
  background: #000;
}
.index-post-related-news ul li .related-image img {
  width: 50px;
  height: 50px;
}
.index-post-related-news ul li .related-text {
  float: left;
  width: 170px;
  height: auto;
  padding: 0 0 0 10px;
}
/*переключатель сторінок */
.list-view .pagination {
  width: 100%;
  clear: left;
}
.list-view .pagination li {
  display: inline-block;
  margin: 0 7px 0 0;
  height: 25px;
  min-width: 25px;
  border-radius: 2px;
  background: #F0F0F0;
}
.list-view .pagination li:hover {
  background-color: #FFCA00;
}
.list-view .pagination li:hover a {
  color: #FFF;
}
.list-view .pagination li.disabled {
  display: none;
}
.list-view .pagination li a {
  border: none;
  color: #152414;
  font-size: 12px;
  padding: 3px 7px 4px;
  background-color: transparent;
  display: inline-block;
  min-width: 25px;
  text-align: center;
}
.list-view .pagination li span {
  border: none !important;
  padding: 12px;
  background-color: transparent !important;
}
.list-view .pagination .active {
  background: #FFCA00;
}
.list-view .pagination .active a {
  color: #FFF;
  background: transparent;
}
.yandex-absolute {
  position: relative;
}
.yandex-absolute #yandex_ad_more,
.yandex-absolute #yandex_ad_baner,
.yandex-absolute > .adsbygoogle,
.yandex-absolute > div {
  position: absolute;
  top: 0;
  overflow: hidden;
}
.yandex-absolute #yandex_ad_more,
.yandex-absolute > .adsbygoogle,
.yandex-absolute > div {
  width: 220px;
}
.yandex-absolute #yandex_ad_baner {
  width: 300px;
}
@media (max-width: 992px) {
  .yandex-absolute #yandex_ad_baner {
    display: none;
  }
}
.absolute-bottom-adv {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 2;
}
.absolute-bottom-adv .bottom-advertising {
  width: 1323px;
  padding: 0 30px 0 100px;
  margin: 0 auto;
}
.absolute-bottom-adv .bottom-advertising .bottom-adv {
  position: relative;
  max-width: 620px;
  width: 100%;
  background: #fff;
}
.absolute-bottom-adv .bottom-advertising .bottom-adv .adv-block {
  height: 90px;
}
.absolute-bottom-adv .bottom-advertising .bottom-adv .close-adv {
  position: absolute;
  bottom: 100%;
  right: 0;
  font-size: 40px;
  text-decoration: none;
  font-weight: 700;
  line-height: 25px;
  color: #e6f0ff;
  cursor: pointer;
  background: #646464;
  padding: 0 10px 0 10px;
  height: 35px;
  font-family: ProximaNova, serif;
  font-style: normal;
}
.absolute-bottom-adv .bottom-advertising .bottom-adv .close-adv:hover {
  color: red;
}
@media (max-width: 1440px) {
  .absolute-bottom-adv .bottom-advertising {
    width: 980px;
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  .absolute-bottom-adv .bottom-advertising {
    width: 100%;
  }
  .absolute-bottom-adv .bottom-advertising .bottom-adv {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .absolute-bottom-adv .bottom-advertising {
    padding: 0 10px;
  }
}
.absolute-bottom-adv .goods-blocks-news {
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-style: normal;
}
.absolute-bottom-adv .goods-blocks-news .all-goods {
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
}
.index-post .block-poll-block div {
  margin: 0;
}
.block-poll {
  display: table;
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: none;
  background: rgba(255, 202, 0, 0.1);
  font-size: 24px;
  line-height: 30px;
  color: #282828;
  margin: 30px 0;
}
.poll-text,
.text-poll-block,
.poll-button,
.count-positive,
.count-help-post {
  display: table-cell;
  vertical-align: middle;
}
.text-poll-block > div {
  display: table;
  width: 100%;
}
.count-help-post {
  width: 20px;
}
.poll-title {
  padding: 0 30px;
}
.poll-title div {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  padding-top: 5px;
  color: #6e6e6e;
  vertical-align: middle;
}
.poll-title .social-likes {
  float: left;
}
.poll-title .social-likes .facebook,
.poll-title .social-likes .vkontakte,
.poll-title .social-likes .odnoklassniki,
.poll-title .social-likes .telegram {
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border-radius: 2px;
}
.poll-title .social-likes .facebook {
  background-image: url("/images/fermer/oauth/facebook.png");
}
.poll-title .social-likes .vkontakte {
  background-image: url("/images/fermer/oauth/vk.png");
}
.poll-title .social-likes .odnoklassniki {
  background-image: url("/images/fermer/oauth/ok.png");
}
.poll-title .social-likes .telegram {
  background-image: url("/images/fermer/fix/telegram.png");
}
.poll-title .no-mobile {
  padding-left: 185px;
  display: block;
}
.poll-title .no-mobile .table {
  height: 32px;
}
.count-positive {
  padding: 0 30px;
  background: rgba(255, 202, 0, 0.1);
  font-size: 36px;
  text-align: center;
  width: 10px;
}
.count-positive div {
  display: table;
  margin: 0 auto;
}
.count-positive span {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
.count-positive span:first-child {
  padding-right: 20px;
}
.count-positive span:not(:first-child) {
  width: 55px;
  font-size: 12px;
  line-height: 12px;
  display: block;
  padding: 2px 0;
}
@media all and (max-width: 500px) {
  .count-positive {
    padding: 0px;
  }
}
.poll-button {
  width: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  text-decoration: underline;
  padding: 0 17px;
  margin-right: 5px;
}
.poll-button:hover,
.poll-button:focus {
  opacity: 0.8;
}
.poll-button.no {
  color: #d08282;
  text-decoration: underline #eed2c3;
}
.poll-button.yes {
  color: #98b65c;
  text-decoration: underline #dce2b9;
}
@media all and (max-width: 767px) {
  .poll-button {
    width: initial;
  }
}
.poll-text[data-id="yes"] {
  font-size: 14px;
  line-height: 12px;
}
.poll-text[data-id="yes"] .no-mobile-block .table {
  height: 30px;
  width: auto;
}
.poll-text[data-id="yes"] .mobile-block {
  margin-bottom: 5px;
}
.table {
  width: 100%;
  height: 100%;
  display: table;
}
.table .table-row {
  display: table-row;
}
.table .table-cell {
  display: table-cell;
  vertical-align: middle;
}
.social-likes {
  float: left;
}
.social-likes_visible {
  display: inline-block;
}
.social-likes__widget {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
}
.social-likes__widget_facebook {
  background: #3b5998;
}
.social-likes__widget_mailru {
  background: #168de2;
}
.social-likes__widget_odnoklassniki {
  background: #eb722e;
}
.social-likes__widget_vkontakte {
  background: #48729e;
}
.social-likes__widget_plusone {
  background: #eb1c00;
}
.social-likes__button {
  color: transparent;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  position: relative;
  background: no-repeat center;
}
.social-likes__counter {
  font-size: 14px;
  line-height: 30px;
  padding: 0 8px;
  color: #FFF;
  display: inline-block;
  position: relative;
}
.social-likes__counter:before {
  content: '';
  top: 10%;
  left: 0;
  position: absolute;
  height: 80%;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.social-likes__counter_empty {
  display: none;
}
.social-likes__button_facebook {
  background-image: url('/images/agronomu/social/facebook.svg');
}
.social-likes__button_mailru {
  background-image: url('/images/agronomu/social/mailru.svg');
}
.social-likes__button_odnoklassniki {
  background-image: url('/images/agronomu/social/odnoklasniki.svg');
}
.social-likes__button_vkontakte {
  background-image: url('/images/agronomu/social/vk.svg');
}
.social-likes__button_plusone {
  background-image: url('/images/agronomu/social/google.svg');
}
.complain-content {
  width: 500px;
  margin: 30px;
  background: #fff;
}
.complain-content .complain-main {
  overflow: hidden;
}
.complain-content .complain-main .complain-main-title {
  margin-top: 0;
  font-size: 22px;
  padding-top: 15px;
  padding-left: 10px;
  font-family: ProximaNova, serif;
  font-weight: bold;
}
.complain-content .complain-main .complain-main-content-complain {
  padding-left: 10px;
  padding-right: 10px;
}
.complain-content .complain-main .complain-main-content-complain p {
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-size: 16px;
}
.complain-content .complain-main .complain-main-content-complain .comment {
  margin-bottom: 10px;
}
.complain-content .complain-main .complain-main-content-complain textarea {
  height: 80px;
  max-width: 480px;
  min-width: 480px;
  border: 2px solid #4a4a4a;
}
.complain-content .complain-main .send-wrapper {
  float: right;
  position: relative;
  width: auto;
  padding: 5px 10px 5px;
}
.complain-content .complain-main .send-wrapper .send-button {
  position: relative;
  float: left;
  cursor: pointer;
  font-size: 18px;
  color: #4a4a4a;
  left: -18px;
  height: 22px;
}
.complain-content .complain-main .send-wrapper .send-button:hover {
  text-decoration: underline;
}
.complain-content .complain-main .send-wrapper .close-popup {
  position: relative;
  float: left;
  height: 22px;
  color: #4a4a4a;
  font-size: 18px;
  cursor: pointer;
}
.complain-content .complain-main .send-wrapper .close-popup:hover {
  text-decoration: underline;
}
.comments {
  margin: 20px 0 0 0;
}
.comments .c-title {
  color: #4a4a4a;
  font-size: 18pt;
  margin: 0 0 20px 0;
  font-family: ProximaNova, serif;
  font-weight: bold;
}
.comments .c-title img {
  margin: 0 3px 0 10px;
}
.comments .c-title-form {
  margin: 20px 0 30px;
  font-size: 18px;
  line-height: 1.2;
  color: #191919;
}
.comments .c-form textarea {
  padding: 14px;
  font-size: 14px;
  line-height: 1.2;
  resize: none;
  height: 120px;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  width: 100%;
}
.comments .c-form button {
  display: block;
  cursor: pointer;
  margin: 10px 0 15px 0;
  font-size: 18px;
  line-height: 25px;
  padding: 20px 0;
  background: #FFCA00;
  color: #FFF;
  font-family: ProximaNova, serif;
  border: none;
  max-width: 250px;
  width: 100%;
}
.comments .c-form .c-focus-only {
  display: none;
}
.comments .c-form.c-focused .c-focus-only {
  display: block;
}
.comments textarea#editField {
  min-width: 536px;
  max-width: 620px;
}
.comments .c-comment {
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-size: 14px;
  padding: 25px 30px;
  background: #f5faef;
  margin-top: 30px;
  padding-left: 130px;
}
.comments .c-comment .c-text {
  line-height: 14pt;
}
@media all and (max-width: 767px) {
  .comments .c-comment .c-text {
    margin-left: -100px;
  }
}
.comments .c-comment .c-reply {
  color: #e91010;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 20px;
}
.comments .c-comment .c-reply:hover {
  opacity: 0.9;
}
@media all and (max-width: 767px) {
  .comments .c-comment .c-reply {
    margin-left: 0px;
    display: block;
  }
}
.comments .c-comment .c-user {
  margin-bottom: 14px;
  position: relative;
}
.comments .c-comment .c-user .c-username {
  color: #191919;
  font-size: 18px;
  line-height: 1;
}
.comments .c-comment .c-user .c-username img {
  background: #F7811D;
  border-radius: 3px;
  position: absolute;
  left: -100px;
  top: 0;
  width: 66px;
  height: 66px;
}
.comments .c-comment .c-user .c-timestamp {
  padding-left: 20px;
}
@media all and (max-width: 767px) {
  .comments .c-comment .c-user .c-timestamp {
    padding-left: 0px;
    display: block;
  }
}
.comments .c-comment .c-user .c-timestamp a {
  color: #9d9d9d;
}
.comments .c-comment .c-user .c-reply-to {
  background: url("../reply-to.png") no-repeat 0 2px;
  font-family: ProximaNova, serif;
  font-weight: normal;
  padding-left: 20px;
  margin-left: 13px;
}
.comments .c-comment .c-user .c-reply-to img {
  width: 16px;
  height: 16px;
  margin-top: -2px;
  border-radius: 2px;
}
.comments .c-comment .c-user .c-reply-to a {
  color: #8c8c8c;
}
.comments .c-comment .c-footer {
  overflow: hidden;
  min-height: 25px;
  margin-top: 5px;
}
.comments .c-comment .c-comment-hide {
  float: right;
  text-decoration: underline;
  cursor: pointer;
}
.comments .c-comment .c-like-users {
  font-size: 0.9em;
  padding-top: 5px;
  position: relative;
  width: 530px;
  word-wrap: normal;
}
.comments .c-comment .c-like-users .more-like-users {
  cursor: pointer;
}
.comments .c-comment .c-edit {
  cursor: pointer;
  margin-left: 20px;
  color: #5287c1;
  text-decoration: underline;
}
.comments .c-comment:hover .c-edit {
  opacity: 0.9;
}
.comments .c-comment .c-complain {
  display: block;
  height: 45px;
  margin: -16px 0 0 10px;
}
.comments .c-comment:hover .c-complain {
  display: block;
  background: url("../images/megaphone.png") no-repeat 0;
  height: 45px;
  margin: -16px 0 0 10px;
  width: 45px;
}
.comments .c-comment-list .c-comment-list {
  margin-left: 96px;
}
.comments .c-comment-list .c-comment-list .c-comment {
  background: transparent;
  padding: 25px 0;
  padding-left: 55px;
  margin-top: 0;
}
.comments .c-comment-list .c-comment-list .c-comment .c-user .c-username img {
  border-radius: 2px;
  width: 40px;
  height: 40px;
  left: -55px;
}
.comments .c-comment-list .c-comment-list .c-comment-list {
  margin-left: 0;
}
.comments .c-like {
  bottom: 9px;
  float: right;
  position: relative;
  width: auto;
}
@media all and (max-width: 767px) {
  .comments .c-like {
    overflow: hidden;
    margin: 10px 0px;
    float: none;
    bottom: 0;
  }
}
.comments .c-like .c-like-select {
  float: left;
}
.comments .c-like .c-like-select-type {
  display: none;
  position: relative;
  top: -3px;
}
.comments .c-like .c-like-message {
  display: none;
}
.comments .c-like #c-like-image {
  float: left;
  left: 0;
  position: relative;
  top: -2px;
  font-size: 23px;
}
.comments .c-like .like-block,
.comments .c-like .c-like-count {
  float: left;
  display: block;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  padding: 1px;
}
.comments .c-like .like-block {
  line-height: 20px;
  color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.comments .c-like .like-block[data-id="like"] {
  background: #E0ECC8;
}
.comments .c-like .like-block[data-id="like"]:hover {
  background: #c6d2ab;
}
.comments .c-like .like-block[data-id="dislike"] {
  background: #F3C7C7;
}
.comments .c-like .like-block[data-id="dislike"]:hover {
  background: #d7a9a9;
}
.comments .c-like .c-like-count {
  padding: 2px 8px;
  min-width: 34px;
  color: #bfbfbf;
}
.comments .c-like .c-like-count.success {
  color: #aabb89;
}
.comments .c-like .c-like-count.error {
  color: #c24949;
}
.comments .c-edit-buttons span {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #4a4a4a;
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0 15px;
  padding: 3px 15px;
  text-transform: uppercase;
  cursor: pointer;
}
