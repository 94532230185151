html, body {
  height: 100%;
  margin: 0;
  font: 12px/18px ProximaNova, sans-serif;
}

html {
  overflow-x: hidden;
  background: #FFF;
}

@font-face {
  font-family: ProximaNova;
  src: url(/font/ProximaNova-Regular.ttf);
}

.wrapper-index {
  font: 12px/18px ProximaNova, sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  //background: url('/images/agronomu/background.png') no-repeat center 0;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/images/agronomu/background.jpg') no-repeat center bottom;
    background-size: cover;
    opacity: 0.5;
  }
}

.loader-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8) url('/images/load.gif') no-repeat center;
}

.content-main-index {
  height: auto;
  max-width: 1340px;
  width: 1323px;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(145, 166, 186, 0.3);
  padding-bottom: 222px;
  background: #FFF;
}

img {
  vertical-align: middle;
}

* {
  outline: none;
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row, .block-separator {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.right {
  float: right;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-fx-1, .col-fx-2, .col-fx-3, .col-fx-4, .col-fx-5, .col-fx-6, .col-fx-7, .col-fx-8, .col-fx-9, .col-fx-10, .col-fx-11, .col-fx-12,
.col-sx-1, .col-sx-2, .col-sx-3, .col-sx-4, .col-sx-5, .col-sx-6, .col-sx-7, .col-sx-8, .col-sx-9, .col-sx-10, .col-sx-11, .col-sx-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-dl-1, .col-dl-2, .col-dl-3, .col-dl-4, .col-dl-5, .col-dl-6, .col-dl-7, .col-dl-8, .col-dl-9, .col-dl-10, .col-dl-11, .col-dl-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-1{width:8.33333333%;}.col-xs-2{width:16.66666667%;}.col-xs-3{width:25%;}.col-xs-4{width:33.33333333%;}.col-xs-5{width:41.66666667%;}.col-xs-6{width:50%;}
.col-xs-7{width:58.33333333%;}.col-xs-8{width:66.66666667%;}.col-xs-9{width:75%;}.col-xs-10 {width:83.33333333%;}.col-xs-11{width:91.66666667%;}.col-xs-12{width:100%;}

.col-xs-offset-1{margin-left:8.33333333%;}.col-xs-offset-1-5{margin-left:12.5%;}.col-xs-offset-2{margin-left:16.66666667%;}.col-xs-offset-3{margin-left:25%;}
.col-xs-offset-4{margin-left:33.33333333%;}.col-xs-offset-4-5{margin-left:37.5%;}.col-xs-offset-5{margin-left:41.66666667%;}.col-xs-offset-6{margin-left:50%;}
.col-xs-offset-7{margin-left:58.33333333%;}.col-xs-offset-8{margin-left:66.66666667%;}.col-xs-offset-9{margin-left:75%;}
.col-xs-offset-10{margin-left:83.33333333%;}.col-xs-offset-11{margin-left:91.66666667%;}.col-xs-offset-12{margin-left:100%;}

@media (min-width: 501px)
{
  .col-fx-1, .col-fx-2, .col-fx-3, .col-fx-4, .col-fx-5, .col-fx-6, .col-fx-7, .col-fx-8, .col-fx-9, .col-fx-10, .col-fx-11, .col-fx-12 {
    float: left;
  }
  .col-fx-1{width:8.33333333%;}.col-fx-2{width:16.66666667%;}.col-fx-3{width:25%;}.col-fx-4{width:33.33333333%;}.col-fx-5{width:41.66666667%;}.col-fx-6{width:50%;}
  .col-fx-7{width:58.33333333%;}.col-fx-8{width:66.66666667%;}.col-fx-9{width:75%;}.col-fx-10 {width:83.33333333%;}.col-fx-11{width:91.66666667%;}.col-fx-12{width:100%;}

  .col-fx-offset-1{margin-left:8.33333333%;}.col-fx-offset-1-5{margin-left:12.5%;}.col-fx-offset-2{margin-left:16.66666667%;}.col-fx-offset-3{margin-left:25%;}
  .col-fx-offset-4{margin-left:33.33333333%;}.col-fx-offset-4-5{margin-left:37.5%;}.col-fx-offset-5{margin-left:41.66666667%;}.col-fx-offset-6{margin-left:50%;}
  .col-fx-offset-7{margin-left:58.33333333%;}.col-fx-offset-8{margin-left:66.66666667%;}.col-fx-offset-9{margin-left:75%;}
  .col-fx-offset-10{margin-left:83.33333333%;}.col-fx-offset-11{margin-left:91.66666667%;}.col-fx-offset-12{margin-left:100%;}
}

@media (min-width: 641px)
{
  .col-sx-1, .col-sx-2, .col-sx-3, .col-sx-4, .col-sx-5, .col-sx-6, .col-sx-7, .col-sx-8, .col-sx-9, .col-sx-10, .col-sx-11, .col-sx-12 {
    float: left;
  }
  .col-sx-1{width:8.33333333%;}.col-sx-2{width:16.66666667%;}.col-sx-3{width:25%;}.col-sx-4{width:33.33333333%;}.col-sx-5{width:41.66666667%;}.col-sx-6{width:50%;}
  .col-sx-7{width:58.33333333%;}.col-sx-8{width:66.66666667%;}.col-sx-9{width:75%;}.col-sx-10 {width:83.33333333%;}.col-sx-11{width:91.66666667%;}.col-sx-12{width:100%;}

  .col-sx-offset-1{margin-left:8.33333333%;}.col-sx-offset-1-5{margin-left:12.5%;}.col-sx-offset-2{margin-left:16.66666667%;}.col-sx-offset-3{margin-left:25%;}
  .col-sx-offset-4{margin-left:33.33333333%;}.col-sx-offset-4-5{margin-left:37.5%;}.col-sx-offset-5{margin-left:41.66666667%;}.col-sx-offset-6{margin-left:50%;}
  .col-sx-offset-7{margin-left:58.33333333%;}.col-sx-offset-8{margin-left:66.66666667%;}.col-sx-offset-9{margin-left:75%;}
  .col-sx-offset-10{margin-left:83.33333333%;}.col-sx-offset-11{margin-left:91.66666667%;}.col-sx-offset-12{margin-left:100%;}
}

@media (min-width: 768px)
{
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1{width:8.33333333%;}.col-sm-2{width:16.66666667%;}.col-sm-3{width:25%;}.col-sm-4{width:33.33333333%;}.col-sm-5{width:41.66666667%;}.col-sm-6{width:50%;}
  .col-sm-7{width:58.33333333%;}.col-sm-8{width:66.66666667%;}.col-sm-9{width:75%;}.col-sm-10 {width:83.33333333%;}.col-sm-11{width:91.66666667%;}.col-sm-12{width:100%;}

  .col-sm-offset-1{margin-left:8.33333333%;}.col-sm-offset-1-5{margin-left:12.5%;}.col-sm-offset-2{margin-left:16.66666667%;}.col-sm-offset-3{margin-left:25%;}
  .col-sm-offset-4{margin-left:33.33333333%;}.col-sm-offset-4-5{margin-left:37.5%;}.col-sm-offset-5{margin-left:41.66666667%;}.col-sm-offset-6{margin-left:50%;}
  .col-sm-offset-7{margin-left:58.33333333%;}.col-sm-offset-8{margin-left:66.66666667%;}.col-sm-offset-9{margin-left:75%;}
  .col-sm-offset-10{margin-left:83.33333333%;}.col-sm-offset-11{margin-left:91.66666667%;}.col-sm-offset-12{margin-left:100%;}
}

@media (min-width: 993px)
{
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1{width:8.33333333%;}.col-md-2{width:16.66666667%;}.col-md-3{width:25%;}.col-md-4{width:33.33333333%;}.col-md-5{width:41.66666667%;}.col-md-6{width:50%;}
  .col-md-7{width:58.33333333%;}.col-md-8{width:66.66666667%;}.col-md-9{width:75%;}.col-md-10 {width:83.33333333%;}.col-md-11{width:91.66666667%;}.col-md-12{width:100%;}

  .col-md-offset-1{margin-left:8.33333333%;}.col-md-offset-1-5{margin-left:12.5%;}.col-md-offset-2{margin-left:16.66666667%;}.col-md-offset-3{margin-left:25%;}
  .col-md-offset-4{margin-left:33.33333333%;}.col-md-offset-4-5{margin-left:37.5%;}.col-md-offset-5{margin-left:41.66666667%;}.col-md-offset-6{margin-left:50%;}
  .col-md-offset-7{margin-left:58.33333333%;}.col-md-offset-8{margin-left:66.66666667%;}.col-md-offset-9{margin-left:75%;}
  .col-md-offset-10{margin-left:83.33333333%;}.col-md-offset-11{margin-left:91.66666667%;}.col-md-offset-12{margin-left:100%;}
}

@media (min-width: 1200px)
{
  .col-dl-1, .col-dl-2, .col-dl-3, .col-dl-4, .col-dl-5, .col-dl-6, .col-dl-7, .col-dl-8, .col-dl-9, .col-dl-10, .col-dl-11, .col-dl-12 {
    float: left;
  }
  .col-dl-1{width:8.33333333%;}.col-dl-2{width:16.66666667%;}.col-dl-3{width:25%;}.col-dl-4{width:33.33333333%;}.col-dl-5{width:41.66666667%;}.col-dl-6{width:50%;}
  .col-dl-7{width:58.33333333%;}.col-dl-8{width:66.66666667%;}.col-dl-9{width:75%;}.col-dl-10 {width:83.33333333%;}.col-dl-11{width:91.66666667%;}.col-dl-12{width:100%;}

  .col-dl-offset-1{margin-left:8.33333333%;}.col-dl-offset-1-5{margin-left:12.5%;}.col-dl-offset-2{margin-left:16.66666667%;}.col-dl-offset-3{margin-left:25%;}
  .col-dl-offset-4{margin-left:33.33333333%;}.col-dl-offset-4-5{margin-left:37.5%;}.col-dl-offset-5{margin-left:41.66666667%;}.col-dl-offset-6{margin-left:50%;}
  .col-dl-offset-7{margin-left:58.33333333%;}.col-dl-offset-8{margin-left:66.66666667%;}.col-dl-offset-9{margin-left:75%;}
  .col-dl-offset-10{margin-left:83.33333333%;}.col-dl-offset-11{margin-left:91.66666667%;}.col-dl-offset-12{margin-left:100%;}
}

@media (min-width: 1200px)
{
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1{width:8.33333333%;}.col-lg-2{width:16.66666667%;}.col-lg-3{width:25%;}.col-lg-4{width:33.33333333%;}.col-lg-5{width:41.66666667%;}.col-lg-6{width:50%;}
  .col-lg-7{width:58.33333333%;}.col-lg-8{width:66.66666667%;}.col-lg-9{width:75%;}.col-lg-10 {width:83.33333333%;}.col-lg-11{width:91.66666667%;}.col-lg-12{width:100%;}

  .col-lg-offset-1{margin-left:8.33333333%;}.col-lg-offset-1-5{margin-left:12.5%;}.col-lg-offset-2{margin-left:16.66666667%;}.col-lg-offset-3{margin-left:25%;}
  .col-lg-offset-4{margin-left:33.33333333%;}.col-lg-offset-4-5{margin-left:37.5%;}.col-lg-offset-5{margin-left:41.66666667%;}.col-lg-offset-6{margin-left:50%;}
  .col-lg-offset-7{margin-left:58.33333333%;}.col-lg-offset-8{margin-left:66.66666667%;}.col-lg-offset-9{margin-left:75%;}
  .col-lg-offset-10{margin-left:83.33333333%;}.col-lg-offset-11{margin-left:91.66666667%;}.col-lg-offset-12{margin-left:100%;}
}

.table-row {
  margin: 15px -15px;
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
  .col-sx-1, .col-sx-2, .col-sx-3, .col-sx-4, .col-sx-5, .col-sx-6, .col-sx-7, .col-sx-8, .col-sx-9, .col-sx-10, .col-sx-11, .col-sx-12,
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
  .col-dl-1, .col-dl-2, .col-dl-3, .col-dl-4, .col-dl-5, .col-dl-6, .col-dl-7, .col-dl-8, .col-dl-9, .col-dl-10, .col-dl-11, .col-dl-12,
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12{
    float: none;
    padding: 15px;
  }
}

.main-tags-block {
  list-style: none;
  margin: 0;
  background-color: #F0F5F5;
  border-radius: 3px;
  overflow: hidden;
  padding: 18px 25px;
  li {
    padding: 17px 5px;
    float: left;
    width: 25%;
    text-align: center;
    a {
      font-size: 14px;
      font-weight: bold;
      color: #282828;
      &:hover, &:focus {
        color: #282828;
        text-decoration: underline;
      }
    }
  }
  .image-block {
    height: 55px;
    margin-bottom: 15px;
    .table-cell {
      vertical-align: bottom;
    }
  }
}

.other-table {
  height: 50%;
  display: table;
  width: 100%;
  &.padding-bottom {
    padding-bottom: 15px;
  }
  &.padding-top {
    padding-top: 15px;
  }
}

.other-block {
  background: #FFFAE5;
  border-radius: 2px;
  text-align: center;
  padding: 15px 5px;
  a {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: #282828;
    &:hover, &:focus {
      color: #282828;
      text-decoration: underline;
    }
    .image-block {
      margin-bottom: 25px;
    }
    .text-count-post {
      font-weight: normal;
      color: #868686;
      min-height: 23.4px;
      margin-top: 7px;
    }
  }
}

a {
  text-decoration: none;
  color: #5a82d1;
  &:hover, &:focus {
    text-decoration: none;
    color: #e91010;
    .text-post {
      color: #e91010;
      > div, .text, .category {
        color: #e91010;
      }
    }
  }
}

.tablet-block, .mobile-block, .little-tablet {
  display: none;
}

.content-index {
  .content-index-left {
    top: 0;
    width: 933px;
    position: relative;
    float: left;
  }
  .content-index-right {
    width: 300px;
    float: right;
    position: relative;
    top: 0;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
  > .content-main-post {
    margin: -30px -30px 0;
    padding: 30px 30px 0;
  }
}

/* User index
-------------------------------------------------------------------------------------------------------------------*/
.user-index {
  height: auto;
  .top-user-index {
    overflow: hidden;
    margin: 8px 0 15px 0;
    position: relative;
    .top-title {
      float: left;
    }
    .mini-user-menu {
      float: right;
      height: 58px;
      width: 345px;
      background: #000000;
      p {
        padding: 20px;
        color: #ffffff;
        font-size: 21px;
      }
    }
  }
  .main-user-index {
    overflow: hidden;
    position: relative;
    .main-user-left {
      float: left;
      width: 250px;
      height: auto;
      margin: 0 20px 0 0;
      ul {
        width: 222px;
        padding: 0;
        list-style-type: none;
        li:last-child {
          border-bottom: 4px solid #ddddde;
        }
        li:first-child {
          cursor: none;
          background: #2a2a2a;
          padding: 9px 0 6px 20px;
          h1 {
            margin: 0;
            color: #f9cd1b;
            font-size: 12px;
            display: inline;
            padding: 0 0 0 10px;
          }
        }
        .visit {
          border-left: 3px solid #f9cd1b;
          padding: 8px 0 8px 10px;
        }
        .ul-title {
          cursor: none;
          background: #d4d4d4;
          padding: 9px 0 6px 20px;
          h1 {
            margin: 0;
            color: #676767;
            font-size: 12px;
            display: inline;
            padding: 0 0 0 10px;
          }
        }
        li {
          cursor: pointer;
          background: #f6f6f6;
          padding: 8px 0 8px 10px;
          border-bottom: 1px solid #ddddde;
          a {
            color: #2a2a2a;
            font-size: 13px;
            text-decoration: none;
          }
        }
      }
    }
    .main-user-right {
      width: auto;
      height: auto;
      padding: 0 0 0 270px;
      .main-user-right-title {
        height: 22px;
        width: 100%;
        padding: 0 0 0 45px;
        span {
          color: #2a2a2a;
          font-size: 12px;
        }
      }
      .main-user-right-main {
        height: auto;
        overflow: hidden;
        position: relative;
        .main-user-right-foto {
          float: left;
          width: 150px;
          min-height: 200px;
          a {
            color: #ffffff;
            cursor: pointer;
            font-size: 12px;
            position: absolute;
            background: #2a2a2a;
            text-decoration: none;
            margin: 17px 28px 0 28px;
            padding: 8px 10px 8px 10px;
          }
          .foto {
            width: 130px;
            height: 130px;
            border-radius: 7px;
            background: #f6f6f6;
            border: 3px solid #d9d9d9;
            background-image: url("/images/forum/userlogo.png");
          }
        }
        .main-user-right-info {
          float: left;
          height: auto;
          .status {
            height: auto;
            h2 {
              margin: 0;
              color: #2a2a2a;
              font-size: 16px;
              font-weight: Bold;
              span {
                color: #2a2a2a;
                font-size: 20px;
                font-family: AllegroScript, serif;
              }
            }
            .newstatus {
              padding: 0 0 15px 0;
              input {
                height: 30px;
                width: 500px;
                border-radius: 2px;
                margin: 10px 0 0 0;
                background: #f6f6f6;
                padding: 0 0 0 12px;
                border: 1px solid #e8e8e8;
              }
              a {
                color: #ffffff;
                cursor: pointer;
                font-size: 12px;
                margin: 0 0 0 5px;
                background: #2a2a2a;
                text-decoration: none;
                padding: 8px 10px 8px 10px;
              }
            }
            .gender {
              font-size: 14px;
              color: #2a2a2a;
              padding: 0 0 15px 0;
              border-bottom: 1px solid #dededf;
              h2 {
                margin: 0;
                color: #2a2a2a;
                font-size: 16px;
                font-weight: Bold;
                display: inline;
              }
              .radio-button {
                display: inline;
                input {
                  width: 20px;
                  height: 20px;
                  margin: 15px 5px 0 20px;
                }
              }
            }
            .personal-info-box {
              padding: 20px 0 0 0;
              border-bottom: 1px solid #dededf;
              h2 {
                margin: 0;
                color: #2a2a2a;
                font-size: 16px;
                font-weight: Bold;
                padding: 0 0 10px 0;
                span {
                  color: #2a2a2a;
                  font-size: 14px;
                  font-weight: 100;
                  font-family: Arial;
                }
              }
              span {
                color: #999999;
                font-size: 11px;
              }
              .checbox-info {
                input {
                  width: 19px;
                  height: 19px;
                  display: inline;
                }
                span {
                  color: #2a2a2a;
                  font-size: 12px;
                }
              }
              .show-its-age {
                width: 100%;
                height: auto;
                overflow: hidden;
                position: relative;
                margin: 10px 0 8px 0;
                .show-its-age-info {
                  float: left;
                  width: 170px;
                  height: auto;
                }
                .show-its-age-setting {
                  float: right;
                  width: 150px;
                  height: auto;
                  text-align: right;
                  a {
                    color: #2a2a2a;
                    font-size: 11px;
                    text-decoration: underline;
                  }
                }
              }
            }
            .persotal-info {
              h2 {
                margin: 20px 0 0 0;
                color: #676767;
                font-size: 14px;
                font-weight: Bold;
              }
              input {
                width: 100%;
                height: 30px;
                border-radius: 2px;
                margin: 10px 0 0 0;
                background: #f6f6f6;
                padding: 0 0 0 12px;
                border: 1px solid #e8e8e8;
              }
              textarea {
                width: 100%;
                resize: none;
                height: 100px;
                border-radius: 2px;
                margin: 10px 0 0 0;
                background: #f6f6f6;
                padding: 7px 0 0 12px;
                border: 1px solid #e8e8e8;
              }
            }
            .much-button {
              width: 220px;
              height: 50px;
              margin: 30px auto;
              overflow: hidden;
              position: relative;
              .cencel {
                color: #ffffff;
                cursor: pointer;
                font-size: 12px;
                margin: 0;
                position: absolute;
                background: #cdcdcd;
                text-decoration: none;
                padding: 8px 20px 8px 20px;
              }
              .save {
                right: 0;
                color: #ffffff;
                cursor: pointer;
                font-size: 12px;
                margin: 0 0 0 5px;
                position: absolute;
                background: #2a2a2a;
                text-decoration: none;
                padding: 8px 20px 8px 20px;
              }
            }
          }
        }
      }
      .main-title-days-last {
        height: 25px;
        margin: 5px 0 0 0;
        background: #d4d4d4;
        h2 {
          color: #2e2e2e;
          font-size: 12px;
          padding: 6px 0 0 15px;
        }
      }
      .main-title-days {
        height: 25px;
        background: #f9cd1b;
        h2 {
          color: #2e2e2e;
          font-size: 12px;
          padding: 6px 0 0 15px;
        }
      }
      table {
        width: 100%;
        tbody {
          tr:last-child {
            border-bottom: 3px solid #e0e0e1;
          }
          tr {
            td {
              border-bottom: 1px solid #e0e0e1;
              .info-foto {
                img {
                  vertical-align: middle;
                  display: block;
                  border-radius: 4px;
                  float: left;
                  border: 2px solid #d3d3d3;
                  -webkit-border-radius: 4px;
                  -moz-border-radius: 4px;
                  margin: 25px 9px;
                }
              }
              .message {
                margin: 31px 0 0 0;
                p {
                  color: #494949;
                  font-size: 12px;
                  a {
                    color: #2585ce;
                    font-size: 12px;
                  }
                  .name {
                    padding: 5px 0 5px 15px;
                    background-image: url("/images/forum/user.png");
                    color: #2a2a2a;
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    margin: 0 5px 0 0;
                    font-size: 11px;
                    font-family: Arial;
                    font-weight: bold;
                  }
                  .time {
                    padding: 5px 0 5px 15px;
                    background-image: url("/images/forum/time.png");
                    color: #2a2a2a;
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    font-size: 11px;
                    font-family: Arial;
                  }
                }
              }
            }
          }
        }
      }
      .bottom-menu-info {
        width: 100%;
        height: 100px;
        margin: 35px 0 0 0;
        .yet {
          width: 600px;
          float: left;
          span {
            color: #828282;
            font-size: 12px;
          }
        }
        .forum-nav {
          width: 400px;
          float: right;
          position: relative;
          overflow: hidden;
          .summary {
            display: inline;
            font-size: 12px;
            color: #494949;
          }
          ul {
            padding: 0;
            margin: 0;
            float: right;
            display: inline-flex;
            list-style-type: none;
            .active {
              color: #000000;
              background: #f9cd1b;
            }
            .next {
              color: #eec41c;
              background: #2a2a2a;
              a {
                color: #eec41c;
              }
            }
            li {
              color: #2a2a2a;
              margin: 0 8px 0 0;
              background: #cdcdcd;
              padding: 1px 6px 2px 6px;
            }
          }
        }
      }
    }
  }
}

/* Content-index
-------------------------------------------------------------------------------------------------------------------*/
.site-login {
  width: 800px;
  margin: 0 auto;
  .form-horizontal {
    .form-group {
      &:last-child {
        top: -25px;
        display: block;
        position: relative;
        .col-lg-11 {
          display: inline-block;
          width: auto !important;
          margin: 0;
        }
      }
      .ubi-register {
        display: inline-block;
        line-height: 47px;
        float: right;
        margin: 0 20px 0 0;
      }
    }
  }
  h1 {
    text-align: center;
    margin: 40px 0;
  }
  .col-lg-3 {
    width: 100%;
  }
  .form-horizontal {
    width: 300px;
    margin: 20px auto;
  }
  .eauth-service-id-yahoo {
    display: none;
  }
  .eauth-service-id-github {
    display: none;
  }
  .eauth-service-id-live {
    display: none;
  }
  .eauth-service-id-steam {
    display: none;
  }
  .col-lg-offset-1 {
    display: none;
    &.col-lg-11 {
      display: block;
    }
  }
}

/* header
-------------------------------------------------------------------------------------------------------------------*/
.menu-index-logo {
  float: left;
  width: 225px;
  padding: 10px 0;
  a {
    display: inline-block;
    color: #282828;
    text-decoration: none;
    margin: 0 0 0 30px;
    .footer-logo {
      filter: grayscale(1);
    }
  }
  img {
    float: left;
  }
  span {
    display: block;
    font-size: 11px;
    padding: 7px 0 7px 63px;
    line-height: 13px;
  }
  b {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 21px;
  }
}

.menu-enter {
  width: 100%;
  position: relative;
  z-index: 5;
  //height: 68px;
  border-bottom: 1px solid #F5F5F5;
  .progress-bar {
    display: none;
    height: 4px;
    float: none;
    width: 100%;
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    box-shadow: none;
    #progress {
      height: 100%;
      padding-right: 100%;
      div {
        background: #39b54a;
        height: 100%;
      }
    }
  }
  .new-enter {
    color: #fff;
    float: right;
    font-size: 18px;
    width: 247px !important;
    margin: -15px 25px 0 0;
    .avatarr {
      margin: -4px 0 0 20px;
      border-radius: 25px;
    }
    a {
      text-transform: lowercase;
      float: right;
      color: #fff;
      display: inline-block;
      width: 70px;
      height: 30px;
      font-size: 12px;
      cursor: pointer;
      padding: 3px 10px;
      margin: 0 0 0 15px;
      text-decoration: none;
      font-family: ProximaNova, serif;
      border: 3px solid #FFDE13;
      line-height: 16px;
      text-align: center;
      border-radius: 15px;
      transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
      &:hover, &:active {
        background: #FFDE13;
        color: #666;
        border: none;
        line-height: 22px;
      }
    }
    .enter {
      margin: 10px 10px 0 0;
      //border: 2px solid #f3f3f1;
    }
  }
}

.menu-block {
  padding: 0;
  display: block;
  margin: 0;
  position: relative;
  list-style-type: none;
  max-height: 68px;
  &.tablet {
    display: none;
  }
  ul {
    display: inline-block;
    padding: 0;
  }
  li, > div {
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    line-height: 68px;
    float: left;
    &.right {
      float: right;
    }
    &.no-float {
      float: none;
    }
    &.menu-mobile {
      display: none;
      border: medium none;
      margin: 0;
      padding: 25px;
      background: #FAFCFC;
      float: left;
      cursor: pointer;
      .icon-bar {
        display: block;
        background-color: #FFCA00;
        width: 14px;
        height: 4px;
        margin-bottom: 2px;
      }
    }
    &.tablet, &.little-tablet {
      display: none;
    }
    &.dacha {
      display: block;
      background: #FFCA00 url('/images/agronomu/dacha-video.png') no-repeat 25px center;
      a {
        line-height: 16px;
        padding: 18px 30px 18px 75px;
        color: #fff5d0;
        background: transparent;
        .text {
          display: block;
          font-size: 12px;
          text-transform: none;
        }
        &:hover, &:focus {
          background: transparent;
        }
      }
    }
    a {
      color: #282828;
      display: block;
      cursor: pointer;
      padding: 0 20px;
      text-decoration: none;
      font-family: ProximaNova, serif;
      font-weight: bold;
      text-transform: uppercase;
      &.admin-block {
        padding: 0;
      }
      &:not(.social):not(.admin-block) {
        &:hover, &:focus {
          background: #fafcfc;
        }
      }
      .last {
        margin: 0 -5px 0 15px;
      }
    }
    &.auth {
      width: 225px;
      line-height: 1.1;
      .avatar-link {
        padding: 15px 20px;
        height: 68px;
        //cursor: pointer;
      }
      .avatar-image {
        float: right;
      }
      .avatar-block {
        display: block;
        padding: 4px 52px 4px 0;
        text-align: right;
        > div {
          display: inline-block;
          text-align: left;
        }
        span {
          font-weight: bold;
          text-transform: uppercase;
          display: inline-block;
          height: 16px;
          overflow: hidden;
        }
        a {
          padding: 0;
          text-transform: none;
          font-weight: normal;
          font-size: 12px;
          &:hover, &:focus {
            background: transparent;
          }
        }
      }
    }
    &.active:not(.dacha) {
      a {
        background: #fafcfc;
        &:hover, &:focus {
          background: #fafcfc;
        }
      }
    }
    &.search {
      padding: 0 20px;
      border-left: 1px solid #F5F5F5;
      border-right: 1px solid #F5F5F5;
      cursor: pointer;
      input {
        display: none;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: #f5f5f5;
        padding: 10px 50px 10px 30px;
        background: #fafcfc url('/images/agronomu/search.png') no-repeat right 25px top 26.5px;
        &:hover, &:focus {
          display: block;
        }
      }
      .search-window {
        width: 25px;
        height: 68px;
        background: url('/images/agronomu/search.png') no-repeat center;
      }
      &:hover, &.active {
        input {
          display: block;
        }
      }
    }
  }
}

.menu {
  display: flex;
  position: relative;
  .menu-block {
    width: 100%;
  }
}

/* footer
-------------------------------------------------------------------------------------------------------------------*/
.footer-block {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer-index {
  padding: 25px 30px 20px;
  line-height: 30px;
  font-size: 14px;
  .text {
    font-size: 12px;
    line-height: 18px;
  }
  .link-block {
    float: right;
  }
  .socials {
    text-align: center;
    padding-top: 15px;
    line-height: 1;
    a {
      display: inline-block;
      padding: 5px;
      margin: 0 5px;
    }
  }
}

/* index- post
-------------------------------------------------------------------------------------------------------------------*/
.index-post {
  font-family: ProximaNova, serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #4c4c4c;
  table {
    margin-bottom: 30px;
  }
  .answer-table-block {
    border: 1px solid #F0F7F7;
    border-collapse: collapse;
    tr {
      &:nth-child(odd) {
        background: rgba(240, 247, 247, 0.5);
      }
      td {
        padding: 4px 15px;
        border: 1px solid #F0F7F7;
        width: 65%;
        &:first-child {
          width: 35%;
        }
      }
    }
  }
  .table-bordered {
    border: 1px solid #C4C4C4;
    border-collapse: collapse;
    td {
      border: 1px dashed #C4C4C4;
      font-size: 18px;
      line-height: 1;
      padding: 20px 15px;
      width: 1%;
      p {
        margin: 0;
      }
    }
    &:not(.no-header) {
      tr:first-child {
        td {
          border-bottom-style: solid;
          background: #FFFAE5;
          font-weight: bold;
        }
      }
    }
  }

  a {
    color: #2c74bb;
    text-decoration: underline rgba(119, 149, 213, 0.2);
  }

  ul {
    padding-left: 40px;
  }
  .review-block {
    border-radius: 2px;
    background: #ebf2e2;
    margin: 30px 0;
    overflow: hidden;
    .text {
      padding: 25px 30px 60px;
    }
    .author-block {
      margin: 0px;
      padding: 35px 30px 35px 275px;
      background: #d4dfc5;
      font-size: 24px;
      line-height: 24px;
      position: relative;
      img {
        position: absolute;
        top: -30px;
        left: 0;
        margin: 0;
      }
    }
    .author {
      color: #282828;
      font-weight: 600;
      margin-bottom: 7px;
    }
    .source {
      line-height: 18px;
      color: #4c4c4c;
      font-size: 14px;
    }
  }
  .index-post-slaider {
    margin: 60px 0 30px 0;
  }
  h2, h3, h4, h5, h6 {
    clear: both;
    line-height: 30px;
    font-weight: 600;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 24px;
    font-weight: 400;
  }

  h6 {
    font-size: 18px;
  }
  img {
    margin: 10px auto;
    display: block;
  }
  p {
    color: #4c4c4c;
    text-align: left;
    //overflow: hidden;
  }
  > p, > div {
    margin: 30px 0;
  }
  iframe {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    @media (max-width: 640px) {
      height: calc(100vw * 406 / 620);
    }
  }
  p:not([style="margin: 0px;"]) + ol {
    margin-top: -20px;
  }
  .bb-image, .bb-video {
    margin: 30px 0;
    display: block;
    & + .bb-image {
      margin: 30px 0 0 0;
    }
    img {
      cursor: pointer;
      width: 100%;
      max-width: 790px;
      margin: 0 auto;
    }

    &.bb-image-align-left, &.bb-image-align-right {
      margin: 0;
      width: 330px;
      img {
        width: 100%;
      }
    }
    &.bb-image-align-left {
      float: left;
      padding: 5px 30px 30px 0;
    }
    &.bb-image-align-right {
      float: right;
      padding: 5px 0 30px 30px;
    }
    .bb-image-desc {
      padding: 25px 20px;
      border-radius: 2px;
      background: #f0f7f7;
      font-size: 14pt;
      line-height: 20pt;
      color: #718585;
      display: block;
      max-width: 740px;
      margin: 0 auto;
      width: 90%;
      width: ~"calc(100% - 25px)";
      text-align: center;
    }
  }
  ol .bb-image-align-left {
    margin: 5px 10px 5px -40px;
  }
  ul .bb-image-align-left {
    margin: 5px 10px 5px 0;
  }
  .br {
    display: block;
    clear: both;
  }
  .contents {
    padding: 20px 30px 20px 10px;
    background-color: #f0f7f7;
    clear: both;
    margin-top: 30px;
    .title-block.comments {
      margin: 8px 20px 15px;
      float: left;
      > div {
        display: inline;
      }
    }
    #hide-contents {
      float: right;
      margin: 4px;
      .hide {
        display: none;
      }
    }
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      margin-left: 20px;
    }
    li {
      margin: 0;
      padding: 5px 0;

      a {
		padding-left: 20px;
		display: inline-block;
		color: #474747;
		text-decoration-color: #d8e5f1;
		transition: all .3s ease;
		position: relative;
		  &::before, &::after {
			  position: absolute;
			  content: "";
			  width: 4px;
			  height: 6px;
			  top: 10px;
			  left: 0;
			  transition: all .3s ease;
		  }
		  &::after {
			  background: url('/images/agronomu/post2.png') no-repeat;
			  opacity: 0;
		  }
		  &::before {
			  background: url('/images/agronomu/post1.png') no-repeat;
			  opacity: 1;
		  }
        &:hover {
			&::before {
				opacity: 0;
			}
			&::after {
				opacity: 1;
			}
        }
      }
    }
    &.hide-text {
      .title-block.comments {
        margin-bottom: 8px;
      }
      ul {
        display: none;
      }
      #hide-contents {
        span {
          display: none;
          &.hide {
            display: inline;
          }
        }
      }
    }
  }
  .ab {
    clear: both;
    display: inline-block;
    p {
      margin: 0;
    }
    .bb-image, .bb-video {
      width: 480px;
      img {
        width: 480px;
      }
      &.bb-image-align-left, &.bb-image-align-right {
        width: 150px;
        img {
          width: 150px;
          height: 100px;
        }
      }
    }
  }

  .flex-control-paging li a {
    width: 15px;
    height: 15px;
    background: #4a4a4a;
  }
  .flex-control-paging li .flex-active {
    background: #308fed;
  }
  .flex-direction-nav a {
    opacity: 1;
  }
  .ab{
    margin: 27px 0;
    padding: 20px 25px;
    float: none;
    display: block;
    overflow: hidden;
    border-radius: 2px;
  }
  .ab-warning, .ab-danger, .ab-tip, .ab-info, .ab-definition {
    padding-left: 120px;
	  position: relative;
    > div {
      width: 100%;
      display: table;
      height: 78px;
      > div {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .ab-definition {
    background-color: rgba(196, 224, 32, 0.1);
	   &::before, &::after {
		  position: absolute;
		  content: "";
		  background: url('/images/agronomu/icons/ab-definition.png');
		  top: 0;
		  bottom: 0;
		  margin: auto;
		  left: 25px;
		  width: 70px;
		  height: 70px;
		   opacity: 1;
		   transition: all .7s ease;
		   @media all and (max-width: 767px) {
			bottom: initial;
			right: 0;
			left: 0;
			  top: 25px;
		  }
	  }
	  &::after {
		  background: url('/images/agronomu/icons/ab-definition2.png');
		  opacity: 0;
	  }
	  &:hover {
		  transition: all .7s ease;
		  &::before {
			  opacity: 0;
		  }
		  &::after {
			  opacity: 1;
		  }
	  }
  }
  .ab-warning, .ab-danger {
    background-color: #fff4f4;
	  &::before {
		  position: absolute;
		  content: "";
		  background: url('/images/agronomu/icons/ab-warning.png');
		  top: 0;
		  bottom: 0;
		  margin: auto;
		  left: 25px;
		  width: 70px;
		  height: 70px;
		  @media all and (max-width: 767px) {
			bottom: initial;
			right: 0;
			left: 0;
			  top: 25px;
		  }
	  }
	  &::after {
		position: absolute;
		content: "";
		background: url('/images/agronomu/icons/ab-warning2.png');
		top: -12px;
		bottom: 0;
		margin: auto;
		left: 28px;
		width: 63px;
		height: 63px;
		animation: rotate-m 8s infinite linear;
		  @media all and (max-width: 767px) {
			bottom: initial;
			right: 0;
			left: 0;
			  top: 25px;
		  }
	  }
	  @keyframes rotate-m {
		  from {
			  transform: rotate(0deg);
		  }
		  to {
			  transform: rotate(359deg);
		  }
	  }
  }
  .ab-luck {
    border: solid #50ffdc;
  }
  .ab-tip, .ab-info {
    background-color: #f0f7f7;
	  &::before, &::after {
		  position: absolute;
		  content: "";
		  background: url('/images/agronomu/icons/ab-info.png');
		  top: 0;
		  bottom: 0;
		  margin: auto;
		  left: 25px;
		  width: 70px;
		  height: 70px;
		   opacity: 1;
		   transition: all .7s ease;
		  @media all and (max-width: 767px) {
			bottom: initial;
			right: 0;
			left: 0;
			  top: 25px;
		  }
	  }
	  &::after {
		  background: url('/images/agronomu/icons/ab-info2.png');
		  opacity: 0;
	  }
	  &:hover {
		  transition: all .7s ease;
		  &::before {
			  opacity: 0;
		  }
		  &::after {
			  opacity: 1;
		  }
	  }
  }
	* a {
		transition: all .3s ease;
		&:hover {
			transition: all .3s ease;
			color: #e91010;
			text-decoration-color: #e91010;
		}
	}
}

/* index-post-comments
-------------------------------------------------------------------------------------------------------------------*/
.index-post-comments {
  float: left;
  overflow: hidden;
  span {
    color: #333333;
    font-size: 13px;
    padding: 1px 0 0 25px;
    font-family: ProximaNova, serif;
    font-weight: normal;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url("/images/forum/zakladka.png");
  }
  .index-post-event-tag-news {
    .tag-news {
      margin: 10px 0;
      line-height: 1;
      overflow: hidden;
      .tag-news-left {
        width: 64px;
        float: left;
        img {
          margin: 0
        }
      }
      .tag-news-right {
        width: 85%;
        float: left;
        p {
          margin: 0;
          font-size: 15px;
        }
        .tag-news-right-data-public {
          font-size: 13px;
          font-weight: bold;
          padding: 10px 0 0 0;
        }
      }
    }
  }
  .tag-list {
    a {
      color: #282828;
      background: rgba(255, 202, 0, 0.1);
      text-decoration: none;
      margin: 1px 1px 1px 3px;
      font-size: 11pt;
      padding: 1px 6px;
      border-radius: 3px;
      display: inline-block;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
  .comment-index-button {
    width: 100%;
    height: 70px;
    h1 {
      float: left;
      color: #4a4a4a;
      font-size: 24px;
      font-weight: 900;
    }
    a {
      float: right;
      color: #333333;
      cursor: pointer;
      font-size: 16px;
      padding: 3px 15px;
      margin: 22px 0 0 0;
      font-family: ProximaNova, serif;
      font-weight: bold;
      border: 2px solid #5c5c5c;
    }
  }
  .index-comment {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-top: 1px solid #c2c2c2;
    a {
      float: left;
      img {
        margin: 20px 0 0 0;
        border-radius: 35px;
      }
    }
    .text {
      float: left;
      width: 480px;
      margin: 0 0 0 40px;
      h1 {
        color: #222222;
        font-size: 15px;
        font-weight: bold;
        span {
          color: #8c8c8c;
          font-size: 15px;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: italic;
          background-image: none;
        }
      }
      span {
        padding: 0;
        color: #222222;
        font-size: 16px;
        background-image: none;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      a {
        color: #8c8c8c;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 0 20px 0;
      }
    }
  }
  .index-comment-otvet {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-top: 1px solid #c2c2c2;
    a {
      float: left;
      margin: 0 0 0 108px;
      img {
        margin: 20px 0 0 0;
        border-radius: 35px;
      }
    }
    .text {
      float: left;
      width: 400px;
      margin: 0 0 0 20px;
      h1 {
        color: #222222;
        font-size: 15px;
        font-weight: bold;
        span {
          color: #8c8c8c;
          font-size: 15px;
          padding: 0 10px 0 5px;
          background-image: none;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: italic;
        }
      }
      span {
        padding: 0;
        color: #222222;
        font-size: 16px;
        background-image: none;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      a {
        margin: 0;
        color: #8c8c8c;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 0 20px 0;
      }
    }
  }
}

/* index-post-title
-------------------------------------------------------------------------------------------------------------------*/
.index-post-title {
  width: 100%;
  overflow: hidden;
  .title-center {
    width: 1323px;
    min-height: 343px;
    margin: 0 auto;
    position: relative;
    /*-webkit-box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);
    -moz-box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);
    box-shadow: 0px 0px 11px 0px rgba(50, 50, 49, 0.75);*/
    &:before {
      content: '';
      position: absolute;
      width: 1323px;
      height: 200px;
      left: 0;
      bottom: 0;
      margin: 0;
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(78%, rgba(0, 0, 0, 0.8)), color-stop(99%, rgba(0, 0, 0, 0.8)));
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.8) 78%, rgba(0, 0, 0, 0.8) 99%);
    }
    .index-post-into-title {
      width: 100%;
      position: absolute;
      bottom: 0;
      h2, h1 {
        //margin: 0;
        //height: 90px;
        color: #FFFFFF;
        font-size: 32px;
        padding-left: 45px;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
      .block-banner {
        margin: 10px 0 20px 30px;
        a {
          color: #cccaca;
          font-size: 14px;
          cursor: pointer;
          font-weight: bold;
          text-decoration: none;
          img {
            margin: -2px 10px 0 15px;
          }
          p {
            color: #000;
            font-size: 14px;
            display: inline;
            font-weight: bold;
            border-radius: 5px;
            background: #ffdf3f;
            padding: 0px 6px 0 6px;
          }
        }
      }
    }
  }
}
/* content-main-post
-------------------------------------------------------------------------------------------------------------------*/
.content-main-post {
  overflow: hidden;
  position: relative;
  background: #FFFFFF;
  /*.content-main-post-form {
    float: left;
    width: 890px;
    position: relative;
    .content-main-post-text {
      float: left;
      width: 620px;
      height: auto;
      h1 {
        margin: 0 0 25px 0;
        font-weight: bold;
        font-size: 30px;
        color: #3c2313;
      }
      .show-more {
        width: 939px;
        height: 50px;
        overflow: hidden;
        text-align: center;
        margin: 20px 0 0 0;
        background: #308fed;
        padding: 15px 0 15px 0;
        a {
          color: #333333;
          cursor: pointer;
          font-size: 16px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
        img {
          margin: -4px 0 0 0;
        }
      }
    }
    .social-block-on-980 {
      display: none;
    }
    .content-main-post-widgets {
      float: left;
      width: 220px;
      margin: 0 25px;
      min-height: 200px;
    }
    .index-post {
      width: 620px;
      float: left;
      //padding-right: 20px;
    }
  }
  .content-main-post-banner {
    float: left;
    width: 300px;
    height: auto;
    ul {
      list-style-type: none;
      li:last-child {
        margin: 0;
      }
      li {
        margin: 0 0 30px 0;
      }
    }
    .widget-sinoptik {
      //height: 143px;
      border-radius: 5px;
    }
    .widget-changegid {
      height: 367px;
      margin: 0 0 20px 0;
      background: #f5f4f0;
    }
    .widget-rozsilka {
      width: 100%;
      height: auto;
      .widget-rozsilka-title {
        height: 50px;
        background: #4a4a4a;
        h1 {
          color: #ffffff;
          font-size: 16px;
          padding: 16px 0 0 20px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .widget-rozsilka-index {
        background: #f5f4f0;
        input {
          border: none;
          height: 30px;
          width: 280px;
          color: #808080;
          font-size: 16px;
          padding: 0 0 0 15px;
          margin: 15px 0 20px 10px;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: italic;
        }
        a {
          color: #333333;
          font-size: 16px;
          margin: 0 0 0 10px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
          border: 2px solid #5c5c5c;
          padding: 3px 10px 3px 10px;
        }
        .text-help {
          padding: 20px 10px 20px 10px;
          span {
            color: #808080;
            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
      }
    }
    .widget-link-on-forum {
      width: 100%;
      height: auto;
      .link-on-forum-title {
        height: 50px;
        background: #4a4a4a;
        h1 {
          color: #ffffff;
          font-size: 16px;
          padding: 16px 0 0 20px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .link-on-forum-main {
        width: 100%;
        height: auto;
        overflow: hidden;
        background: #f5f4f0;
        .link-on-forum-text {
          height: auto;
          width: 270px;
          padding: 10px;
          margin: 10px auto;
          background: #fafaf8;
          h1 {
            color: #4a4a4a;
            font-size: 16px;
            margin: 0 0 10px 0;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
          }
          h2 {
            color: #808080;
            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
          span {

            font-size: 14px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
      }
    }
  }*/
}

.related-news-blocks {
  padding-top: 45px;
}

.related-news {
  margin: -15px;
}

.related-new {
  padding: 15px;
}

/* Слайдер
-------------------------------------------------------------------------------------------------------------------*/
.slider {
  z-index: 9;
  width: 620px;
  height: 520px;
  overflow: hidden;
  margin: 0 0 7px;
  position: relative;
}

.slider ul,
.slider li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.slider ul {
  width: 9999px;
}

.slider ul li {
  list-style-type: none;
  float: left;
  width: 700px;
  height: 290px;
}

.slider .nav {
  position: absolute;
  left: 260px;
  bottom: 12px;
}

.slider .nav span {
  opacity: 0.9;
  background: #4a4a4a;
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  display: block;
  float: left;
}

.slider .nav span.on {
  background: #308fed;
}

/* Слайдер
-------------------------------------------------------------------------------------------------------------------*/
.breadcrumb {
  background-color: transparent;
}

.about-title-transition {
  top: 0;
  margin: 0;
  width: 100%;
  min-height: 32px;
  opacity: 0.6;
  border-radius: 0;
  background: rgb(0, 0, 0, 04);
  padding: 5px 0 0 0;
  position: absolute;

  ul.breadcrumb {
    margin: 0 0 0 135px;
    background-color: transparent;
    li {
      margin: 0;
      padding: 0;
    }
    li:hover {
      background-color: transparent;
    }
    li {
      &:before {
        background: none;
      }
    }
    a {
      color: #FFFFFF;
      cursor: pointer;
      font-size: 13px;
      margin: 0 20px 0 0;
      position: relative;
      text-decoration: none;
      padding: 0 10px 5px 15px;
      font-family: ProximaNova, serif;
      font-weight: normal;
      font-style: normal;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      -moz-border-radius-topleft: 2px;
      -moz-border-radius-bottomleft: 2px;
      -webkit-border-top-left-radius: 2px;
      -webkit-border-bottom-left-radius: 2px;
      &:before {
        top: 3px;
        width: 0;
        height: 0;
        content: ' ';
        right: -15px;
        position: absolute;
        border-style: solid;
        border-width: 7px 0 6.5px 12px;
        border-color: transparent transparent transparent #308fed;
      }
    }
  }
}

#__utl-buttons-1 .uptl_toolbar > ul > li {
  margin-bottom: 12px !important;
}

#multiupload {
  .b-thumb__preview {
    width: 160px !important;
    height: 160px !important;
    -webkit-transition: -webkit-transform .2s ease-in;
    -moz-transition: -moz-transform .2s ease-in;
    transition: transform .2s ease-in;
  }
  .b-thumb__progress {
    top: 150px;
    left: 10px;
    right: 10px;
    position: absolute;
  }
}

/* last post
-------------------------------------------------------------------------------------------------------------------*/
.last-post {
  width: 100%;
  height: auto;
  overflow: hidden;
  .last-post-title {
    width: 100%;
    height: 50px;
    background: #4a4a4a;
    h1 {
      margin: 0;
      color: #308fed;
      font-size: 24px;
      padding: 12px 0 12px 20px;
      font-family: ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
    }
  }
  .last-post-foto {
    position: relative;
    margin: 35px 0 30px 0;
    .index-news-foto:last-child {
      margin: 0 0 19px 0;
    }
    .index-news-foto {
      float: left;
      cursor: pointer;
      overflow: hidden;
      margin: 0 20px 19px 0;
      &:before {
        content: '';
        position: absolute;
        width: 300px;
        height: 183px;
        margin: 2px 0 0 0;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.59) 82%, rgba(0, 0, 0, 0.89) 99%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0); /* IE6-9 */
      }
      .title-name {
        height: 30px;
        width: 98px;
        margin: -185px 0 0 0;
        background: #308fed;
        position: absolute;
        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: -30px;
          border-top: 30px solid #308fed;
          border-right: 30px solid transparent;
        }
        span {
          color: #333333;
          font-size: 13px;
          position: absolute;
          margin: 6px 0 6px 10px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .title-banner-two {
        width: 285px;
        position: absolute;
        margin: -59px 0 0 0;
        h2 {
          margin: 0;
          color: #fefefe;
          font-size: 16px;
          padding: 0 0 10px 15px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
        .block-banner {
          margin: -8px 0 0 0;
          a {
            color: #308fed;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
            img {
              margin: -2px 10px 0 15px;
            }
            p {
              color: #ffffff;
              font-size: 14px;
              display: inline;
              border-radius: 5px;
              background: #ff0000;
              padding: 0px 6px 0 6px;
              font-family: ProximaNova, serif;
              font-weight: bold;
              font-style: normal;
            }
          }
        }
      }
    }
  }
  .botton-banner {
    width: 100%;
    height: 100px;
    overflow: hidden;
    margin: 10px 0 0 0;
  }
}

/* скрипт прокручования в верх*/
#Go_Top {
  right: 60px;
  width: 40px;
  height: 40px;
  bottom: 176px !important;
  cursor: pointer;
  position: absolute;
  z-index: 5000;
  background: #308fed;
  img {
    margin: 10px 0 0 10px;
  }
}

.main-foto {
  width: 300px;
  height: 185px;
}

/*-------- Error --------*/
.site-error {
  width: auto;
  height: auto;
  .site-error-content {
    height: 500px;
    margin: 40px 0 0 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url("/images/site/Error-foto-agro.jpg");
    -webkit-background-size: cover;
    background-size: cover;
    .error-text {
      width: 490px;
      line-height: 25px;
      padding-left: 30px;
      h1 {
        color: #f8c81f;
        font-size: 48px;
        margin: 0 0 50px 0;
        padding: 30px 0 0 0;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
      p {
        color: #fff;
        font-size: 18px;
        margin: 0 0 30px 0;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
      span {
        color: #fff;
        font-size: 16px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
        a {
          color: #f8c81f;
          font-size: 16px;
          text-decoration: underline;
        }
      }
    }
    .search-in-error {
      width: 460px;
      height: auto;
      padding: 20px 0 0 0;
      input {
        width: 400px;
        height: 48px;
        font-size: 18px;
        padding: 0 0 0 10px;
      }
      a {
        margin: 0 0 0 -3px;
        background: #4a4a4a;
        text-decoration: none;
        padding: 18px 18px 16px 18px;
      }
    }
  }
}

/*-------------------- Страница реклама на сайте (Реклама на сайте)----------------------*/

.content-re {
  width: 100%;
  height: auto;
  position: relative;
  table {
    margin: 0 0 25px 0;
    .re-table-title {
      text-align: center;
      background: #308fed;
      border: 2px solid #4a4a4a;
    }
    tr {
      text-align: center;
      .name-banner {
        text-align: left;
      }
      td:last-child {
        width: 100px;
      }
      th {
        p {
          font-size: 18px;
          margin: 10px 0 10px 25px;
        }
      }
      td {
        border: 2px solid #4a4a4a;
        p {
          padding: 10px;
          font-size: 18px;
        }
        span {
          font-size: 16px;
          padding: 0 10px 0 10px;
        }
      }
    }
  }
  .contact-re {
    top: 0;
    right: 0;
    width: 400px;
    height: auto;
    position: absolute;
    span {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

/*--------Авто точки ---------*/
.auto-point {
  width: 100%;
  height: auto;
  position: relative;
  padding: 0 0 1px 0;
  background: #4a4a4a;
  h2 {
    color: #fff;
    font-size: 32px;
    padding: 40px 0 0 0;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
    a {
      color: #308fed;
      cursor: pointer;
      font-size: 32px;
      text-decoration: none;
      font-family: ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
      border-bottom: 2px dashed #308fed;
    }
  }
  .content-point {
    overflow: hidden;
    margin: 45px auto;
    position: relative;
    .title-point {
      float: left;
      width: 300px;
      height: auto;
      margin: 0 23px 0 0;
      .point:hover {
        background: #308fed;
        &:before {
          width: 0;
          content: '';
          height: 17px;
          position: absolute;
          margin: 0 0 0 250px;
          border: 19px solid transparent;
          border-left: 12px solid #308fed;
        }
        a {
          color: #333333;
          text-decoration: none;
        }
      }
      .button-in-point {
        margin: 40px 0 0 0;
      }
      .point {
        width: 250px;
        height: 37px;
        margin: 0 0 5px 0;
        .point-image {
          width: 37px;
          height: 37px;
          display: initial;
        }
        a {
          color: #ffffff;
          cursor: pointer;
          font-size: 18px;
          padding: 0 0 0 20px;
          display: inline-flex;
          text-decoration: none;
          font-family: ProximaNova;
          sub {
            font-size: 11px;
            padding: 0 0 0 5px;
          }
        }
      }
    }
    .auto-map {
      float: left;
      width: 940px;
      height: 545px;
      border: 1px solid #ffffff;
    }
  }
}

/*----показать больше*----*/
.show-more-categori {

  width: 100%;
  height: 50px;
  cursor: pointer;
  text-align: center;
  margin: 0 0 20px 0;
  background: #308fed;
  padding: 16px 0 15px 0;
  a {
    color: #3d3d3d;
    font-size: 16px;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
  }
  img {
    margin: -4px 0 0 0;
  }
}

/*----------search----------*/
.search-content {
  .search-content-title {
    width: 100%;
    height: auto;
    margin: 0 0 31px 0;
    h1 {
      color: #4a4a4a;
      font-size: 24px;
      font-family: ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
    }
    p {
      color: #808080;
      font-size: 18px;
      font-family: ProximaNova, serif;
      font-weight: normal;
      font-style: normal;
      span {
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
    }
  }
  .search-content-main {
    width: 100%;
    height: auto;
    position: relative;
    .search-content-main-search {
      float: left;
      height: auto;
      width: 300px;
      margin: 0 20px 0 0;
      input {
        width: 195px;
        height: 28px;
        color: #222222;
        font-size: 16px;
        padding: 0 0 0 9px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      a {
        color: #4a4a4a;
        font-size: 14px;
        cursor: pointer;
        font-weight: bold;
        position: absolute;
        margin: -1px 0 0 12px;
        border: 2px solid #4A4A4a;
        text-transform: uppercase;
        padding: 4px 10px 4px 10px;
      }
      .block-categori {
        width: 100%;
        height: auto;
        margin: 40px 0 0 0;
        p {
          color: #4a4a4a;
          font-size: 18px;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: normal;
          span {
            font-size: 16px;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
            padding: 0 0 0 15px;
          }
        }
      }
    }
    .search-content-main-finish {
      float: left;
      height: auto;
      width: 600px;
      .search-content-main-search-box {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 0 0 20px 0;
        position: relative;
        .search-content-image {
          float: left;
          width: 140px;
          margin: 0 20px 0 0;
        }
        .search-content-text {
          float: left;
          width: 440px;
          padding: 0 20px 0 0;
          h1 {
            color: #222222;
            font-size: 18px;
            margin: 0 0 15px 0;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
          }
          p {
            color: #222222;
            font-size: 18px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
          a {
            color: #ccad14;
            font-size: 18px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
      }
      .search-jump {
        height: auto;
        width: 100%;
        ul {
          text-align: center;
          .previous {
            a {
              padding: 13px;
              background: #308fed;
            }
          }
          .next {
            a {
              padding: 13px;
              background: #308fed;
            }
          }
          li {
            cursor: pointer;
            display: inline;
            a {
              padding: 13px;
            }
          }
        }
      }
    }
  }
}

/*----------team----------*/
.team-content {
  width: 100%;
  height: auto;
  position: relative;
  h1 {
    color: #4a4a4a;
    font-size: 24px;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
  }
  .team-content-people-box {
    width: 869px;
    margin: 0 auto;
    @media (max-width: 1440px) {
      width: 423px;
    }
    @media (max-width: 992px) {
      width: 869px;
    }
    @media (max-width: 919px) {
      width: 646px;
    }
    @media (max-width: 696px) {
      width: 423px;
    }
    @media (max-width: 473px) {
      width: 200px;
    }

  }
  .team-content-people {
    height: auto;
    position: relative;
    overflow: hidden;
    margin: -10px;
    .team-people-box {
      text-align: center;
      height: auto;
      overflow: hidden;
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 10px;
      .team-people-box-foto {
        width: 200px;
        height: 200px;
        img {
          width: 100%;
          -webkit-filter: grayscale(1);
          filter: grayscale(1);
          -webkit-transition: filter 0.2s, -webkit-filter 0.2s;
          -moz-transition: filter 0.2s, -webkit-filter 0.2s;
          -ms-transition: filter 0.2s, -webkit-filter 0.2s;
          -o-transition: filter 0.2s, -webkit-filter 0.2s;
          transition: filter 0.2s, -webkit-filter 0.2s;

        }
      }
      .team-people-box-position {
        padding: 10px 0;
        width: 200px;
        height: auto;
        h1 {
          margin: 0;
          font-size: 16px;
        }
        p {
          font-size: 12px;
          margin: 7px 0;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      &:hover {
        .team-people-box-foto img {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
        }
      }
    }
  }
}

/* game - угадай авто */
.static-text {
  font-size: 15px;
  span {
    font-size: 16px;
    font-weight: bold;
  }
}

.answer {
  div {
    margin: 0 0 5px 0;
    label {
      font-size: 20px;
      padding: 0 0 0 7px;
      font-weight: normal;
    }
  }
}

.next-question {
  background: #f9cd1b;
  border: 3px solid #5c5c5c;
  color: #333333;
  font-size: 16px;
  cursor: pointer;
  padding: 3px 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ProximaNova, serif;
  font-weight: bold;
  font-style: normal;
}

.next-question:hover {
  background: #fff;
}

.for-game {
  width: 940px;
  overflow: hidden;
  margin: 30px 0 0 0;
  position: relative;
  padding: 20px 0;
  border-top: 2px solid #b3b3b3;
  &:nth-child(4) {
    border: none;
  }
  .form-game {
    width: 300px;
    float: left;
    margin: 0 20px 0 0;
    .watched-badge-game {
      background-color: #c6c6c6;
      top: 20px;
      color: #FFF;
      float: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.3em;
      overflow: hidden;
      padding: 2px 4px;
      position: absolute;
      left: 220px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      z-index: 99;
    }
    .watched-badge-main-game {
      top: 0;
      width: 300px;
      height: 100%;
      position: absolute;
      opacity: 0.7;
      background: #989898;
    }

    a {
      cursor: pointer;
      img {
        width: 300px;
      }
      .title {
        position: absolute;
        top: 150px;
        color: #308fed;
        padding: 0 0 0 10px;
        h1 {
          font-size: 27px;
        }
      }
    }
  }
  .form-game-info {
    .form-game-info-text {
      width: 100%;
      height: auto;
      h1 {
        font-size: 23px;
        margin: 0px 0 10px 0;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
      span {
        font-size: 16px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
        p {
          color: #4a4a4a;
          font-size: 15px;
          margin: 8px 0 0 0;
        }
      }
      .event-tag-news {
        .tag-news {
          margin: 15px 0 0 0;
          .tag-news-left {
            width: 50px;
            float: left;
            margin-right: 10px;
          }
          .tag-news-right {
            margin-left: 70px;
            .all-tag-news {
              float: right;
              cursor: pointer;
              font-size: 14px;
            }
          }
        }
      }
    }
    .button-game {
      right: 65px;
      float: right;
      margin: 15px 0 10px 0;
      a:hover {
        cursor: pointer;
        background: #fff;
      }
      a {
        border: 2px solid #4a4a4a;
        font-size: 17px;
        padding: 3px 11px;
        color: #4a4a4a;
        background: #308fed;
        font-weight: bold;
      }
    }
  }
  .no-rights {
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    background: #989898;
  }
}

.images-auto {
  img {
    width: 700px;
    margin: 0 0 20px 0;
  }
  h1 {
    font-size: 23px;
    color: #4a4a4a;
  }
}

.end-game {
  text-align: center;
  h1 {
    color: #4a4a4a;
    font-size: 34px;
    font-weight: bold;
  }
  h2 {
    font-size: 22px;
  }
  span {
    font-size: 16px;
    font-family: ProximaNova, serif;
    font-weight: normal;
    font-style: normal;
  }
}

/* Новий дизайн категорий*/
.news-avto-block {
  float: left;
  width: 100%;
  padding: 30px;
  position: absolute;
  margin: 0;
  background: #FFF;
  box-shadow: 0 0 20px 0 rgba(202, 209, 217, 0.75);
  .news-avto-block-img {
    float: left;
    margin: 0 30px 0 0;
    max-width: 230px;
    img {
      max-width: 100%;
    }
  }
  .news-avto-block-title {
    width: 100%;
    margin: -4px 0 0;
    padding: 0 0 0 260px;
    line-height: 27px;
    font-size: 22px;
    a {
      color: #222222;
      font-weight: bold;
    }
    .news-avto-block-title-text {
      a {
        color: #222222;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .news-avto-block-content {
    padding-left: 260px;
    span {
      font-size: 18px;
      line-height: 27px;
      &.posts-block {
        margin-top: 5px;
        display: inline-block;
        padding: 3px 16px;
        background: #FFFBEB;
        margin-left: 4px;
        border-radius: 2px;
        &:hover {
          background-color: rgba(255, 251, 235, 0.9);
        }
      }
      a {
        color: #5287c1;
        text-decoration: underline;
		 transition: all .3s ease; 
        &:hover {
			color: #e91010;
			text-decoration-color: #e91010;
        }
        &.text {
          color: #333;
          text-decoration: none;
        }
        &.posts {
          text-decoration: none;
        }
        &.all-posts {
          text-decoration: none;
          color: #282828;
        }
      }

      ul {
        margin: 10px 0 0 20px;
        list-style: circle;
        color: rgba(40, 40, 40, 0.2);
      }
    }
  }
  /*.enter {
    right: 0;
    bottom: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: absolute;
    background: #bcd6f8;
    img {
      position: absolute;
      right: 0;
      margin: 12px 0 0 12px;
    }
  }
  .enter-arrow {
    right: 11px;
    bottom: 12px;
    position: absolute;
  }*/
}

.quest-button {
  right: 0;
  color: #4A4A4a;
  font-size: 17px;
  font-weight: bold;
  position: absolute;
  padding: 3px 11px;
  background: #308FED;
  margin: 30px 0 0 0;
  border: 2px solid #4a4a4a;
}

.pswp--open {
  display: block;
  z-index: 10000;
}

.fixed-hint {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  font-weight: normal !important;
  .loader-hint {
    width: 100%;
    height: 200px;
    position: absolute;
    background: url('/images/loading.gif') no-repeat center, #FFF;
  }
}

.no-mobile {
  display: inline;
}

/*------------------------@Media - для головна --------------------------------*/

@media screen and (max-width: 1440px) {
  .content-main-index {
    width: 1170px;
    padding-bottom: 240px;
  }

  .content-index {
    .content-index-left {
      width: 780px;
    }
  }

  .category-items {
    &.absolute-type {
      width: 780px;
    }
  }
}

/*------------------------@Media - для головна --------------------------------*/

@media screen and (max-width: 1199px) {


  /*content-main*/
  .desktop-block {
    display: none;
  }
  .tablet-block {
    display: block;
  }
  .popular-post-block {
    margin-bottom: 30px;
    .post {
      &:last-child {
        display: none;
      }
    }
  }
  .content-main-index {
    width: 980px;
    padding-bottom: 258px;
  }

  .content-index {
    .content-index-left {
      width: 590px;
    }
  }

  .category-items {
    &.absolute-type {
      width: 590px;
    }
  }

  .menu-block {
    &, li, > div {
      &.desktop {
        display: none;
      }
      &.tablet {
        display: block;
      }
    }
  }

  /* блок категория*/
  .content-index-left-text-new {
    overflow: hidden;
  }

  /*Заголовок статї*/
  .content-index {
    .index-post-title {
      .title-center {
        width: 980px;
        overflow: hidden;
        &:before {
          width: 980px;
        }
      }
    }
  }

  /*подробно про категории*/
  .content-index-left {
    .content-index-categori {
      .content-index-left-foto-news {
        width: 300px;
        ul {
          li:nth-child(3) {
            display: none;
          }
          li:nth-child(4) {
            display: none;
          }
          li {
            .post-avatar {
              margin: 0 0 20px 0
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 993px) {
  .news-avto-block {
    .news-avto-block-content {
      padding: 0;
    }
  }

  .table {
    &.main-tag-table {
      display: block;
      .table-cell {
        display: block;
        &.other-tag-table {
          display: table;
          width: 100%;
        }
      }
    }
  }
  .other-table {
    width: 50%;
    display: table-cell;
    &.padding-bottom {
      padding-bottom: 0;
      padding-right: 15px;
    }
    &.padding-top {
      padding-top: 0;
      padding-left: 15px;
    }
  }

  .tag-children-list {
    .types-list-blocks {
      position: relative;
      bottom: auto;
      overflow: hidden;
      li {
        float: none;
        width: auto;
        margin: 0 15px;
        &:not(:first-child) {
          margin-top: -4px;
        }
        &:after {
          content: '';
        }
      }
    }
  }
}

/*------------------------@Media - для головна --------------------------------*/

@media screen and (max-width: 992px) {

  .index-side-banner {
    height: 0;
    width: 0;
  }

  html {
    overflow: inherit;
  }

  /*меню */
  .no-little-tablet {
    display: none;
  }
  .little-tablet {
    display: block;
  }

  .menu-index-logo {
    width: 210px;
    a {
      margin-left: 15px;
    }
  }

  .content-index {
    .content-index-left {
      width: 100%;
    }
    .content-index-right {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .category-items {
    &.absolute-type {
      width: 100%;
    }
  }

  .menu-block {
    .menu-block-hamburger {
      display: none;
      position: absolute;
      top: 100%;
      left: -210px;
      z-index: 1;
      margin-top: -3px;
      padding: 0;
      border-color: #FFCA00;
      border-style: solid;
      border-width: 3px 0 0;
      background: #FFF;
      width: 100vw;
      &.footer-hamburger {
        top: auto;
        bottom: 100%;
        border-width: 0 0 3px;
      }
      &:hover, &:focus {
        display: block;
      }
      li {
        line-height: 54px;
        display: block;
        border-bottom: 1px solid #F5F5F5;
        width: 100%;
        a {
          padding: 0 35px;
        }
      }
    }
    li, > div {
      &.menu-mobile {
        display: block;
        &:hover, &:focus {
          + .menu-block-hamburger {
            display: block;
          }
        }
      }
      &.no-little-tablet {
        display: none;
      }
      &.little-tablet {
        display: block;
      }
    }
  }

  .title-block {
    &.block-right {
      padding-left: 0;
      &:before {
        left: -30px;
      }
    }
  }

  .commenting-block {
    border-color: #F7811D;
	  margin-top: 20px;
  }

  /*content-main*/
  .content-main-index {
    width: 100%;
    padding-bottom: 263px;
  }

  .footer-index {
    padding-left: 15px;
    padding-right: 15px;
    .text {
      padding-left: 15px;
      height: auto;
    }
    .link-block {
		z-index: 1;
		float: none;
		display: block;
    }
  }

  .tag-children-list {
    margin: -15px -30px 0;
  }

  /*Заголовок статї*/
  .content-index {
    .index-post-title {
      width: 100%;
      overflow: hidden;
      .title-center {
        width: 100%;
        &:before {
          width: 100%;
        }
        img {
          margin: 0 -35%;
        }
      }
    }
  }

  /*пост*/
  .content-main-post {
    .content-main-post-form {
      width: 100%;
      .content-main-post-text {
        float: none;
        width: 100%;
        .index-post {
          float: none;
          width: 100%;
          p {
            margin: 0 0 10px 0;
            &:not([style="margin: 0px;"]) + ol {
              margin-top: 0;
            }
          }
          span {
            display: none;
          }
        }
        #div-gpt-ad-1466174275524-0, #div-gpt-ad-1466174275524-1 {
          display: none;
        }
      }
      .social-block-on-980 {
        margin: 10px 0 0 0;
        display: block;
        height: 46px;
        & > div {
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          position: absolute;
          & > div {
            max-width: 350px;
            margin: 0 auto;
            ul {
              width: 100%;
              li {
                width: 20%;
                .sn-icon {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
      .comments {
        margin: 0;
        max-width: 100%;
        .c-title {
          font-size: 18px;
        }
        .c-form {
          textarea {
            max-width: 100%;
            min-width: 100%;
            resize: vertical;
            border: 1px solid;
          }
          .c-focus-only {
            display: block;
            width: 100%;
            padding: 6px;
            margin: 0 auto;
            max-width: 450px;
          }
        }
      }
    }
    .content-main-post-banner {
      display: none;
    }
  }

  /*вивод игры*/
  .for-game {
    width: 630px;
    .form-game {
      width: 200px;
      a {
        img {
          height: 123px;
          width: 200px;
        }
      }
      .watched-badge-game {
        left: 140px !important;
      }
      .watched-badge-main-game {
        width: 200px;
        height: 123px;
      }
    }
    //    .form-game-info{
    //      width: 400px;
    //    }
  }

  /*показ фото играть*/
  .images-auto {
    img {
      width: 630px;
    }
  }

  /*подробно про категории*/
  .content-index-left {
    .content-index-categori {
      .content-index-left-foto-news {
        width: 300px;
        ul {
          li:nth-child(3) {
            display: none;
          }
          li:nth-child(4) {
            display: none;
          }
          li {
            .post-avatar {
              margin: 0 0 20px 0
            }
          }
        }
      }
    }
  }
}

/*------------------------@Media - для головна --------------------------------*/

@media screen and (max-width: 767px) {

  /*content-main*/
  .no-mobile-block, .no-mobile {
    display: none;
  }
  .mobile-block {
    display: block;
  }

  .vertical-post {
    &.big-slider-post {
      a {
        .text-post {
          bottom: -5px;
        }
      }
    }
  }

  .content-main-index {
    padding-bottom: 317px;
  }

  .footer-index {
    .text {
      height: auto;
    }
  }
  .content-index {
    .index-post-title {
      .title-center {
        .index-post-into-title h1 {
          font-size: 28px;
        }
        .about-title-transition {
          display: none;
        }
      }
    }
  }

  .index-post {
    .bb-image:not(.bb-image-align-left):not(.bb-image-align-right), .bb-video, .contents {
      margin-left: -30px;
      margin-right: -30px;
    }
    .ab-warning, .ab-danger, .ab-tip, .ab-info, .ab-definition {
      padding: 90px 25px 20px;
      background-position: center 25px;
    }
    .contents {
      margin-left: -40px;
      ul {
        margin-left: 15px;
        li {
          a {
            padding-left: 15px;
          }
        }
      }
    }
    ul, ol {
      .bb-image:not(.bb-image-align-left):not(.bb-image-align-right), .bb-video {
        margin-left: -70px;
      }
    }
    .review-block {
      margin: 30px -30px;
      .author-block {
        padding: 30px;
        img {
          display: none;
        }
      }
    }
	  h1, h2, h3, h4, h5, h6 {
		  word-break: break-word;
	  }
  }
  .fixed-hint {
    margin: 0 -30px;
    width: 100vw;
  }
  .block-poll-block {
    margin: 30px -30px!important;
  }
  .poll-text {
    display: block;
  }
  .poll-title {
    font-size: 14px;
    line-height: 13px;
    margin-bottom: 5px;
    padding: 0 20px;
    &.no-title {
      font-weight: bold;
      div {
        margin-top: 6px;
      }
    }
  }
  .poll-button {
    display: block;
    float: left;
    width: auto;
    padding: 0 15px;
  }
  #poll-yes {
    margin-left: 5px;
  }
  .text-poll-block > div {
    height: auto;
  }
  .count-positive {
    padding: 0 20px 0 15px;
    &.hidden-mobile {
      display: none;
    }
  }
  .count-help-post {
    display: none;
  }
  .social-likes {
    padding-right: 0;
  }
  .social-likes__button {
    width: 24px;
    height: 24px;
  }
  .social-likes__counter {
    display: none;
  }

  .table {
    &.main-tag-table {
      display: block;
      .table-cell {
        display: block;
        &.other-tag-table {
          display: table;
          width: 100%;
        }
      }
    }
  }
  .other-table {
    width: 50%;
    display: table-cell;
    &.padding-bottom {
      padding-bottom: 0;
      padding-right: 15px;
    }
    &.padding-top {
      padding-top: 0;
      padding-left: 15px;
    }
  }

  .tag-children-list {
    .types-list-blocks {
      position: relative;
      bottom: auto;
      overflow: hidden;
      li {
        float: none;
        width: auto;
        margin: 0 15px;
        &:not(:first-child) {
          margin-top: -4px;
        }
        &:after {
          content: '';
        }
      }
    }
  }
	.table-block {
		overflow: auto;
	}
}


.position-content-main-post {
  z-index: 0;
}

/*--------------------Little Media----------------------------*/
@media screen and (max-width: 640px) {
  .news-avto-block {
    .news-avto-block-img {
      float: none;
      margin: 0 0 20px;
      max-width: none;
      text-align: center;
    }
    .news-avto-block-title {
      padding: 0;
    }
    .news-avto-block-content {
      padding: 0;
    }
  }

  .index-post {
    .bb-image {
      &.bb-image-align-left, &.bb-image-align-right {
        float: none;
        text-align: center;
        width: 300px;
        padding: 0;
        margin: 30px auto;
      }
    }

    .answer-table-block {
      display: block;
      tbody {
        display: block;
        tr {
          display: block;
          padding: 4px 15px;
          &:not(:last-child) {
            border-bottom: 1px solid #F0F7F7;
          }
          td {
            display: block;
            width: 100%;
            padding: 0;
            border: none;
            &:first-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .category-items {
    .category-item-block {
      width: 50%;
    }
  }
}

@media screen and (max-width: 500px) {
  .no-little-mobile {
    display: none;
  }
  .little-mobile {
    display: block;
  }

  .menu-index-logo {
    width: 70px;
    span {
      display: none;
    }
  }

  .menu-block {
    .menu-block-hamburger {
      left: -70px;
    }
    li, > div {
      &.auth {
        width: 210px;
      }
    }
  }

  .vertical-post {
    &.slider-post, &.big-slider-post {
      margin: 0 -30px 35px;
    }
  }

  .horizontal-post {
    &.post-bok {
      a {
        img {
          width: 100%;
          height: auto;
        }
        .text-post {
          margin-top: 20px;
          padding: 0;
          text-align: left;
          > div {
            .text {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  .content-main-index {
    padding-bottom: 331px;
  }

  .footer-index {
    .text {
      height: auto;
      font-size: 10px;
      color: #969696;
      line-height: 16px;
    }
  }

  .index-post {
    .bb-image {
      &.bb-image-align-left, &.bb-image-align-right {
        width: auto;
      }
    }
  }

  .main-tags-block {
    li {
      width: 33.334%;
    }
  }

  .table {
    &.main-tag-table {
      .table-cell {
        &.other-tag-table {
          display: block;
        }
      }
    }
  }
  .other-table {
    display: block;
    width: 100%;
    &.padding-bottom, &.padding-top {
      padding-bottom: 15px;
      padding-right: 0;
    }
    &.padding-top {
      padding-top: 15px;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 420px) {
  .main-tags-block {
    li {
      width: 50%;
    }
  }

  .category-items {
    .category-item-block {
      width: 100%;
    }
  }
}

@media screen and (max-width: 379px) {
  .content-main-index {
    padding-bottom: 411px;
  }

  .footer-index {
    .text {
      height: auto;
    }
  }

  .menu-block {
    li, > div {
      &.auth {
        width: 185px;
        .avatar-link {
          padding: 15px;
        }
        .avatar-block {
          padding-right: 50px;
        }
      }
    }
  }

  .index-post {
    .bb-image.bb-image-align-left, .bb-image.bb-image-align-right {
      margin: 30px -30px;
      padding: 0;
      max-width: none;
      float: none;
    }
    .contents {
      .title-block.comments {
        > div {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 319px) {
  .menu-block {
    li, > div {
      &.search, a {
        padding: 0 10px;
        .last {
          margin: 0 0 0 10px;
        }
      }
      &.auth {
        width: 146px;
        .avatar-link {
          padding-left: 10px;
        }
        .avatar-block {
          padding-right: 45px;
        }
      }
    }
  }

  .content-main-index {
    padding-bottom: 344px;
  }

  .footer-index {
    .link-block {
      float: none;
    }
    .text {
      padding-left: 15px;
      padding-top: 15px;
      height: 159px;
    }
  }
}

/*--------------------Event-update-slider----------------------------*/
.event-update {
  UL.mytabs {
    position: relative;
    z-index: 2;
  }
  UL.mytabs, UL.mytabs LI {
    margin: 0;
    padding: 0;
    list-style: none;
    float: left;
  }
  UL.mytabs LI {
    padding: 0 5px;
  }
  UL.mytabs LI A {
    float: left;
    padding: 7px;
    border: 1px solid #CCCCCC;
    border-bottom: 1px solid #E0E0E0;
    background: #F0F0F0;
    text-decoration: none;
    color: #333333;
    //height: 22px;
  }
  UL.mytabs LI A:HOVER, UL.mytabs LI.current A {
    background: #FFFFFF;
  }
  UL.mytabs LI.current A {
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #FFFFFF;
  }
  .mytabs-container {
    position: relative;
    z-index: 1;
    clear: both;
    border: 1px solid #E0E0E0;
    padding: 10px;
    top: 0;
    background: #fff;
  }
}

/*------------------------Banners----------------------*/
.index-image-banner {
  position: relative;
  z-index: 5;
}
.index-image-banner span {
  display: none;
}
.index-image-banner.mini {
  display: none;
}
@media (max-width: 1900px) {
  .index-image-banner {
    display: none;
  }
  .index-image-banner.mini {
    display: block;
  }
}

/*--------Admin-popup-created-event------*/
.event-form {
  .one-text {
    padding: 25px 15px;
    margin-bottom: 20px;
    border: 2px solid maroon;
    p {
      font-size: 27px;
      text-align: center;
      font-weight: normal;
      padding-bottom: 15px;
      border-bottom: 2px solid maroon;
    }
  }
}
.insert-social-image  {
  width: 204px;
  min-height: 134px;
  border: 2px solid;
}
.insert-logo-image   {
   width: 204px;
   min-height: 134px;
   border: 2px solid;
}
.insert-main-image  {
    width: 204px;
    min-height: 134px;
    border: 2px solid;
}