::selection {
  background: #c6c6c6; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #c6c6c6; /* Gecko Browsers */
}


/*----------------------------Головна сторінка-----------------------------------*/
.content-index {
  padding: 30px 30px 0;
}

.popular-posts {
  margin: -30px -30px 0;
  padding: 25px 30px;
  background-color: rgba(255, 202, 0, 0.1);
}

.block-title-block {
  padding-bottom: 35px;
  .title-block {
    margin: 0;
  }

  .block-after-title {
    margin-top: 20px;
  }

  .comment-block-title {
    float: right;
    padding-left: 23px;
    font-size: 14px;
    line-height: 22px;
    background: url('/images/agronomu/comment-dark.png') no-repeat 0 center;
    a{
      color: #282828;
      &:hover{
        color: #e91010;
      }
    }
    &.no-background {
      background: none;
    }
    &:not(:last-child) {
      margin-left: 20px;
    }
  }
}

.title-block {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 24px;
  color: #282828;
  margin-top: 2px;
  margin-bottom: 30px;
  font-weight: 600;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: 2px;
    height: 20px;
    background: #E4512F;

  }
  &.popular {
    font-size: 18px;
    &:before {
      background: #FFCA00;
    }
  }
  &.comments {
    padding: 0;
    margin: 8px 0 30px;
    &:before {
      content: none;
    }
  }
  &.block-right {
    padding-left: 15px;
    &:before {
      left: 0;
    }
  }
  .count-posts {
    display: block;
    padding-top: 5px;
    font-size: 12px;
    color: #bec0c2;
  }
}

h1 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 800;
  &.title-block {
    font-size: 36px;
    line-height: 1.2;
    height: auto;
    &:before {
      top: 11.6px;
    }
  }
}

.title-dacha {
  display: block;
  font-size: 24px;
  color: #282828;
  text-align: center;
  margin: 0 0 20px;
  font-weight: 600;
  line-height: 1.2;
}

.post {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.horizontal-post {
  &:not(:last-child) {
    margin-bottom: 32px;
  }
  a {
    position: relative;
    display: block;
    img {
      max-width: 100%;
      border-radius: 2px;
      float: left;
    }
    .text-post {
      padding-left: 85px;
      > div {
        display: table;
        width: 100%;
        height: 67px;
        > span {
          display: table-cell;
          vertical-align: top;
          line-height: 20px;
          font-size: 16px;
          color: #282828;
          &.table-text {
            vertical-align: middle;
          }
          .category {
            font-size: 12px;
            color: #778ca3;
          }
        }
      }
    }
    &:hover, &:focus {
      .text-post {
        > div  {
          > span {
            color: #e91010;
            .category {
              color: rgba(233, 16, 16, 0.6);
            }
          }
        }
      }
    }
  }
  &.little-post {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    a {
      .text-post {

        padding-left: 65px;
        > div {
          height: 50px;
          > span{
            font-size: 12px;
            line-height: 1.2;
            color: #191919;
          }
        }
      }
      &:hover, &:focus {
        .text-post {
          > div {
            > span {
              color: #e91010;
            }
          }
        }
      }
    }
  }
  &.post-bok {
    margin-bottom: 32px;
    a {
      img {
		width: 164px;
		height: 135px;
		@media all and (max-width: 767px) {
			width: 100%;
			height: auto;
			margin-bottom: 15px;
		}
      }
      .text-post {
        padding-left: 195px;
        > div {
          height: 135px;
			@media all and (max-width: 767px) {
				height: auto;
			}
          > span {
            line-height: 1.2;
            max-width: 500px;
            margin: 0 auto 32px;
            font-size: 24px;
            font-weight: 600;
            .text {
              font-weight: 400;
              padding-top: 20px;
              line-height: 24px;
              font-size: 14px;
              color: #606060;
				@media all and (max-width: 767px) {
					display: none;
				}
            }
          }
        }
		  @media all and (max-width: 767px) {
			  padding-left: 0px;
		  }
      }
      &:hover, &:focus {
        .text-post {
          > div {
            color: #e91010;
          }
        }
      }
    }
  }
}

.vertical-post {
  a {
    position: relative;
    display: block;
    color: #282828;
    .image-post {
      position: relative;
      > img {
        width: 100%;
        border-radius: 2px;
      }
      .comment-count {
        position: absolute;
        bottom: 8px;
        left: 8px;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 6px;
        background: #FFCA00;
        color: #FFF;
        border-radius: 2px;
        img {
          margin-right: 6px;
        }
      }
    }
    .text-post {
      width: 100%;
      margin-top: 20px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      color: #282828;
      .category {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &.popular {
    padding-bottom: 20px;
    a {
      background: #F7FAFA;
      border-radius: 2px;
      padding: 12px 12px 0;
      .text-post {
        margin-top: 5px;
        line-height: 18px;
        font-weight: 400;
        display: table;
        height: 90px;
        width: 100%;
        .text {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
  &.dacha-post {
    a {
      .image-post {
        position: relative;
        .image-block {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5) url('/images/agronomu/dacha-post.png') no-repeat center;
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }
    }
  }
  &.related-new-block {
    a {
      .image-post {
        position: relative;
        .image-block {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: -moz-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
          background-image: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
          background-image: -ms-linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
      .text-post {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        padding: 0 5px;
        color: #FFF;
        line-height: 1.2;
        font-size: 18px;
        margin: 0;
        text-align: center;
      }
      &:hover, &:focus {
        .text-post {
          > div {
            color: #f5f5f5;
          }
        }
      }
    }
  }
  &.slider-post, &.big-slider-post {
    position: relative;
    margin-top: 45px;
    a {
      .text-post {
        position: absolute;
        bottom: 30px;
        left: 7.5%;
        width: 85%;
        padding: 15px 20px 10px;
        color: #FFF;
        line-height: 28px;
        margin: 0;
        text-align: center;
        .text {
          text-decoration: underline;
          font-size: 24px;
        }
      }
      &:hover, &:focus {
        .text-post {
          > div {
            color: #f5f5f5;
          }
        }
      }
    }
    &.first {
      a {
        .text-post {
          background-color: rgba(255, 178, 148, 0.9);
        }
      }
    }
    &.second {
      a {
        .text-post {
          background-color: rgba(206, 222, 189, 0.9);
        }
      }
    }
    &.third {
      a {
        .text-post {
          background-color: rgba(189, 195, 222, 0.9);
        }
      }
    }
  }
  &.big-slider-post {
    a {
      .text-post {
        bottom: 30px;
        text-align: left;
        left: 3.75%;
        width: 92.5%;
      }
    }
  }
  &.tag-post-breed {
    a {
      .text-post {
        text-align: center;
        font-size: 18px;
        line-height: 1.2;
        margin-top: 25px;
      }
    }
  }
}

.popular-bok {
  padding: 45px 0 5px;
}
.boks {
  padding-bottom: 3px;
  padding-top: 25px;
}
.dacha-block {
  margin: 0 -30px;
  padding: 20px 30px 0;
  background-color: rgba(208, 223, 191, 0.1);
  .vertical-post {
    padding-bottom: 20px;
  }
}
.commenting-block {
  padding: 20px 15px 6px;
  border: 2px solid #FADDD7;
  border-radius: 3px;
  margin: 0 auto 32px;
  &.normal {
    border-color: #F7811D;
  }
  .little-block-post {
    margin-bottom: 14px;
  }
}
.news {
  margin: 0 -30px;
  padding: 5px 30px;
  border-top: 1px solid #F5F5F5;
  background-color: #FAFCFC;
  .horizontal-post {
    padding: 25px 0;
    a {
      .text-post {
		  padding-left: 0px;
        > div {
          font-size: 14px;
          line-height: 1.2;
          font-weight: 600;
          position: relative;
          padding-bottom: 16px;
          .time-post {
			color: #a4a9ae;
			font-size: 12px;
			font-weight: 400;
			position: relative;
			margin-top: 5px;
			bottom: 0;
			left: 0;
            &:before {
              content: '•';
              margin-right: 15px;
            }
          }
        }
      }
		img {
			width: 100%;
			float: none;
			margin-bottom: 15px;
		}
    }
  }
}

/*-----------------------Сторінка новостей-------------------------*/
.count-themes {
  line-height: 20px;
  display: inline-block;
  margin-top: 2px;
  color: #bec0c2;
  font-weight: 600;
}

.category-items {
  padding: 15px 12px;
  background: #FCFBF8;
  border-radius: 2px;
  margin-bottom: 30px;
  &.alphabet-type {
    background: #FFFDF4;
    padding: 20px;
  }
  &.chars-type {
    background: #FCF6DF;
    padding: 20px;
  }
  &.absolute-type {
    position: absolute;
    bottom: 30px;
    z-index: -1;
    width: 933px;
    &.fixed {
      position: fixed;
      z-index: 5;
      top: 0;
      bottom: auto;
    }
  }
  .category-item-block {
    width: 33.334%;
    float: left;
  }
  .category-item-link {
    padding: 10px;
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    color: #282828;
    border-radius: 2px;
    &.active, &:hover, &:focus {
      background: rgba(255, 202, 0, 0.1);
    }
  }
  .title-type {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 25px;
    color: #282828;
  }
}

.botton-banner {
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 10px 0 0 0;
}
.content-index-categori {
  margin: 0 0 20px 0;
  .content-index-left-text {
    float: left;
    width: 300px;
    min-height: 1px;
    padding: 0 15px  0 0;
    .simple-in-page-news
    {
      width: 300px;
      font-size: 16px;
      position: relative;
      margin: 0 0 10px 0;
      padding: 0 0 0 24px;
      display: inline-block;
      font-family: ProximaNova, serif;
      font-weight: normal;
      font-style: normal;
      .important {
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
      .time{
        color: #ccad14;
        font-size: 14px;
        padding: 0 10px 0 0;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      div{
        width: 214px;
        display: inline-flex;
      }
      a {
        color: #222222;
        cursor: pointer;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      a {
        &.test-red-title{
          color: #c6c6c6;
        }
      }
      &:before {
        content: " ";
        left: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        background: #edecec;
        border-radius: 10px;
      }
    }
    .show-more-categori{

      width: 100%;
      height: 50px;
      cursor: pointer;
      text-align: center;
      background: #308fed;
      margin: 0 0 20px 0;
      padding: 16px 0 15px 0;
      a{
        color: #3d3d3d;
        font-size: 16px;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
        text-transform: uppercase;
      }
      img{
        margin: -4px 0 0 0;
      }
    }
  }
  .content-index-left-foto-news {
    float: left;
    height: auto;
    width: 622px;
    margin: 10px 0 0 20px;
    ul{
      list-style-type: none;
    }
    .categori-text{
      margin: 20px 0 20px 0;
      span{
        color: #222222;
        font-size: 18px;
        line-height: 24px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      .show-more-categori{
        width: 300px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        margin: 20px 0 0 0;
        background: #308fed;
        padding: 16px 0 15px 0;
        a{
          color: #3d3d3d;
          font-size: 16px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
          text-transform: uppercase;
        }
        img{
          margin: -4px 0 0 0;
        }
      }
    }
  }
}

/*---------------------  сторінка тегів  -------------------*/
.tag-children-list {
  position: relative;
  padding-bottom: 30px;
  margin-top: -15px;
  .category-items {
    margin: 0;
  }
  .types-list-blocks {
    position: absolute;
    bottom: 100%;
    right: 0;
    list-style: none;
    margin: 0;
    li {
      float: left;
      display: block;
      width: 195px;
      height: 55px;
      position: relative;
      &:last-child {
        border-top-right-radius: 2px;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -15px;
        border-left: 15px solid transparent;
        border-bottom: 55px solid #000;
      }
      &:after {
        position: absolute;
        top: 0;
        right: -15px;
        border-right: 15px solid transparent;
        border-bottom: 55px solid #000;
      }
      &.category-block-list {
        background: #FCFBF8;
        &:before, &:after {
          border-bottom-color: #FCFBF8;
        }
      }
      &.alphabet-block-list {
        background: #FFFDF4;
        &:before, &:after {
          border-bottom-color: #FFFDF4;
        }
      }
      &.chars-block-list {
        background: #FCF6DF;
        &:before, &:after {
          border-bottom-color: #FCF6DF;
        }
      }
      .list-block-type {
        display: table;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #282828;
        padding-right: 20px;
        .table-cell {
          display: table-cell;
          vertical-align: middle;
        }
        .image {
          text-align: center;
          width: 70px;
          padding-right: 5px;
        }
      }
    }
  }
}

.char-title {
  padding: 30px 15px;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  color: #282828;
}

.alphabet-block-char {
  margin-bottom: 5px;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  background: #ffca00;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  &:hover, &:focus {
    color: #ffffff;
  }
  &.no-active {
    background: #A5A5A5;
    cursor: not-allowed;
  }
}

.form-group {
  margin-bottom: 30px;
  position: relative;
  color: #646464;
  input, .text-input-block {
    line-height: 40px;
    border-radius: 2px;
    background: #FFF;
    border: 1px solid #ECE4C6;
    font-size: 14px;
    width: 100%;
    padding-left: 15px;
  }
  .text-input-block {
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 20px;
      width: 10px;
      height: 100%;
      background: url('/images/agronomu/select-arrow.png') no-repeat center;
    }
  }

  .select-block {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #FFF;
    border: 1px solid #ECE4C6;
    border-radius: 2px;
    margin-top: -1px;
  }

  .checkbox {
    label {
      cursor: pointer;
      display: block;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 15px 6px 40px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        background: #FFF;
        border: 1px solid #E2E6ED;
        border-radius: 2px;
      }
    }
    &:first-child {
      label {
        padding-top: 15px;
      }
    }
    &:last-child {
      label {
        padding-bottom: 15px;
      }
    }

    input[type="checkbox"] {
      display: none;
      &:checked+label {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 16px;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          background: url('/images/agronomu/active-checkbox.png');
        }
      }
    }
  }
}

.filters-block {
  margin-bottom: 20px;
  border: 1px solid #E2E6ED;
  border-radius: 2px;
  padding: 10px 190px 10px 10px;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  height: 50px;
  .remove-filter {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 170px;
    padding: 6px 10px;
    background: #FFCA00;
    border-radius: 2px;
    color: #FFF;
    cursor: pointer;
    text-align: center;
  }
  .filter-block {
    padding: 6px 10px;
    float: left;
    background: #FCFBF8;
    border-radius: 2px;
    color: #646464;
    &:not(:last-child) {
      margin-right: 10px;
    }
    .close-filter {
      cursor: pointer;
      padding: 14px 10px;
      margin: -10px -10px -10px 0;
    }
  }
}


/*------   під адмінку  ------*/
ul
{
  &.inline-list
  {
    display: inline;
    li
    {
      float:left;
      margin: 6px;
      display: inline;
      cursor: pointer;
    }
  }
}
/*------   сеню в категориях 4 иконки рразом  ------*/
ul
{
  padding: 0;
  &.inline-list-news
  {
    display: inline;
    li
    {
      margin: 0;
      float:left;
      display: inline;
      cursor: pointer;
      &:before {
        margin: 79px 0 0 0;
        content: '';
        width: 300px;
        height: 106px;
        position: absolute;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0); /* IE6-9 */
      }
      .title-name {
        height: 30px;
        background: #308fed;
        position: absolute;
        margin: -185px 0 0 0;
        padding: 6px 0 0 0;
        text-transform: uppercase;
        span {
          a{
            color: #333333;
            font-size: 13px;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
            margin: 0 0 0 5px;
            &:before {
              content: '';
              top: 0;
              width: 0;
              height: 0;
              right: -30px;
              position: absolute;
              border-top: 30px solid #308fed;
              border-right: 30px solid transparent;
            }
          }
        }
      }
      .title-banner {
        width: 300px;
        margin: -140px 0 0 0;
        position: absolute;
        h2{
          padding: 0 0 0 15px;
          display: table-cell;
          vertical-align: bottom;
          height: 105px;
          text-shadow: none;
          a {
            margin: 0;
            color: #fefefe;
            font-size: 16px;
            padding: 0 0 0 0;
            text-shadow: none;
            font-family: ProximaNova, serif;
            font-weight: bold;
            font-style: normal;
          }
        }

        .block-banner {
          margin: 10px 0 20px 0;
          a {
            color: #cccaca;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
            img {
              margin: -2px 10px 0 15px;
            }
            p {
              color: #000000;
              font-size: 14px;
              display: inline;
              border-radius: 5px;
              background: #ffdf3f;
              padding: 0px 6px 0 6px;
              font-family: ProximaNova, serif;
              font-weight: bold;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
/*=-------меню категории------------*/
.memu-categori{
  width: 100%;
  height: 50px;
  .menu-ca{
    height: 50px;
    width: 1323px;
    margin: 0 auto;
    overflow: hidden;
    background: #308fed;
    -webkit-box-shadow: 0px 3px 11px 0px rgba(50,50,49,0.75);
    -moz-box-shadow: 0px 3px 11px 0px rgba(50,50,49,0.75);
    box-shadow: 0px 3px 11px 0px rgba(50,50,49,0.75);
    ul {
      padding: 0;
      margin: 15px 0 0 0;
      list-style-type: none;
      li:first-child:before {
        width: 0;
        content: '';
        height: 17px;
        position: absolute;
        background: #FFFFFF;
      }
      li:first-child {
        padding:  6px 15px 24px 0;
      }
      li {
        display: inline;
        text-decoration: none;
        padding: 6px 15px 24px 0;
        &:before {
          width: 1px;
          content: '';
          height: 22px;
          position: absolute;
          background: #6c6c6c;
        }
        a {
          color: #4a4a4a;
          font-size: 16px;
          cursor: pointer;
          padding: 0 0 0 15px;
          text-decoration: none;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
          &:hover {
            color: #575757;
          }
        }
      }
    }
  }
}
/*=------- Последние новости------------*/
.LatestArticles{
  float: left;
  width: 100%;
  height: auto;
  .LatestArticles-title{
    width: 100%;
    height: 50px;
    margin: 20px 0 0 0;
    background: #4a4a4a;
    h2{
      margin: 0;
      color: #308fed;
      font-size: 24px;
      text-shadow: none;
      padding: 10px 0 0 20px;
      font-family: ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
    }
  }
  .LatestArticles-content{
    width: 100%;
    height: 260px;
    ul{
      list-style-type: none;
      li:last-child{
        margin: 0;
      }
      li{
        margin: 0 15px 0 0;
        float: left;
      }
    }
  }
}
/*------------Cупер пупер--------------*/
.smol-foto-slider .post-avatar {
  position:relative;
}
.index-news .post-hover {
  display: none;
  position: relative;
}
.index-news .post {
  width: 300px;
}
.big-foto-slider .post-avatar {
  float: left;
  position:relative;
}
.index-news .post:hover {
  .post-hover {
    display: block;
    position: absolute;
    margin-top: -10px;
    margin-left: -10px;
    width: 320px;
    height: 330px;
//    overflow: hidden;
    z-index: 200;
    padding: 10px 10px 10px 10px;
    background-color: lightslategray;
  }
  .post-avatar {
    z-index: 300;
    .title-avatar {
      z-index: 400;
    }

  }
}
.post-avatar {
  position:relative;

  .post-avatar-n(@width, @height) {
    width: @width;
    height: @height;
    &:before {
      margin: 0.42*@height 0 0 0;
      width: @width;
      height: 0.58*@height;
    }
    .main-foto{
      width: @width;
      height: @height;
    }
    .title-text h2{
        height: @height - 40px;
    }
    .watched-badge-main{
      width: @width;
      height: @height;
    }
  }

  &.post-avatar-300 {
    .post-avatar-n(300px, 185px);
  }

  &.post-avatar-630 {
    .post-avatar-n(630px,370px);
    margin: 0 0 15px;
  }

  .main-foto{
    border-image: none;
  }
  &:before {

    content: '';
    position: absolute;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0)), color-stop(83%, rgba(0, 0, 0, 0.89)), color-stop(99%, rgba(0, 0, 0, 0.89))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.89) 83%, rgba(0, 0, 0, 0.89) 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#e3000000', GradientType=0); /* IE6-9 */
  }
  img{

  }
  .title-avatar{
    height: 30px;
    background: #308fed;
    position: absolute;
    top:0;
    padding: 6px 0 0 0;
    z-index: 100;
    text-transform: uppercase;
    span {
      a{
        color: #bcd6f8;
        cursor: pointer;
        font-size: 13px;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
        margin: 0 0 0 10px;
        &:before {
          content: '';
          top: 0;
          width: 0;
          height: 0;
          right: -30px;
          position: absolute;
          border-top: 30px solid #308fed;
          border-right: 30px solid transparent;
        }
      }
    }
  }
  .title-text{
    width: 100%;
//    margin: -140px 0 0 0;
    position: absolute;
    top: 0;
    h2{
      padding: 0 15px 0 15px;
      display: table-cell;
      vertical-align: bottom;

      line-height: 11pt;
      text-shadow: none;
      a {
        margin: 0;
        color: #fefefe;
        font-size: 16px;
        padding: 0 0 0 0;
        text-shadow: none;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
    }

    .block-banner {
      margin: 10px 0 20px 0;
      a {
        color: #cccaca;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        img {
          margin: -2px 10px 0 15px;
        }
        p {
          color: #000000;
          font-size: 14px;
          display: inline;
          border-radius: 5px;
          background: #ffdf3f;
          padding: 0px 6px 0 6px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
    }
  }
  .pere{
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
  }
}
.pere:focus, .pere:hover {
  text-decoration: none;
}
.post-hover {
  .ddd {
    color: #ffffff;
    font-size: 14px;
    padding: 186px 0 0 0;
    text-shadow: none;
    line-height: 1.2;
    text-align: justify;
    &:hover {
      text-decoration: none;
    }
  }
  .read-new {
    position: relative;
    cursor: pointer;
    width: 320px;
    height: 40px;
    float: none;
    text-align: center;
    background-color: #308fed;
    right: 10px;
    padding-top: 10px;
    p {
      color:#333333;
      font-size:16px;
      font-family:ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
    }
  }
}
/*-------Социальна прокутка ------*/
aside {
  display: inline-block;
  width: 300px;
  height: 240px;
}
.prilip {
  position: fixed;
  z-index: 101;
}
.stop {
  position: relative;
}
/*-------Блок просмотрено */
.watched-badge-main{
   top: 0;
   width: 300px;
   height: 185px;
   position: absolute;
   opacity: 0.5;
   background: #989898;

 }
.watched-badge {
  background-color: #c6c6c6;
  top: 20px;
  color: #FFF;
  float: right;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.3em;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  right: 15px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
/*----сторинка azs------*/
.azs{
  width: 100%;
  height: auto;
  position: relative;
  .min-fin{
    color: #222222;
    font-size: 14px;
    margin-top: 20px;
    text-align: right;
    font-family: ProximaNova, serif;
    font-weight: normal;
    font-style: normal;
  }
  .azs-title{
    width: 100%;
    clear: both;
    text-align: center;
    margin-bottom: 20px;
    #ui-datepicker-div{
      background: #000;
    }
    input{
      width: 160px;
      border: none;
      height: 35px;
      outline: none;
      color: #4a4a4a;
      font-size: 18px;
      cursor: pointer;
      text-align:center;
      border-radius: 7px;
      background: #f9cd1b;
      font-family: ProximaNova, serif;
      font-weight: bold;
      font-style: normal;
    }
  }
  .azs-content{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .azs-content-block-gas{
      float: left;
      width: 280px;
      height: 100%;
      margin: 0 40px 0 0;
      .title-in-widget-news-related{
        height: 30px;
        color: #222222;
        font-size: 24px;
        line-height: 27px;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
        background: url("/images/site/related-background.png");
        background-repeat: no-repeat;
      }
      .azs-block-data{
        height: 40px;
        width: 280px;
        position: relative;
        margin: 0 0 10px 0;
        background: #f3f3f1;
        border: 3px solid #5c5c5c;
        .title-area{
          float: left;
          width: 73px;
          cursor: pointer;
          margin: 8px 0 0 0;
          padding: 0 0 0 10px;
          border-right: 2px solid #e4e4e2;
          span{
            color: #3333;
            font-size: 18px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
        input{
          border: none;
          background: none;
          width: 200px;
          height: 32px;
          outline:none;
          font-size: 17px;
          font-weight: bold;
          color: #4a4a4a;
          cursor: pointer;
          padding: 5px 0 0 15px;
          background: url("/images/site/calendar.png");
          background-position: 94% 50%;
          background-repeat: no-repeat;
        }
      }
      .content-area{
        height: 40px;
        width: 280px;
        position: relative;
        background: #f3f3f1;
        border: 3px solid #5c5c5c;
        .title-area{
          float: left;
          width: 73px;
          cursor: pointer;
          margin: 8px 0 0 0;
          padding: 0 0 0 10px;
          border-right: 2px solid #e4e4e2;
          span{
            color: #333333;
            font-size: 16px;
            font-family: ProximaNova, serif;
            font-weight: normal;
            font-style: normal;
          }
        }
        .area{
          font-size: 20px;
          background: url("/images/point-in-area.png");
          background-position: 90% 50%;
          background-repeat: no-repeat;
        }
        .area-dropdown{
          ul{
            top: 40px;
            right: -3px;
            z-index: 100;
            width: 280px;
            background: #ccc;
            text-align: center;
            position: absolute;
            list-style-type: none;
            border: 2px solid #4a4a4a;
            li:hover{

            }
            li{
              float: left;
              width: 300px;
              height: 35px;
              cursor: pointer;
              padding: 8px 0 8px 0;
              a{
                color: #4a4a4a;
                font-size: 18px;
                font-family: ProximaNova, serif;
                font-weight: bold;
                font-style: normal;
              }
            }
          }
        }
      }
      .content-block-gas{
        width: 280px;
        height: auto;
        margin: 0 20px 0 0;
      }
    }
    .azs-content-block-price{
      float: left;
      width: 500px;
      height: auto;
    }
  }
  .dropdown{
    float: left;
    width: 600px;
    position: relative;
    //  background: #ccc;
    //  border: 2px solid #308fed;
    .title-dropdown{
      height: 27px;
      background: #f3f3f1;
      span{
        color: #3e3c35;
        font-size: 16px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
      }
      .title-right{
        height: 27px;
        float: right;
        padding: 4px 10px 0 0;
      }
      .title-left{
        float: left;
        height: 27px;
        padding: 4px 0 0 10px;
      }
    }
    .dropdown-refill:hover{
      cursor: default;
      background: #fefaea;
    }
    .dropdown-refill{
      height: auto;
      cursor: default;
      overflow: hidden;
      margin: 2px 0 0 0;
      position: relative;
      border-bottom: 2px solid #f3f3f1;
      .name-gas{
        border: none;
        width: 350px;
        height: auto;
        float: left;
        font-size: 20px;
        margin: 8px 0 8px 20px;
        a{
          color: #e20000;
          font-size: 15px;
        }
      }
      .prise{
        top: 30%;
        right: 0;
        text-align: center;
        position: absolute;
        .prise-gas{
          float: left;
          width: 100px;
          height: auto;
          border: none;
          font-size: 24px;
          position: relative;
          vertical-align: middle;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
    }
    div{
    }
  }
}
/*------меню цен -------*/
ul#menu {list-style-type:none; margin:0; padding:0;}
ul#menu li{
  width: 280px;
  height: 45px;
  background: #f3f3f1;
}
ul#menu li a{
  border:none;
  height: 45px;
  color:#4a4a4a;
  display:block;
  text-decoration:none;
}

.vidactive{
  color: #fff!important;
  background: #308fed;
}
.gas:hover{
  color: #fff;
  background: #308fed;
}
.gas{
  height: 45px;
  width: 280px;
  cursor: pointer;
  margin: 5px 0 0 0;
  position: relative;
  .name{
    left: 0;
    width: 100px;
    font-size: 20px;
    position: absolute;
    margin: 14px 0 14px 20px;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
  }
  .one-gas{
    right: 0;
    font-size: 20px;
    display: inline;
    position: absolute;
    margin: 14px 15px 14px 0;
    font-family: ProximaNova, serif;
    font-weight: normal;
    font-style: normal;
  }
}
.social-azs{
  width: 100px;
  margin: 30px 0 0 0;
  position: absolute;
}
/*------------Виджет бензина--------------*/
.widget-gas{
  width: 300px;
  height: auto;
  padding: 0 0 5px 0;
  background: #f5f4f0;
  .head{
    margin: 0;
    color: #fff;
    height: 50px;
    font-size: 18px;
    background: #4a4a4a;
    padding: 17px 0 0 15px;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
    span{
      font-size: 15px;
      color: #4a4a4a;
    }
  }
  .widget-block-comment{
    margin: 10px;
    background: #fafaf8;
    padding: 10px 10px 5px 10px;
    .widget-block-comment-title{
      padding: 0 0 1px 0;
      a{
        color: #4a4a4a;
        font-size: 15px;
        font-family: ProximaNova, serif;
        font-weight: bold;
        font-style: normal;
      }
    }
    .widget-block-comment-name{
      color: #808080;
      font-size: 14px;
      padding: 0 0 5px 0;
      font-family: ProximaNova, serif;
      font-weight: normal;
      font-style: normal;
    }
    p{
      color: #808080;
      font-size: 14px;
      font-family: ProximaNova, serif;
      font-weight: normal;
      font-style: normal;
      a{
        color: #4a4a4a;
        font-size: 14px;
        font-weight: bold;
      }
      span{
        color: #222222;
        font-size: 14px;
      }
    }
  }
  select{
    margin: 10px 0 0 70px;
  }
  .redirect-azs{
    width: 300px;
    text-align: center;
    a{
      cursor: pointer;
    }
  }
  .widget-price-gas{
    width: 300px;
    height: 90px;
    position: relative;
    padding: 10px 0 0 10px;
    .block-gas{
      width: 140px;
      height: 25px;
      position: relative;
      display: inline-block;
      .block-gas-name{
        float: left;
        width: 70px;
        p{
          color: #808080;
          font-size: 14px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
      .block-gas-price{
        float: left;
        width: 50px;
        p{
          color: #333333;
          font-size: 16px;
          font-family: ProximaNova, serif;
          font-weight: bold;
          font-style: normal;
        }
      }
    }
  }
}
/*-------Политика Конфиденциальности Сonfidentiality */
.confidentiality-main{

  .confidentiality-box{
    h1{
      text-align: center;
    }
    p{
      font-size: 17px;
      line-height: 24px;
    }
  }
}
/*--------- Tags ---------*/
.tags-index{
  height: auto;
  width: 630px;
  margin: 0 auto;
  max-width: 100%;
  h2{
    color: #4a4a4a;
    font-size: 24px;
    text-align: center;
    margin: 10px 0 20px 0;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
    span{
      color: #f9cd1b;
    }
  }
}
/*---------------------------*/
.index-post-related-news{
  width: 220px;
  height: auto;
  .title-in-widget-news-related{
    height: 30px;
    color: #222222;
    font-size: 18px;
    line-height: 27px;
    padding-left: 5px;
    font-family: ProximaNova, serif;
    font-weight: bold;
    font-style: normal;
    background: url("/images/site/related-background.png");
    background-repeat: no-repeat;
  }
  ul{
    list-style-type: none;
    li:last-child{
      border-bottom: none;
    }
    li{
      padding: 9px 0;
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid #e5e5e5;
      p{
        margin: 0;
        color: #969696;
        .time-related{
          color: #969696;
        }
        a{
          color: #222222;
          font-size: 15px;
          font-family: ProximaNova, serif;
          font-weight: normal;
          font-style: normal;
        }
      }
      a{
        color: #222222;
        font-size: 14px;
        font-family: ProximaNova, serif;
        font-weight: normal;
        font-style: normal;
        overflow: hidden;
        display: block;
        margin-bottom: -1px;
        &:hover > div, &:focus > div {
          text-decoration: underline;
        }
      }
      .related-image{
        float: left;
        width: 50px;
        height: 50px;
        background: #000;
        img{
          width: 50px;
          height: 50px;
        }
      }
      .related-text{
        float: left;
        width: 170px;
        height: auto;
        padding: 0 0 0 10px;
      }
    }
  }
}

/*переключатель сторінок */
.list-view{
  .pagination{
    width: 100%;
    clear: left;
    li{
      display: inline-block;
      margin: 0 7px 0 0;
      height: 25px;
      min-width: 25px;
      border-radius: 2px;
      background: #F0F0F0;
      &:hover {
        background-color: #FFCA00;
        a {
          color: #FFF;
        }
      }
      &.disabled {
        display: none;
      }
      a{
        border: none;
        color: #152414;
        font-size: 12px;
        padding: 3px 7px 4px;
        background-color: transparent;
        display: inline-block;
        min-width: 25px;
        text-align: center;
      }
      span{
        border: none !important;
        padding: 12px;
        background-color: transparent !important;
      }
    }
    .active{
      background: #FFCA00;
      a{
        color: #FFF;
        background: transparent;
      }
    }
  }
}
.yandex-absolute {
  position: relative;
  #yandex_ad_more, #yandex_ad_baner, > .adsbygoogle, >div {
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  #yandex_ad_more, > .adsbygoogle, > div {
    width: 220px;
    //@media (max-width: 1400px) {
    //  display: none;
    //}
  }
  #yandex_ad_baner {
    width: 300px;
    @media (max-width: 992px) {
      display: none;
    }
  }
}

.absolute-bottom-adv {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 2;
  .bottom-advertising {
    width: 1323px;
    padding: 0 30px 0 100px;
    margin: 0 auto;
    .bottom-adv {
      position: relative;
      max-width: 620px;
      width: 100%;
      //margin: 0 auto;
      background: #fff;
      .adv-block {
        height: 90px;
      }
      .close-adv {
        position: absolute;
        bottom: 100%;
        right: 0;
        font-size: 40px;
        text-decoration: none;
        font-weight: 700;
        line-height: 25px;
        color: #e6f0ff;
        cursor: pointer;
        background: #646464;
        padding: 0 10px 0 10px;
        height: 35px;
        font-family: ProximaNova, serif;
        font-style: normal;
        &:hover {
          color: red;
        }
      }
    }
    @media (max-width: 1440px) {
      width: 980px;
      padding: 0 20px;
    }
    @media (max-width: 992px) {
      width: 100%;
      .bottom-adv {
        max-width: none;
      }
    }
    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }
  .goods-blocks-news {
    font-family: ProximaNova, serif;
    font-weight: normal;
    font-style: normal;
    .all-goods {
      padding: 0;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

.index-post .block-poll-block div {
  margin: 0;
}

.block-poll
{
  display: table;
  width: 100%;
  height: 75px;
  overflow: hidden;
  float: none;
  background: rgba(255, 202, 0, 0.1);
  font-size: 24px;
  line-height: 30px;
  color: #282828;
  margin: 30px 0;
}

.poll-text, .text-poll-block, .poll-button, .count-positive, .count-help-post {
  display: table-cell;
  vertical-align: middle;
}

.text-poll-block > div {
  display: table;
  width: 100%;
}

.count-help-post {
  width: 20px;
}

.poll-title {
  padding: 0 30px;
  div {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    padding-top: 5px;
    color: #6e6e6e;
    vertical-align: middle;
  }
  .social-likes {
    float: left;
    .facebook,
    .vkontakte,
    .odnoklassniki,
    .telegram {
      display: inline-block;
      margin-right: 10px;
      vertical-align: bottom;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      border-radius: 2px;
    }
    .facebook{
      background-image: url("/images/fermer/oauth/facebook.png");
    }
    .vkontakte{
      background-image: url("/images/fermer/oauth/vk.png");
    }
    .odnoklassniki{
      background-image: url("/images/fermer/oauth/ok.png");
    }
    .telegram {
      background-image: url("/images/fermer/fix/telegram.png");
    }
  }
  .no-mobile {
    padding-left: 185px;
    display: block;
    .table {
      height: 32px;
    }
  }
}

.count-positive {
  padding: 0 30px;
  background: rgba(255, 202, 0, 0.1);
  font-size: 36px;
  text-align: center;
  width: 10px;
  div {
    display: table;
    margin: 0 auto;
  }
  span {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    &:first-child {
      padding-right: 20px;
    }
    &:not(:first-child) {
      width: 55px;
      font-size: 12px;
      line-height: 12px;
      display: block;
      padding: 2px 0;
    }
  }
	@media all and (max-width: 500px) {
		padding: 0px;
	}
}

.poll-button
{
  width: 10px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  text-decoration: underline;
  //float: right;
  padding: 0 17px;
  margin-right: 5px;
  &:hover, &:focus {
    opacity: 0.8;
  }
  &.no
  {
    color: #d08282;
    text-decoration: underline #eed2c3;
  }

  &.yes
  {
    color: #98b65c;
    text-decoration: underline #dce2b9;
  }
	@media all and (max-width: 767px) {
		width: initial;
	}
}

.poll-text[data-id="yes"] {
  font-size: 14px;
  line-height: 12px;
  .no-mobile-block {
    .table {
      height: 30px;
      width: auto;
    }
  }
  .mobile-block {
    margin-bottom: 5px;
  }
}

.table {
  width: 100%;
  height: 100%;
  display: table;
  .table-row {
    display: table-row;
  }
  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.social-likes {
  float: left;
}

.social-likes_visible {
  display: inline-block;
}

.social-likes__widget {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
}

.social-likes__widget_facebook {
  background: #3b5998;
}
.social-likes__widget_mailru {
  background: #168de2;
}
.social-likes__widget_odnoklassniki {
  background: #eb722e;
}
.social-likes__widget_vkontakte {
  background: #48729e;
}
.social-likes__widget_plusone {
  background: #eb1c00;
}

.social-likes__button {
  color: transparent;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  position: relative;
  background: no-repeat center;
}

.social-likes__counter {
  font-size: 14px;
  line-height: 30px;
  padding: 0 8px;
  color: #FFF;
  display: inline-block;
  position: relative;
  &:before {
    content: '';
    top: 10%;
    left: 0;
    position: absolute;
    height: 80%;
    width: 1px;
    background: rgba(255,255,255,0.2);
  }
}

.social-likes__counter_empty {
  display: none;
}

.social-likes__button_facebook {
  background-image: url('/images/agronomu/social/facebook.svg');
}
.social-likes__button_mailru {
  background-image: url('/images/agronomu/social/mailru.svg');
}
.social-likes__button_odnoklassniki {
  background-image: url('/images/agronomu/social/odnoklasniki.svg');
}
.social-likes__button_vkontakte {
  background-image: url('/images/agronomu/social/vk.svg');
}
.social-likes__button_plusone {
  background-image: url('/images/agronomu/social/google.svg');
}
